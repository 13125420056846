<div fxLayout="column wrap" fxFlex="80" fxFlexOffset="8" fxFlex.xs="auto" fxFlexOffset.xs="1">
   
    <h3>{{'shopping_list' | translate | titlecase}} </h3>
  <div  fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-button mat-mini-fab color="primary" (click)="goBack()">
          <mat-icon aria-label="Cusotmer icon-button with a back icon">arrow_back</mat-icon>
        </button>
      
          <button mat-button mat-mini-fab color="primary" (click)="createShoppingListItem()">
            <mat-icon aria-label="Cusotmer icon-button with a back icon">add</mat-icon>
          </button>
        </div>
     
      <h4>{{shop_name }} / {{shopping_list_name}}</h4>
    
    <div class="example-container">
        <mat-form-field>
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
          </mat-form-field>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" >
            <ng-container matColumnDef="edit">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let element">
                  <button mat-icon-button class="mat-primary" (click)="editShoppingListItem(element)">
                      <mat-icon>edit</mat-icon>
                    </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="product_number">
                <th mat-header-cell *matHeaderCellDef> Produktnummer </th>
                <td mat-cell *matCellDef="let element" [style.color]="'navy'" (click)="editShoppingListItem(element)" [style.cursor] ="'pointer'">{{element.product_number}}</td>
              </ng-container>  
          <ng-container matColumnDef="product_name">
              <th mat-header-cell *matHeaderCellDef> Navn </th>
              <td mat-cell *matCellDef="let element" [style.color]="'navy'" (click)="editShoppingListItem(element)" [style.cursor] ="'pointer'"> {{element.name}} </td>
            </ng-container>
            <ng-container matColumnDef="unit">
              <th mat-header-cell *matHeaderCellDef> Antal </th>
              <td mat-cell *matCellDef="let element"> {{element.unit}} </td>
            </ng-container>
            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element"> 
                    <button mat-icon-button class="mat-warn" (click)="delete(element)">
                        <mat-icon>delete</mat-icon>
                      </button>
                    
                 <!--< <mat-icon  class="mat-primary" (click)="delete(element)">delete</mat-icon>-->
                </td>
              </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
  </div>