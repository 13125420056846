import { Injectable } from '@angular/core';
import {Contact} from '../shared/contact';
import { BehaviorSubject, Observable} from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';

interface responseData{
  OK: string,
  contact_list: Contact[]
}

interface responseOK{
  OK: {}
}


@Injectable({
  providedIn: 'root'
})
export class ContactService {
  

  private contact: Contact
  private currentContactSubject: BehaviorSubject<Contact>
  public currentContact: Observable<Contact>;

  constructor(private http: HttpClient) {
    this.currentContactSubject = new BehaviorSubject<Contact>(this.contact);
    this.currentContact = this.currentContactSubject.asObservable();
  }

  public get currentContactValue(): Contact {
    return this.currentContactSubject.value;
  }

  removeContactValue(){
    this.currentContactSubject.next(null)
  }



  getContactById(id: string): Observable<Contact>{
    let httpParams = new HttpParams()
                  .set("id",id);
    return this.http.get<Contact>('server/engros_company_contact.php?action=read', {params: httpParams}).pipe(map(contact => {
      // login successful if there's a jwt token in the response
      if (contact) {
          this.currentContactSubject.next(contact);
      }

      return contact;
  }));;
  }

  public getContact(): Observable<Contact> {
 
    return( this.currentContactSubject.pipe( distinctUntilChanged() ) );

}

  getContacts(id: string): Observable<responseData>{
    let httpParams = new HttpParams()
                  .set("company_id",id);
    return this.http.get<responseData>('server/engros_company_contact.php?action=company_based_list', {params: httpParams});
  }

  addContact(contact): Observable<Contact>{
    let formData: FormData = new FormData()
    formData.append("job_title",contact.job_title)
    formData.append("name", contact.name)
    formData.append("phone", contact.phone.replace(/\s/g, ""))
    formData.append("email", contact.email.toLowerCase())
    formData.append("company_id", contact.company_id)
    formData.append("action", "create")
    formData.append("locale", "da")
    return this.http.post<Contact>('server/engros_company_contact.php', formData);
  }

  updateContact(contact: Contact): Observable<Contact>{
    let formData: FormData = new FormData()
    formData.append("id", contact.id)
    formData.append("job_title",contact.job_title)
    formData.append("name", contact.name)
    formData.append("phone", contact.phone.replace(/\s/g, ""))
    formData.append("email", contact.email.toLowerCase())
    formData.append("company_id", contact.company_id)
    formData.append("action", "update")
    
    return this.http.post<Contact>('server/engros_company_contact.php', formData);
  }

  updateVisibilityContact(id: string, value: string) {
    const formData: FormData = new FormData();
    formData.append("is_visible", value);
    formData.append("id", id);
    formData.append("action", "update_visibile")
    return this.http.post<responseOK>('server/engros_company_contact.php', formData)
 
  }

  deleteContact(id:string): Observable<Contact>{
    let httpParams = new HttpParams().set("id", id);
    return this.http.get<Contact>('server/engros_company_contact.php?action=delete', {params: httpParams});
  }

  updateContactImage(id: string, fileToUpload: File): Observable<responseOK>{
    const formData: FormData = new FormData();
    formData.append('userfile', fileToUpload);
    formData.append('id', id);
    return this.http.post<responseOK>('server/engros_company_contact.php?action=add_photo', formData)
  }

  deleteContactImage(id:string){
    let httpParams = new HttpParams().set("id", id);
    return this.http.get('server/engros_company_contact.php?action=remove_photo', {params: httpParams});
  }
}