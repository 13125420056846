import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { switchMap } from 'rxjs/operators';
import { Params, ActivatedRoute } from '@angular/router';
import { Product } from '../shared/product';
import { ShoppingListService } from '../services/shopping-list.service';
import { MatTableDataSource } from '@angular/material';
import{ SnackbarService } from '../services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../services/dialog.service';
import {MatDialog} from '@angular/material';
import { ShoppingListItemAddComponent } from '../shopping-list-item-add/shopping-list-item-add.component';
import { ShoppingListItemEditComponent } from '../shopping-list-item-edit/shopping-list-item-edit.component';

@Component({
  selector: 'app-shopping-list-detail',
  templateUrl: './shopping-list-detail.component.html',
  styleUrls: ['./shopping-list-detail.component.scss']
})
export class ShoppingListDetailComponent implements OnInit {

  products: Product[]
  dataSource
  id: string
  shop_name: string
  shopping_list_name: string
  shopping_list_id:string
  displayedColumns: string[] = ['edit','product_number', 'product_name', 'unit', 'delete']

  constructor(
    private location: Location, 
    private route: ActivatedRoute,
    private shoppinglistService: ShoppingListService,
    private translate: TranslateService,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.id = localStorage.getItem("profile_id")
    this.route.params
    .pipe(switchMap((params: Params) => {
      this.shopping_list_id = params['id']
      return this.shoppinglistService.getShoppingListItem(this.shopping_list_id);
    }))
    .subscribe(
      (data) => {
        this.shop_name = data.shop_name
        this.shopping_list_name = data.shopping_list_name
        this.products = data.shopping_list_items
        this.dataSource = new MatTableDataSource<Product>(this.products)
      }
    )
  }

  createShoppingListItem(): void{
    let dialogRef = this.dialog.open(ShoppingListItemAddComponent, {
      width: '800px',
      data: this.shopping_list_id,
      disableClose: true 
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      console.log(result.data)
      this.shoppinglistService.addShoppingListItem(result.data).subscribe(
      (data) => {
        if(data.OK == "YES"){
          this.shoppinglistService.getShoppingListItem(this.shopping_list_id).subscribe(
            (data) => {
              this.products = data.shopping_list_items
              this.dataSource = new MatTableDataSource<Product>(this.products)
              this.snackbarService.success(this.translate.instant("add_success"))
              })
        }
      })
    });
  }

  editShoppingListItem(product: Product): void{
    let dialogRef = this.dialog.open(ShoppingListItemEditComponent, {
      width: '800px',
      data: product,
      disableClose: true 
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      console.log(result.data)
      this.shoppinglistService.editShoppingListItem(result.data).subscribe(
      (data) => {
        if(data.OK == "YES"){
          this.shoppinglistService.getShoppingListItem(this.shopping_list_id).subscribe(
            (data) => {
              this.products = data.shopping_list_items
              this.dataSource = new MatTableDataSource<Product>(this.products)
              this.snackbarService.success(this.translate.instant("update_success"))
              })
        }
      })
    });
  }


  delete(product: Product){
    this.dialogService.openConfirmDialog("Er du sikker på slette denne produkt?")
     .afterClosed().subscribe(res=>{
       if(res){
          this.shoppinglistService.deleteShoppingListItem(product.id).subscribe((data)=>{
            if(data.OK == "YES"){
              this.products.forEach( (item, index) => {
                if(item.id === product.id) 
                  this.products.splice(index,1);
                this.dataSource = new MatTableDataSource<Product>(this.products);
              });
            }
          })
        }
      })
  }

  goBack(): void{
    this.location.back();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
