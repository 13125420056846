<div class="spinner-wrapper">
    <button mat-icon-button color="primary" aria-label="Message-detail icon-button with a cancel icon">
        <mat-icon (click)="close()">cancel</mat-icon>
    </button>
    <mat-spinner [diameter]="25" style="margin-left:auto; display: flex;" *ngIf="showProgressIcon"></mat-spinner>
  </div>


<h4 mat-dialog-title>{{shop_name}}</h4>

<mat-dialog-content>
    
  <section>
    <mat-list dense class="conversation-history mat-typography" role="list">
        <!--<mat-progress-bar mode="buffer"></mat-progress-bar>-->
      <mat-list-item *ngFor="let msg of messages" [dir]="msg.sending === 'Company' ? 'rtl' : 'ltr'">
          <div matLine style="font-size: 12px;">
            <span *ngIf="msg.sending === 'Company'">{{msg.create_date  | date }} {{msg.create_date  | date:'HH:mm'}}</span>
            <span *ngIf="msg.sending === 'Shop'">{{msg.app_user_name}} - {{msg.create_date | toDateObj | date }} {{msg.create_date | toDateObj | date:'HH:mm'}}</span>
          </div>
          <div matLine>
           {{msg.body}}
          </div>
      </mat-list-item>
    </mat-list>
  </section>
</mat-dialog-content>

<mat-dialog-actions>

    <div class="conversation-input">
        <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{'message' | translate | titlecase }}</mat-label>
          <input (keydown.enter)="sendText(text)" matInput placeholder="{{'reply_a_message' | translate  }}" name="text" [(ngModel)]="text" cdkFocusInitial>
          <mat-icon matSuffix (click)="sendText(text)" [style.cursor] ="'pointer'">send</mat-icon>
        </mat-form-field>
      </div>

</mat-dialog-actions>

  <!-- <mat-card class="messagebox">
        <mat-list> 
            <mat-list-item  *ngFor="let msg of messages">
                <p><span class="sending-{{msg.sending}}">{{ msg.body }}</span></p>        
                    <p>{{msg.create_date | date }} </p>
            </mat-list-item>
        </mat-list>
    </mat-card>
  <mat-card>
        <mat-form-field style="width:75%; margin-right:2%;">
            <input matInput
                     formControlName="body">
        </mat-form-field>
        <button class="mat-raised-button mat-primary"
             (click)="save()"> send
        </button>
  </mat-card>-->