import { Component, OnInit } from '@angular/core';
import { RatingService } from '../services/rating.service';
import { Rating } from '../shared/rating';
import {MatDialog, MatDialogConfig} from '@angular/material'
//import { RatingReplyComponent } from '../rating-reply/rating-reply.component';
import { SnackbarService } from '../services/snackbar.service';
import { DialogService } from '../services/dialog.service'
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {

  ratings: Rating[]
  id: string;
  

  constructor(
    private ratingService: RatingService, 
    private snackbarService: SnackbarService,
    private dialogService: DialogService,
    private translate: TranslateService,
    private dialog: MatDialog) { }

  ngOnInit() {
    
    this.id = localStorage.getItem("profile_id")
    this.ratingService.getRatingsByProfileId(this.id).subscribe(
      (data) => this.ratings = data.ratings.filter((rating: Rating) => rating.inappropriate != "1")
    )
  }

  

  sendReply(rating:Rating){
    console.log(rating)

    this.ratingService.sendReply(rating).subscribe(
      (data) => {
        if(data.OK == "YES"){
          this.snackbarService.success(this.translate.instant("update_success"))
        }
      }
    )
   /*let dialogRef = this.dialog.open(RatingReplyComponent, {
      width: '600px',
      disableClose: true ,
      data: rating
    });*/

    /*dialogRef.afterClosed().subscribe(
      val => console.log("Dialog output:", val)
  );*/
  /*dialogRef.componentInstance.event.subscribe((result) => {
    console.log(result.data)
    this.ratingService.sendReply(result.data).subscribe(
    (data) => {
      if(data.OK == "YES"){
        this.ratingService.getRatingsByProfileId(this.id).subscribe(
          (data) => this.ratings = data.ratings.filter((rating: Rating) => rating.inappropriate != "1"))
      }
    })
  });*/

  }

}
