<mat-dialog-content [formGroup]="form" class="example-form">

    <div class="image-box">
        <input style="display: none" [disabled]="!imageClick" type="file" (change)="onFileChanged($event.target.files)"
            #fileInput accept="image/*">
            <button class="mat-raised-button mat-warn" style="display: flex;" *ngIf="!imageClick" (click) = "deleteImage()">Fjern billede</button>
            
        <!--<mat-icon style="margin-left:auto; display: flex;" *ngIf="!imageClick" (click) = "deleteImage()">close</mat-icon>-->
        <mat-card class="example-card">
        <img mat-card-image [src]="imageUrl"  (click)="fileInput.click()">
        </mat-card>
    </div>

    <mat-form-field appearance="outline" class="full-width">
            <mat-label>Navn</mat-label>
        <input matInput formControlName="name">
        <mat-error *ngIf="isFieldInvalid('name')">
                Der skal indtastes navn
        </mat-error>

    </mat-form-field>
    <mat-form-field appearance="outline" class="full-width">
            <mat-label>Mobilnummer</mat-label>
        <input matInput formControlName="phone">
        <mat-error *ngIf="isFieldInvalid('phone')">
                Der skal indtastes mobilnummer
        </mat-error>

    </mat-form-field>

    <mat-form-field appearance="outline" class="full-width">
            <mat-label>Email</mat-label>
        <input matInput formControlName="email">
        <mat-error *ngIf="isFieldInvalid('email')">
                Der skal indtastes email
        </mat-error>

    </mat-form-field>

    <mat-form-field appearance="outline" class="full-width">
            <mat-label>Rolle</mat-label>
        <input matInput formControlName="job_title">
        <mat-error *ngIf="isFieldInvalid('job_title')">
                Der skal indtastes rolle
        </mat-error>
    </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button"
          (click)="close()">
      Fortryd
    </button>
    <span class="fill-space"></span>
    <button class="mat-raised-button mat-primary"
          (click)="save()" [disabled] ="form.invalid">
      Gem
    </button>
</mat-dialog-actions>