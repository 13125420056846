<div fxLayout="column wrap" fxFlex="80" fxFlexOffset="10" fxFlex.xs="auto" fxFlexOffset.xs="0">
  <div fxLayout="row" fxLayoutAlign="center center" >
    <a href="https://shopengros.com"><img  class="image_logo" mat-card-image src="/assets/imgs/logo.png" alt="logo"></a>
  </div>

  <div fxLayout="column wrap" fxLayoutAlign="center center" *ngIf="submittingForm">
      <h4>Venter ...</h4>
      <mat-spinner></mat-spinner>
      <div>
        <button mat-raised-button (click)="close()">Go back</button>
     </div>
    </div>
    <div fxLayout="column wrap" style="padding:15px; text-align: justify;" *ngIf="submitted">
      <mat-list>
        <h4>Tusinde tak for at have registreret din butik på vores ShopEngros markedsplads, vi vil kontakte dig i løbet af 1-3 arbejdsdage.</h4>
      <!--<h4>Thank you for signup to ShopEngros. We will contact you within 1-2 working days.</h4>-->
      <mat-list-item><button mat-raised-button (click)="close()">Go back</button></mat-list-item>
    </mat-list>

</div>
<div  fxFlex *ngIf="initForm">
<mat-card class="signin-content">
  <mat-card-title>
    <h2 style="text-align:center;">Opret grossist virksomhed</h2>
  </mat-card-title>
  <mat-card-content>
    <form  novalidate [formGroup]="companyForm" (ngSubmit)="onSubmit()" #fform="ngForm">
              <!-- fxLayout="row" fxLayoutGap="20px" -->
              <p>
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Kategori</mat-label>
                    <mat-select placeholder="Category" formControlName="company_category_id">
                        <mat-option *ngFor="let c of companyCategories" [value]="c.id">
                          {{ c.name }}
                        </mat-option>
                      </mat-select>
                  </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>CVR</mat-label>
                    <input matInput type="text" formControlName="cvr" required>
                    <mat-error *ngIf="formErrors.cvr">{{formErrors.cvr}}</mat-error>
                  </mat-form-field>
              </p>
              <p>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Navn</mat-label>
                  <input matInput type="text" formControlName="name" required>
                  <mat-error *ngIf="formErrors.name">{{formErrors.name}}</mat-error>
                </mat-form-field>
              </p>
              <p>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Adresse</mat-label>
                  <input matInput type="text" formControlName="address" required>
                  <mat-error *ngIf="formErrors.address">{{formErrors.address}}</mat-error>
                </mat-form-field>
              </p>
              <div fxLayout="row wrap" fxLayout.xs="column" >
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Postnr</mat-label>
                    <input matInput type="text"formControlName="zip" required>
                    <mat-error *ngIf="formErrors.zip">{{formErrors.zip}}</mat-error>
                  </mat-form-field>
                  <span class="fill-space"></span>
                      <mat-form-field appearance="outline" class="full-width">
                        <mat-label>By</mat-label>
                        <input matInput type="text" formControlName="city" required>
                        <mat-error *ngIf="formErrors.city">{{formErrors.city}}</mat-error>
                      </mat-form-field>
              
                    </div>
              <p>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Land</mat-label>
                  <input matInput type="text" formControlName="country" required>
                  <mat-error *ngIf="formErrors.country">{{formErrors.country}}</mat-error>
                </mat-form-field>
              </p>
              <div fxLayout="row" fxLayout.xs="column" >
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Email</mat-label>
                    <input matInput type="email"formControlName="email" required>
                    <mat-error *ngIf="formErrors.email">{{formErrors.email}}</mat-error>
                  </mat-form-field>
                  <span class="fill-space"></span>
                      <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Telefon</mat-label>
                        <input matInput type="text" formControlName="phone" required>
                        <mat-error *ngIf="formErrors.phone">{{formErrors.phone}}</mat-error>
                      </mat-form-field>
              </div>
              <p>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Hjemmeside</mat-label>
                  <input matInput type="text" formControlName="web">
                </mat-form-field>
              </p>
             <!-- <div fxLayout="row" fxLayout.xs="column" >
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Bestilling Email</mat-label>
                  <input matInput type="text" formControlName="order_email">
                </mat-form-field>
                  <span class="fill-space"></span>
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Bestilling SMS</mat-label>
                    <input matInput type="text" formControlName="order_sms">
                  </mat-form-field>
              </div>
            
            -->
                <p>
                    <mat-form-field appearance="outline" class="full-width">
                      <mat-label>Leveringstid</mat-label>
                      <textarea matInput formControlName="our_delivery_times"></textarea>
                    </mat-form-field>
                  </p>
  
                  <p>
                      <mat-form-field appearance="outline" class="full-width">
                        <mat-label>Åbningstider</mat-label>
                        <textarea matInput formControlName="our_office_opening_hours"></textarea>
                      </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>Navn på kontaktperson</mat-label>
                            <input matInput type="text" formControlName="user_name" required>
                            <mat-error *ngIf="formErrors.user_name">{{formErrors.user_name}}</mat-error>
                          </mat-form-field>  
                       
                      </p>
                   <!-- <div fxLayout="row" fxLayout.xs="column" >
                        
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>Kontaktpersons email</mat-label>
                            <input matInput type="text" formControlName="contact_email" required>
                            <mat-error *ngIf="isFieldInvalid('contact_email')">
                                Der skal indtastes kontaktperson email
                              </mat-error>
                          </mat-form-field>
                          <mat-form-field appearance="outline" class="full-width">
                              <mat-label>Kontaktpersons mobilnummer</mat-label>
                              <input matInput type="tel" pattern="[0-9]*" formControlName="contact_phone" required>
                              <mat-error *ngIf="isFieldInvalid('contact_phone')">
                                  Der skal indtastes kontaktperson mobilnummer
                                </mat-error>
                            </mat-form-field>
                        </div>-->
                        
                          <!--<p>
                              <mat-form-field appearance="outline" class="full-width">
                                <mat-label>Kommentarer</mat-label>
                                <textarea matInput formControlName="notes"></textarea>
                              </mat-form-field>
                            </p>-->
                            <p>
                              <mat-checkbox formControlName="agree" required></mat-checkbox>
                              <mat-label (click)="showPrivacy()" for="agree" style="margin-left:2%; cursor: pointer; color: blue;">Jeg accepterer vilkårene for brug, privatlivspolitik</mat-label>
                                
                              
                            </p>
                 
                  <!--<div fxLayout="column" fxLayoutAlign="start" class="row">
                      <mat-form-field class="full-width">
                          <textarea matInput formControlName="notes" placeholder="Your Note" rows=6></textarea>
                        </mat-form-field>
                  </div>-->
                         
                 
                  <div fxLayout="row wrap" fxLayoutAlign="space-between">
                    <button mat-raised-button (click)="close()">Fortryd</button>
                    <button type="submit" mat-raised-button color = "primary" [disabled] = companyForm.invalid >Gem og opret</button>
                </div>
              
              </form>
          
            </mat-card-content>
          
       
      </mat-card>
    </div>
      
        
      
      </div>