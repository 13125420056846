<mat-dialog-content [formGroup]="form" class="example-form">

<mat-form-field>

        <textarea matInput placeholder="Insert your reply"
            formControlName="reply">

        </textarea>

    </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions>

<button class="mat-raised-button"
        (click)="close()">
    Luk
</button>

<button class="mat-raised-button mat-primary"
        (click)="save()">
    Gem
</button>
</mat-dialog-actions>