<div fxLayout="column wrap" fxFlex="80" fxFlexOffset="8" fxFlex.xs="auto" fxFlexOffset.xs="1">
 
    <div>
      <h3>{{'notifications'| translate | titlecase}}</h3>
      <div fxLayout="column wrap" fxLayoutAlign="start start" class="card-container">
        <button button="submit" mat-mini-fab color="primary" (click) = "createNotification()">
          <mat-icon aria-label="Notification icon-button with a add icon">add</mat-icon>
        </button>
      </div>

      <div class="example-container">
      
          <mat-form-field>
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Søg efter listen">
          </mat-form-field>
          <mat-progress-bar mode="indeterminate" *ngIf="isWait"></mat-progress-bar>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" >
      
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef> Title </th>
              <td mat-cell  *matCellDef="let element" [style.color]="'navy'" (click)="editNotification(element)" [style.cursor] ="'pointer'"> {{element.title}} </td>
            </ng-container>
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let element" [style.color]="'navy'" > {{element.status}} </td>
              </ng-container>
    
            <ng-container matColumnDef="create_date">
              <th mat-header-cell *matHeaderCellDef> Dato </th>
              <td mat-cell *matCellDef="let element"> 
                  <p *ngIf="element.to_date != '0000-00-00 00:00:00';else noDate" [style.color]="'green'" >
                      {{element.create_date | toDateObj | date}}
                   </p>
                   <ng-template #noDate><p [style.color]="'blue'"></p></ng-template>
              </td>
            </ng-container>
            <ng-container matColumnDef="view_response">
              <th mat-header-cell *matHeaderCellDef>Interesseret</th>
              <td  mat-cell *matCellDef="let element" [routerLink]="['/notification-response', element.id]" [style.color]="'blue'" [style.cursor] ="'pointer'"> 
                  <p>
                      <span matBadge="{{element.butikker}}" matBadgeOverlap="false" matBadgeColor="warn" >butikker</span>
                    </p>
                    
              </td>
            </ng-container>
            <!--<ng-container matColumnDef="delete">
                <th  mat-header-cell *matHeaderCellDef>Slet  </th>
                <td  mat-cell *matCellDef="let element"> 
                    
                </td>
              </ng-container>-->
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
      </div>
  </div>