import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {Rating} from "../shared/rating";
import {FormBuilder, Validators, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-rating-reply',
  templateUrl: './rating-reply.component.html',
  styleUrls: ['./rating-reply.component.scss']
})
export class RatingReplyComponent implements OnInit {

  form: FormGroup;
  public event: EventEmitter<any> = new EventEmitter();

  constructor(private fb: FormBuilder,
    private dialogRef: MatDialogRef<RatingReplyComponent>,
    @Inject(MAT_DIALOG_DATA) rating:Rating ) { 
      this.form = fb.group({
        id: rating.id,
        profile_id: rating.app_user_id,
        company_id: rating.company_id,
        stars: rating.stars,
        review: rating.review,
        reply: rating.reply,
        inappropriate: rating.inappropriate
    });
    }

  ngOnInit() {
  }

  save() {
    console.log(this.form.value)  
    this.event.emit({data: this.form.value});
    this.dialogRef.close();  
  }

  close() {
    this.dialogRef.close();
}

}
