import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Product } from '../shared/product';

interface responseData{
  OK: string,
  product_list: Product[]
}

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient) { }

  getProducts(id: string): Observable<responseData>{
    let httpParams = new HttpParams()
                  .set("company_id",id);
    return this.http.get<responseData>('server/engros_company_product.php?action=company_based_list', {params: httpParams});
  }

  addProduct(product:Product): Observable<Product>{
    let formData: FormData = new FormData()
    formData.append("name", product.name)
    formData.append("unit", product.unit)
    formData.append("standard_product", product.standard_product)
    formData.append("company_id", product.company_id)
    formData.append("action", "create")
    return this.http.post<Product>('server/engros_company_product.php', formData);
  }

  updateProduct(product: Product): Observable<Product>{
    let formData: FormData = new FormData()
    formData.append("id", product.id)
    formData.append("name", product.name)
    formData.append("unit", product.unit)
    formData.append("standard_product", product.standard_product)
    formData.append("action", "update")
    return this.http.post<Product>('server/engros_company_product.php', formData);
  }

  updateStandardProduct(product: Product): Observable<Product>{
    let formData: FormData = new FormData()
    formData.append("id",product.id)
    formData.append("standard_product", product.standard_product)
    formData.append("action", "update_standard_product")
    return this.http.post<Product>('server/engros_company_product.php', formData);
  }

  deleteProduct(id:string): Observable<Product>{
    let httpParams = new HttpParams().set("id", id);
    return this.http.get<Product>('server/engros_company_product.php?action=delete', {params: httpParams});
  }
}
