<mat-dialog-content>
        <mat-nav-list>
                <h4>Butik information</h4>
                <mat-list-item>
        <p matLine>{{data.name}} </p>
        <p matLine>CVR: {{data.cvr}}</p>
        <p matLine>{{data.zip}} {{data.city}}</p>
        <p matLine>{{data.country}}</p>
        <p matLine>+{{data.phone}} <a [href]="'tel:+'+data.phone"><mat-icon>phone</mat-icon></a></p>
        <p matLine>{{data.email}} <a [href]="'mailto:'+data.email"><mat-icon>email</mat-icon></a> </p>
    </mat-list-item>

        <h4>Kontakt person</h4>
       <!-- Name: {{data.contact_name}}<br>
        Mobile: <a [href]="'tel:' + data.contact_phone">{{data.contact_phone}}</a><br>
        Email: {{data.contact_email}}<br>
       -->
            <mat-list-item *ngFor="let e of data.employee_list">
                <p matLine><b>{{ e.name }}</b> - ({{ e.job_title}})</p>
                <p matLine>+{{e.phone}}<a [href]="'tel:+' + e.phone"><mat-icon>phone</mat-icon></a></p>
            </mat-list-item>
        </mat-nav-list>
</mat-dialog-content>

<mat-dialog-actions>
    <button color="primary" mat-button mat-dialog-close>LUK</button>
    <span style="flex: 1 1 auto;"></span>
    <button mat-icon-button (click)="onPrint()" ><mat-icon>print</mat-icon></button>
</mat-dialog-actions>

