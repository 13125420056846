import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap, finalize } from 'rxjs/operators';
import { ShopService } from '../services/shop.service';
import { Shop } from '../shared/shop';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource } from '@angular/material';
import {DialogService} from '../services/dialog.service'
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder , Validators} from '@angular/forms';

@Component({
  selector: 'app-customer-add',
  templateUrl: './customer-add.component.html',
  styleUrls: ['./customer-add.component.scss']
})
export class CustomerAddComponent implements OnInit {

  company_id = localStorage.getItem("profile_id")
  text: string
  dataSource;
  displayedColumns: string[] = ['cvr','name','address','phone'];
 
  isWait = false;
  private shopSubject = new Subject<string>();

  readonly shops$ = this.shopSubject.pipe(
    debounceTime(250),
    distinctUntilChanged(()=>this.isWait = false),
    switchMap(shopName => this.shopService.searchShops(shopName, this.company_id)),
    tap((data)=>{ 
      console.log(data) 
      this.dataSource = new MatTableDataSource<Shop>(data.shops)
      this.isWait = false
    }))

  ngOnInit(){}

  constructor(private location: Location,  private dialog: MatDialog,  private shopService: ShopService) { }

  searchShops(name: string) {
    this.isWait = true
    this.shopSubject.next(name)
  }

  goBack(): void{
    this.location.back();
  }

  showInfo(shop: Shop){
    let dialogRef = this.dialog.open(DialogShopInfo, {
      width: '800px',
      disableClose: true,
      data: shop
    })
    dialogRef.componentInstance.event.subscribe((result) => {
      console.log(result.data)
      if(result.data == "YES"){
        console.log(shop)
       this.shopService.requestFavorite(shop.id, this.company_id).subscribe((response) => {
         if(response.OK == "YES"){
          dialogRef.close();
         }
       })
      }
    })
  }

  createNewShop(){
    let dialogRef = this.dialog.open(DialogCreateShop, {
      width: '800px',
      disableClose: false,
      data : this.company_id
    });

    /*dialogRef.componentInstance.event.subscribe((result) => {
      console.log(result)
    this.shopSubject.subscribe(result.shops)
    
    }) */
  }


}

@Component({
  selector: 'dialog-shop-info',
  templateUrl: 'dialog-shop-info.html',
  styleUrls: ['./customer-add.component.scss']
})
export class DialogShopInfo {
  
  public event: EventEmitter<any> = new EventEmitter();
  constructor(private dialogService: DialogService,  
    private translate: TranslateService, 
    private dialogRef: MatDialogRef<DialogShopInfo>,
    @Inject(MAT_DIALOG_DATA) public data: Shop) {}

  requestToConnect(){
    //Are you sure want to connect with this shop? We will send notification to shop to accept your request.
    this.dialogService.openConfirmDialog(this.translate.instant("Vil du gerne forbindes med denne butik? Butikken skal godkende dit ønske, før der kan oprettes forbindelse."))
    .afterClosed().subscribe(res=>{
      if(res){
        this.event.emit({data: "YES"});
        this.dialogRef.close();
        //console.log(this.form.value) 
      }
    })
  }

}

@Component({
  selector: 'dialog-create-shop',
  templateUrl: 'dialog-create-shop.html',
  styleUrls: ['./customer-add.component.scss']
})
export class DialogCreateShop {
  form: FormGroup;
  public event: EventEmitter<any> = new EventEmitter();
  constructor(private fb: FormBuilder, 
    private dialog: MatDialog, 
    private dialogRef: MatDialogRef<DialogCreateShop>, 
    private shopService: ShopService,
    @Inject(MAT_DIALOG_DATA) public companyId: string) {

    this.form = this.fb.group({
      cvr: ['', Validators.required],
      name: ['', Validators.required],
      address: ['', Validators.required],
      zip: ['', Validators.required],
      city: ['', Validators.required],
      phone:['', Validators.required],
      email: ['', Validators.required],
      contact_name:['', Validators.required],
      contact_phone:['', Validators.required],
  });
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched)
    );
  }

  save(){
    //console.log(this.form.value)
    this.shopService.createShop(this.form.value, this.companyId).subscribe((data)=>{
      if(data.OK == "YES"){
        //You will be notified soon after validation
        this.dialogRef.close(); 
      }
      else if(data.OK == "Shop found"){
        let dialogRef = this.dialog.open(DialogShopList, {
          width: '800px',
          disableClose: true,
          data: data.shops
        })
      }
    })
     
  }
}

@Component({
  selector: 'dialog-shop-list',
  templateUrl: 'dialog-shop-list.html',
  styleUrls: ['./customer-add.component.scss']
})
export class DialogShopList {
  
  dataSource;
  displayedColumns: string[] = ['cvr','name','address','phone'];
 
  public event: EventEmitter<any> = new EventEmitter();
  constructor(
    private dialog: MatDialog,
    private dialogService: DialogService,  
    private translate: TranslateService, 
    private dialogRef: MatDialogRef<DialogShopInfo>,
    @Inject(MAT_DIALOG_DATA) public shops: Shop[]) {
      this.dataSource = new MatTableDataSource<Shop>(shops)
    }

  /*requestToConnect(){
    this.dialogService.openConfirmDialog(this.translate.instant("Are you sure want to connect with this shops? We will send notification to shop to accept your request."))
    .afterClosed().subscribe(res=>{
      if(res){
        this.event.emit({data: "YES"});
        this.dialogRef.close();
        //console.log(this.form.value) 
      }
    })
  }*/

  showInfo(shop: Shop){
    let dialogRef = this.dialog.open(DialogShopInfo, {
      width: '800px',
      disableClose: true,
      data: shop
    })
    dialogRef.componentInstance.event.subscribe((result) => {
      console.log(result.data)
      if(result.data == "YES"){
        console.log(shop)
      }
    })
  }

}
