<div fxLayout="column wrap" fxFlex="80" fxFlexOffset="8" fxFlex.xs="auto" fxFlexOffset.xs="1">
 
  <h3>Ordre </h3>
  
  <div class="example-container">
      <mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
        </mat-form-field>
        <mat-progress-bar mode="indeterminate" *ngIf="isWait"></mat-progress-bar>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" >

          <ng-container matColumnDef="shop_name">
            <th mat-header-cell *matHeaderCellDef> Navn </th>
            <td mat-cell *matCellDef="let element" [style.color]="'navy'" (click)="showDetail(element)" [style.cursor] ="'pointer'"> {{element.shop_name}} </td>
          </ng-container>

          <ng-container matColumnDef="delivery_date">
            <th mat-header-cell *matHeaderCellDef> Leveringsdato </th>
            <td mat-cell *matCellDef="let element"> {{ element.delivery_date === "0000-00-00 00:00:00" ? element.delivery_date  : element.delivery_date | date}} </td>
          </ng-container>

          <ng-container matColumnDef="create_date">
            <th mat-header-cell *matHeaderCellDef> Dato </th>
            <td mat-cell *matCellDef="let element"> {{element.create_date | toDateObj | date}} </td>
          </ng-container>
        
          <ng-container matColumnDef="done">
            <th mat-header-cell *matHeaderCellDef> Udskrevet </th>
            <td mat-cell *matCellDef="let element"> <mat-checkbox  [checked]="element.done == '1' ? true : false " (change)="toggle($event, element)"></mat-checkbox> </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
  </div>
</div>