import { Component, EventEmitter, Inject, OnInit, OnDestroy } from '@angular/core'
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatNativeDateModule, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material"
import {News} from "../shared/news"
import {FormBuilder, Validators, FormGroup} from "@angular/forms"
import { NewsService } from '../services/news.service'
import { DialogService } from '../services/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { NewsPublishComponent } from '../news-publish/news-publish.component';
import { CompanyService } from '../services/company.service'
import { Company } from '../shared/company'
import { take } from 'rxjs/operators'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss']
})
export class NewsDetailComponent implements OnInit, OnDestroy {

  private subscription: Subscription
  form: FormGroup;
  status: string;
  selectedFile: File
  imageUrl: string = "/assets/imgs/defaultImg.jpg"
  pdfUrl : string;
  fileToUpload: File = null;
  newsId: string;
  imageClick : boolean = true;
  pdfClick:boolean = true;
  company: Company

  public event: EventEmitter<any> = new EventEmitter();
  constructor(
    private dialog: MatDialog, 
    private newsService: NewsService,
    private companyService: CompanyService,
    private dialogService: DialogService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<NewsDetailComponent>,
    @Inject(MAT_DIALOG_DATA) news:News ) {

      if(news.jpg_path !== ""){
        this.imageUrl = news.jpg_path
        this.imageClick = false;
       }
       if(news.pdf_path !== ""){
        this.pdfUrl = news.pdf_path
        this.pdfClick = false;
       }

      this.newsId = news.id.toString();
      if(news.status == "draft"){
        this.status = "publish"
      }else{
        this.status = "draft"
      }

    this.form = fb.group({
        id: news.id,
        company_id: news.company_id,
        title: [news.title, Validators.required],
        body: [news.body, Validators.required],
        status: news.status
    });

}

ngOnInit() {
  console.log(this.companyService.currentCompanyValue)
  if (this.companyService.currentCompanyValue) { 
    this.subscription = this.companyService.currentCompany.subscribe((data) => {
      this.company = data
      console.log(data)})
    }else{
      this.subscription = this.companyService.getCompanyById(this.form.value.company_id).subscribe((data) => {
       this.company = data
       console.log(data)
      })
    }
}
ngOnDestroy(){
  this.subscription.unsubscribe()
}

isFieldInvalid(field: string) {
  return (
    (!this.form.get(field).valid && this.form.get(field).touched)
  );
}

onFileChanged(file: FileList) {
  //this.selectedFile = event.target.files[0]
  if(file){
    this.fileToUpload = file.item(0)
    //console.log(this.selectedFile)
    //show image preview
    var reader = new FileReader();
    reader.onload = (event:any) =>{
      this.imageUrl = event.target.result
    }
    reader.readAsDataURL(this.fileToUpload)
    this.newsService.updateNewsImage(this.newsId, this.fileToUpload).subscribe(
      (data) => {
        //console.log(data)
        if(data.OK == "YES"){
          this.imageClick = false;
          this.form.value.jpg_path = "https://shopengros.com/database/engros/news/jpg/" + this.newsId + ".jpg"
          this.event.emit({data: this.form.value});
        }
      }
    )

    console.log(this.fileToUpload)
  }
}


onPdfFileChanged(file: FileList) {
  //this.selectedFile = event.target.files[0]
  if(file){
    this.fileToUpload = file.item(0)
    //console.log(this.selectedFile)
    //show image preview
    var reader = new FileReader();
    reader.onload = (event:any) =>{
      this.pdfUrl = event.target.result
    }
    //this.pdfClick = false;
    reader.readAsDataURL(this.fileToUpload)
    this.newsService.updateNewsPdf(this.newsId, this.fileToUpload).subscribe(
      (data) => {
        //console.log(data)
        if(data.OK == "YES"){
          this.pdfClick = false;
          this.form.value.pdf_path = "https://shopengros.com/database/engros/news/pdf/" + this.newsId + ".pdf"
          this.event.emit({data: this.form.value});
        }
      }
    )

    console.log(this.fileToUpload)
  }
}


  save() {
    console.log(this.form.value)  
    this.event.emit({data: this.form.value});
    this.dialogRef.close();
    /*this.newsService.createNews(this.form.value).subscribe(
    (data) => this.dialogRef.close(this.form.value)
    )*/
    
  }

  /*changeStatus(){
    //if status is publish, then check for subscriber and if not subscribe remind them to subscribe or invoice.
    this.dialogService.openConfirmDialog(this.translate.instant("phrases.sure_change_status") + " " + this.translate.instant(this.status) + " " + this.translate.instant("denne")  + " " + this.translate.instant("news"))
    .afterClosed().subscribe(res=>{
      if(res){
        if(this.status == "publish"){
                this.form.value.status = "published"
                this.event.emit({data: this.form.value});
                this.dialogRef.close();
        }else{
          this.form.value.status = this.status
          this.event.emit({data: this.form.value});
          this.dialogRef.close();
        }
        
        console.log(this.form.value) 
      }
    })
  }*/

  changeStatus(){
    //if status is publish, then check for subscriber and if not subscribe remind them to subscribe or invoice.

    if(this.status == "publish" && this.company.b2b == "0"){
    
        let dialogRef = this.dialog.open(NewsPublishComponent, {
          width: '600px',
          disableClose: true
        });
      dialogRef.componentInstance.event.subscribe((result) => {
        //console.log(result.data)
        this.form.value.status = "published"
        this.form.value.target_group = result.data.target_group
        this.form.value.selected_shops = result.data.selected_shops
       
       // console.log(this.form.value)
        this.event.emit({data: this.form.value});
        this.dialogRef.close();
      });
    }else{
      this.dialogService.openConfirmDialog(this.translate.instant("phrases.sure_change_status") + " " + this.translate.instant(this.status) + " " + this.translate.instant("denne")  + " " + this.translate.instant("news"))
      .afterClosed().subscribe(res=>{
        if(res){
          if(this.status == "publish" && this.company.b2b == "1"){
            this.form.value.status = "published"
            this.form.value.target_group = "all"
            this.event.emit({data: this.form.value});
            this.dialogRef.close();
          }else{
          this.form.value.status = this.status
          this.event.emit({data: this.form.value});
          this.dialogRef.close();
        }
      }
      })
    }
  }

  deleteImage(){
    this.dialogService.openConfirmDialog(this.translate.instant("phrases.sure_delete_image"))
    .afterClosed().subscribe(res=>{
      if(res){
        this.imageClick = true;
        this.newsService.deleteImage(this.newsId).subscribe(
          data => {
            this.form.value.jpg_path = ""
            this.event.emit({data: this.form.value});
            this.imageUrl = "/assets/imgs/defaultImg.jpg"
          })
      }
    })
    


  }

  deletePdf(){
    this.dialogService.openConfirmDialog(this.translate.instant("phrases.sure_delete_pdf"))
    .afterClosed().subscribe(res=>{
      if(res){
      this.pdfClick = true;
      this.newsService.deletePdf(this.newsId).subscribe(
        data => {
          this.form.value.pdf_path = ""
          this.event.emit({data: this.form.value});
          this.pdfUrl = ""
        })
      }
  })
  


  }
  
  close() {
      this.dialogRef.close();
  }

}
