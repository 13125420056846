import { Injectable } from '@angular/core';
import {OfferResponse} from '../shared/offerResponse';
import { Observable, of} from 'rxjs';
import { delay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

interface responseData{
  OK: string,
  responses: OfferResponse[]
}

@Injectable({
  providedIn: 'root'
})
export class ResponseOrderService {

  constructor(private http: HttpClient) { }

  getOfferResponse(id:string): Observable<responseData>{
    return this.http.get<responseData>('server/engros_response.php?action=list_offers&offer_id='+ id);
  }

  updateOfferResponse(offerResponse: OfferResponse): Observable<OfferResponse>{
    let formData: FormData = new FormData()
    formData.append("id", offerResponse.id.toString())
    formData.append("engros_company_customer_id",offerResponse.engros_company_customer_id)
    formData.append("note", offerResponse.note)
    formData.append("done", offerResponse.done)
    formData.append("action", "update_offer_response")
    return this.http.post<OfferResponse>('server/engros_response.php', formData);
  }


  getNotificationResponse(id:string): Observable<responseData>{
    return this.http.get<responseData>('server/engros_response.php?action=list_notifications&notification_id='+ id);
  }

  updateNotificationResponse(offerResponse: OfferResponse): Observable<OfferResponse>{
    let formData: FormData = new FormData()
    formData.append("id", offerResponse.id.toString())
    formData.append("engros_company_customer_id",offerResponse.engros_company_customer_id)
    formData.append("note", offerResponse.note)
    formData.append("done", offerResponse.done)
    formData.append("action", "update_notification_response")
    return this.http.post<OfferResponse>('server/engros_response.php', formData);
  }

  
}