<div class="content-container">
          <mat-icon id="close-icon" (click) = "closeDialog()">close</mat-icon>
          
        <mat-list>
                <h3 matSubheader>{{'new_message' | translate}}</h3>
                <mat-list-item *ngFor="let shop of shops" (click)="showDetails(shop)">
                    <h4 matLine [style.cursor] ="'pointer'">{{ shop.shop_name }} </h4>
                     <mat-divider></mat-divider>
                </mat-list-item>
            </mat-list>  
</div>

