<mat-dialog-content [formGroup]="form" class="example-form">
        <h3>Create new shoppinglist</h3>
    
        <mat-form-field appearance="outline" class="full-width">
            <mat-label>Kundenummer</mat-label>
            <input matInput formControlName="engros_company_customer_id">
            <mat-error *ngIf="isFieldInvalid('engros_company_customer_id')">
                    Der skal indtastes kundenummer
            </mat-error>
        
        </mat-form-field>
        <mat-form-field appearance="outline" class="full-width">
                <mat-label>Navn</mat-label>
                <input matInput formControlName="shopping_list_name">
                <mat-error *ngIf="isFieldInvalid('shopping_list_name')">
                        Der skal indtastes navn
                </mat-error>
            
        </mat-form-field>
        
        </mat-dialog-content>
        
        
        <mat-dialog-actions>
        
          
          <button class="mat-raised-button"
                  (click)="close()">
              Fortryd
          </button>
          <span class="fill-space"></span>
          <button class="mat-raised-button mat-primary"
                  (click)="save()" [disabled] ="form.invalid">
              Gem
          </button>
        </mat-dialog-actions>