import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Shop } from '../shared/shop';

interface responseData{
  OK: string,
  last_contact_shop_id: string,
  shops: Shop[]
}

@Injectable({
  providedIn: 'root'
})
export class ShopService {

  constructor(private http: HttpClient) { }

  getFavoriteShopList(id: string): Observable<responseData>{
    let httpParams = new HttpParams()
                  .set("id",id);
    return this.http.get<responseData>('server/engros_company.php?action=favorite_shop_list', {params: httpParams});
  }

  getShopDetailById(id: string): Observable<Shop>{
    let httpParams = new HttpParams()
                  .set("shop_id",id);
    return this.http.get<Shop>('server/engros_company.php?action=favorite_shop_detail', {params: httpParams});
  }

  deleteFavoriteShop(company_id:string, shop:Shop): Observable<Shop>{
    let httpParams = new HttpParams()
                    .set("id", company_id)
                    .set("shop_id", shop.id.toString())
                    .set("contact_shop_id", shop.contact_shop_id);
    return this.http.get<Shop>('server/engros_company.php?action=delete_favorite_shop', {params: httpParams});
  }

  searchShops(name: string, company_id: string): Observable<responseData> {
    let httpParams = new HttpParams()
    .set("search_value",name)
    .set("id",company_id );
    return this.http.get<responseData>('server/engros_company.php?action=search_shops', {params: httpParams})
  }

  requestFavorite(shop_id: number, company_id: string): Observable<Shop>{
    let formData: FormData = new FormData()
    formData.append("shop_id",shop_id.toString())
    formData.append("id", company_id)
    formData.append("action", "request_favorite")
    return this.http.post<Shop>('server/engros_company.php', formData)
  }

  createShop(shop:Shop, company_id:string): Observable<responseData>{
    let formData: FormData = new FormData()
    formData.append("name",shop.name)
    formData.append("cvr", shop.cvr)
    formData.append("address",shop.address)
    formData.append("zip", shop.zip)
    formData.append("city", shop.city)
    formData.append("phone", shop.phone.replace(/\s/g, ""))
    formData.append("email", shop.email.toLowerCase())
    formData.append("contact_name",shop.contact_name)
    formData.append("contact_phone", shop.contact_phone.replace(/\s/g, ""))
    formData.append("locale", "da")
    formData.append("id", company_id)
    formData.append("action", "create_shop");
    //let options = new RequestOptions({ headers: httpHeaders,withCredentials:true});
    return this.http.post<responseData>('server/engros_company.php', formData);
  }

  addSalerShop(shop_id,contact_id): Observable<responseData>{
    let formData: FormData = new FormData()
    formData.append("shop_id",shop_id)
    formData.append("contact_id", contact_id)
    formData.append("action", "request_add_saler")
    return this.http.post<responseData>('server/engros_company.php', formData)

  }
  updateSalerShop(id,contact_id): Observable<responseData>{
    let formData: FormData = new FormData()
    formData.append("contact_shop_id",id)
    formData.append("contact_id", contact_id)
    formData.append("action", "request_update_saler")
    return this.http.post<responseData>('server/engros_company.php', formData)

  }
}
