import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError} from 'rxjs/operators';
import { Offer } from '../shared/offer';

interface responseData{
  OK: string,
  offers: Offer[]
}

interface responseOK{
  OK: {}
}

@Injectable({
  providedIn: 'root'
})
export class OfferService {

  constructor(private http: HttpClient) { }

  getOffersByProfileId(id: string): Observable<responseData>{
    let httpParams = new HttpParams()
                  .set("company_id",id);
    return this.http.get<responseData>('server/engros_offer.php?action=company_based_list', {params: httpParams});
  }

  getOffers(): Observable<responseData>{
    return this.http.get<responseData>('server/engros_offer.php?action=list');
  }
  getOffer(id:any): Observable<any>{
    //return of(DISHES.map(dish => dish.id));
    return this.getOffers().pipe(map(data => data.offers.filter((offer: Offer) => offer.id == id)[0]))
      .pipe(catchError(error => error))
  }

  readOffer(id:string): Observable<Offer>{
    //return of(DISHES.map(dish => dish.id));
    let formData: FormData = new FormData()
    formData.append("id",id)
    formData.append("action", "read")
    return this.http.post<Offer>('server/engros_offer.php', formData);
  }

  createOffer(offer): Observable<Offer>{
    let formData: FormData = new FormData()
    formData.append("title",offer.title)
    formData.append("body", offer.body)
    formData.append("company_id", offer.company_id)
    formData.append("status", offer.status)
    formData.append("from_date", offer.from_date)
    formData.append("to_date", offer.to_date)
    formData.append("price", offer.price)
    formData.append("currency", offer.currency)
    formData.append("offer_web_link", offer.offer_web_link)
    formData.append("action", "create")
    return this.http.post<Offer>('server/engros_offer.php', formData);
  }

  updateOffer(offer: Offer) : Observable<Offer>{
    let formData: FormData = new FormData()
    formData.append("id", offer.id.toString())
    formData.append("title",offer.title)
    formData.append("body", offer.body)
    formData.append("company_id", offer.company_id.toString())
    formData.append("status", offer.status)
    formData.append("from_date", offer.from_date)
    formData.append("to_date", offer.to_date)
    formData.append("price", offer.price)
    formData.append("currency", offer.currency)
    formData.append("offer_web_link", offer.offer_web_link)
    formData.append("action", "update")
    return this.http.post<Offer>('server/engros_offer.php', formData);
  }

  publishOffer(offer: Offer) : Observable<Offer>{
    let formData: FormData = new FormData()
    formData.append("id", offer.id.toString())
    formData.append("title",offer.title)
    formData.append("body", offer.body)
    formData.append("company_id", offer.company_id.toString())
    formData.append("status", offer.status)
    formData.append("from_date", offer.from_date)
    formData.append("to_date", offer.to_date)
    formData.append("price", offer.price)
    formData.append("currency", offer.currency)
    formData.append("offer_web_link", offer.offer_web_link)
    formData.append("target_group", offer.target_group)
    formData.append("selected_shops", offer.selected_shops)
    formData.append("action", "publish")
    return this.http.post<Offer>('server/engros_offer.php', formData);
  }

  updateStatusOffer(offer: Offer) : Observable<Offer>{
    let formData: FormData = new FormData()
    formData.append("id", offer.id.toString())
    formData.append("title",offer.title)
    formData.append("body", offer.body)
    formData.append("company_id", offer.company_id.toString())
    formData.append("status", "deleted")
    formData.append("from_date", offer.from_date)
    formData.append("to_date", offer.to_date)
    formData.append("price", offer.price)
    formData.append("currency", offer.currency)
    formData.append("offer_web_link", offer.offer_web_link)
    formData.append("action", "update")
    return this.http.post<Offer>('server/engros_offer.php', formData);
  }

  deleteOffer(id:string): Observable<Offer>{
    let httpParams = new HttpParams().set("id", id);
    return this.http.get<Offer>('server/engros_offer.php?action=delete', {params: httpParams});
  }

  updateOfferImage(id: string, fileToUpload: File): Observable<responseOK>{
    const formData: FormData = new FormData();
    formData.append('userfile', fileToUpload);
    formData.append('id', id);
    return this.http.post<responseOK>('server/engros_offer.php?action=save_jpg', formData)
  }

  deleteImage(id:string){
    let httpParams = new HttpParams().set("id", id);
    return this.http.get('server/engros_offer.php?action=delete_jpg', {params: httpParams});
  }
}
