<div fxLayout="column" fxFlex="80" fxFlexOffset="8" fxFlex.xs="auto" fxFlexOffset.xs="1" *ngIf='company'>
      <mat-card class="example-card mat-elevation-z1" >
          <mat-card-header>
              
                <input style="display: none" [disabled]="!imageClick" type="file" (change)="onFileChanged($event.target.files)"
                  #fileInput accept="image/*">
                <!--<mat-icon style="margin-left:auto; display: flex;" *ngIf="!imageClick" (click) = "deleteImage()">close</mat-icon>-->
                <img  mat-card-avatar [src]="imageUrl"  (click)="fileInput.click()">
                  
              
            <!--<img mat-card-avatar src="https://material.angular.io/assets/img/examples/shiba2.jpg">-->
            <mat-card-title>{{company.name}}</mat-card-title> 
            <mat-card-subtitle>CVR: {{company.cvr}}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <p>
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Kategori</mat-label>
                <input matInput value="{{company.company_category_name}}" disabled>
              </mat-form-field>
            </p>

            <p fxLayout="row" fxLayout.xs="column" >
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Adresse</mat-label>
                  <input matInput value="{{company.address}}" [(ngModel)]="company.address">
                </mat-form-field>
            </p>

            <p fxLayout="row" fxLayout.xs="column" >
                  <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Postnr.</mat-label>
                  <input matInput value="{{company.zip}}" [(ngModel)]="company.zip">
                </mat-form-field>
              
              <mat-form-field appearance="outline" class="full-width">
                  <mat-label>By</mat-label>
                  <input matInput value="{{company.city}}" [(ngModel)]="company.city">
                </mat-form-field>       
            </p>

            <p>
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Land</mat-label>
                <input matInput value="{{company.country}}" [(ngModel)]="company.country">
              </mat-form-field>
            </p>

            <p fxLayout="row" fxLayout.xs="column" >
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Telefon</mat-label>
                <input matInput value="{{company.phone}}" [(ngModel)]="company.phone">
              </mat-form-field>
              <span class="fill-space"></span>
              <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Email</mat-label>
                  <input matInput value="{{company.email}}" [(ngModel)]="company.email">
                </mat-form-field>
          </p>

            <p>
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Hjemmeside</mat-label>
                  <input matInput value="{{company.web}}" [(ngModel)]="company.web">
                </mat-form-field>
              </p>

              <p fxLayout="row" fxLayout.xs="column">
                <mat-form-field appearance="outline" class="full-width">
                  <mat-label>Bestilling email</mat-label>
                  <input matInput value="{{company.order_email}}" [(ngModel)]="company.order_email">
                </mat-form-field>
                <span class="fill-space"></span>
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Bestilling SMS</mat-label>
                    <input matInput value="{{company.order_sms}}" [(ngModel)]="company.order_sms">
                  </mat-form-field>
                </p>
              
              <p>
                  <mat-form-field appearance="outline" class="full-width">
                    <mat-label>Leveringstid</mat-label>
                    <textarea matInput [(ngModel)]="company.our_delivery_times">{{company.our_delivery_times}}</textarea>
                  </mat-form-field>
                </p>

                <p>
                    <mat-form-field appearance="outline" class="full-width">
                      <mat-label>Åbningstider</mat-label>
                      <textarea matInput [(ngModel)]="company.our_office_opening_hours">{{company.our_office_opening_hours}}</textarea>
                    </mat-form-field>
                  </p>
                        
                          
                          
                   <!-- <p>
                        <mat-form-field appearance="outline" class="full-width">
                          <mat-label>Contact Person Name</mat-label>
                          <input matInput value="{{company.contact_name}}" [(ngModel)]="company.contact_name">
                        </mat-form-field>
                      </p>
                          
                      <p>
                          <mat-form-field appearance="outline" class="full-width">
                            <mat-label>Contact person email</mat-label>
                            <input matInput value="{{company.contact_email}}" [(ngModel)]="company.contact_email">
                          </mat-form-field>
                        </p>
  
  
                        <p>
                            <mat-form-field appearance="outline" class="full-width">
                              <mat-label>Contact person mobile</mat-label>
                              <input matInput value="{{company.contact_phone}}" [(ngModel)]="company.contact_phone">
                            </mat-form-field>
                          </p>

                        -->
                          <p>
                              <mat-form-field appearance="outline" class="full-width">
                                <mat-label>Note</mat-label>
                                <textarea matInput [(ngModel)]="company.notes">{{company.notes}}</textarea>
                              </mat-form-field>
                            </p> 
                          
                  
            </mat-card-content>
            <mat-card-actions>
              <p> <mat-slide-toggle [(ngModel)]="smsOnly">Vil du modtage ordre på sms?</mat-slide-toggle></p> 
             <!-- <p> <mat-slide-toggle [(ngModel)]="company.allow_ratings">Tillad ratings og anmeldelser?</mat-slide-toggle></p> -->
              <button mat-raised-button color="primary" class="float-right" (click)="updateCompany()">GEM</button>
            </mat-card-actions>
          </mat-card>
  </div>


  <!--<form  class="normal-form" (submit)="onSubmit()">
    <mat-grid-list cols="2" rowHeight="300px">
      <mat-grid-tile>
        <div class="controles-container">
          <mat-form-field>
            <input  matInput placeholder="Full Name*">
            <mat-error>This field is mandatory.</mat-error>
          </mat-form-field>
          <mat-form-field>
            <input  matInput placeholder="Email">
            <mat-error>Invalid email address.</mat-error>
          </mat-form-field>
          <mat-form-field>
            <input  matInput placeholder="Mobile*">
           </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="City">
          </mat-form-field>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="controles-container">
          <div class="add-bottom-padding">
            <mat-radio-group >
              <mat-radio-button value="1">Male</mat-radio-button>
              <mat-radio-button value="2">Female</mat-radio-button>
              <mat-radio-button value="3">Other</mat-radio-button>
            </mat-radio-group>
          </div>
          <mat-form-field>
            <mat-select  placeholder="Department">
              <mat-option>None</mat-option>
              
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <input  matInput [matDatepicker]="picker" placeholder="Hire Date">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          
          <div class="button-row">
            <button mat-raised-button color="primary" type="submit" >Submit</button>
            <button mat-raised-button color="warn" (click)="onClear()">Clear</button>
          </div>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </form>-->
