<mat-dialog-content class="example-form">
  <h4>Vælg kunder</h4>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)"
                      [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="shop_name">
      <th mat-header-cell *matHeaderCellDef> Buttiksnavn </th>
      <td mat-cell *matCellDef="let element"> {{element.shop_name}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        (click)="selection.toggle(row)">
    </tr>
  </table>
</mat-dialog-content>
<mat-dialog-actions>

  <button class="mat-raised-button"
          (click)="close()">
      Fortryd
  </button>
  <span class="fill-space"></span>

<button class="mat-raised-button mat-primary"
          (click)="publish()" [disabled] ="!selection.hasValue()">
      Publish
  </button>

</mat-dialog-actions>