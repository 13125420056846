import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UserService} from '../services/user.service';

@Component({
  selector: 'app-user-create-dialog',
  templateUrl: './user-create-dialog.component.html',
  styleUrls: ['./user-create-dialog.component.scss']
})
export class UserCreateDialogComponent implements OnInit {

  addUser = {
    id: 0,
    name: ''
  };

  public event: EventEmitter<any> = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<UserCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public user: any,
    public userService: UserService
  ) { }

  onNoClick(): void{
    this.dialogRef.close();
  }

  onSubmit(): void{
   // this.addUser.id = this.userService.dataLength() + 1;
    this.event.emit({data: this.addUser});
    this.dialogRef.close();
  }
  ngOnInit() {
  }

}
