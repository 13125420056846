<mat-dialog-content [formGroup]="form" class="example-form">

    <mat-form-field>

        <input matInput
                placeholder="Title"
               formControlName="title">

    </mat-form-field>
    <mat-form-field>

            <textarea matInput placeholder="Body"
                formControlName="body">
    
            </textarea>
    
        </mat-form-field>

        <mat-form-field>
        <input matInput [matDatepicker]="myDatepicker" placeholder="Choose start date"
            formControlName="from_date">

        <mat-datepicker-toggle matSuffix
                               [for]="myDatepicker">

        </mat-datepicker-toggle>

        <mat-datepicker #myDatepicker></mat-datepicker>

    </mat-form-field>
    <mat-form-field>

        <input matInput [matDatepicker]="myDatepicker2" placeholder="Choose end date"
            formControlName="to_date">

        <mat-datepicker-toggle matSuffix
                               [for]="myDatepicker2">

        </mat-datepicker-toggle>

        <mat-datepicker #myDatepicker2></mat-datepicker>

    </mat-form-field>

    <mat-form-field>

        <mat-select placeholder="Select status"
            formControlName="status">

            <mat-option value="draft">
                Draft</mat-option>
            <mat-option value="published">
                Published</mat-option>
            <mat-option value="deleted">
                Deleted</mat-option>

        </mat-select>

    </mat-form-field>

    <mat-form-field>

            <mat-select placeholder="Select economy"
            formControlName="economy">

            <mat-option value="free">
                Free</mat-option>
            <mat-option value="invoice">
                Invoice</mat-option>
            <mat-option value="paid">
                Paid</mat-option>

        </mat-select>


    </mat-form-field>

</mat-dialog-content>


<mat-dialog-actions>

    <button class="mat-raised-button"
            (click)="close()">
        Close
    </button>

    <button class="mat-raised-button mat-primary"
            (click)="save()">
        Save
    </button>

</mat-dialog-actions>