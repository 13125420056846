import { Component, EventEmitter, Inject, OnInit, OnDestroy } from '@angular/core'
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatNativeDateModule, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material"
import {Offer} from "../shared/offer"
import {FormBuilder, Validators, FormGroup, FormControl} from "@angular/forms"
import { OfferService } from '../services/offer.service'
import { validateBasis } from '@angular/flex-layout';
import {DatePipe} from '@angular/common'
import {Company} from '../shared/company'
import { CompanyService } from '../services/company.service'
import {DialogService} from '../services/dialog.service'
import { TranslateService } from '@ngx-translate/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { OfferPublishComponent } from '../offer-publish/offer-publish.component';
import { take } from 'rxjs/operators'
import { Subscription } from 'rxjs'

export const MY_FORMATS = {
  parse:{
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput:'DD MMMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};



@Component({
  selector: 'app-offer-detail',
  templateUrl: './offer-detail.component.html',
  styleUrls: ['./offer-detail.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    {provide: MAT_DATE_LOCALE, useValue: 'da'},
  ],
})
export class OfferDetailComponent implements OnInit, OnDestroy {

  private subscription : Subscription
  form: FormGroup;
  selectedFile: File
  imageUrl: string = "/assets/imgs/defaultImg.jpg"
  fileToUpload: File = null;
  offerId: string;
  status: string;
  company: Company
  company_id: number;
  subscriber: boolean = false;
  imageClick : boolean = true;
  minDate = new Date()
  toMinDate = new Date()
  disabled = true
  
  public event: EventEmitter<any> = new EventEmitter();
  constructor(
    private dialog: MatDialog, 
    private offerService: OfferService,
    private companyService: CompanyService,
    private dialogService: DialogService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<OfferDetailComponent>,
    @Inject(MAT_DIALOG_DATA) offer:Offer ) 
  {
    this.company_id = offer.company_id;
    this.offerId = offer.id.toString()

    if(offer.jpg_path !== ""){
     this.imageUrl = offer.jpg_path
     this.imageClick = false;
    }
    if(offer.status == "draft"){
      this.status = "publish"
    }else{
      this.disabled = false
      this.status = "draft"
    }


    this.form = fb.group({
        id: offer.id,
        company_id: offer.company_id,
        title: [offer.title, Validators.required],
        body: offer.body,
        status: offer.status,
        offer_web_link: offer.offer_web_link,
        price: offer.price,
        currency: 'DKK',
        from_date: [offer.from_date, Validators.required],
        to_date: [offer.to_date, Validators.required],
        target_group:offer.target_group,
        selected_shops:''
    });

  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched)
    );
  }
  

  onFileChanged(file: FileList) {
    //this.selectedFile = event.target.files[0]
    if(file){
      this.fileToUpload = file.item(0)
      //console.log(this.selectedFile)
      //show image preview
      var reader = new FileReader();
      reader.onload = (event:any) =>{
        this.imageUrl = event.target.result
      }
      reader.readAsDataURL(this.fileToUpload)
      this.offerService.updateOfferImage(this.offerId, this.fileToUpload).subscribe(
        (data) => {
          //console.log(data)
          if(data.OK == "YES"){
            this.imageClick = false;
            this.form.value.jpg_path = "https://shopengros.com/database/engros/offer/jpg/" + this.offerId + ".jpg"
            this.event.emit({data: this.form.value});
          }
        }
      )

      console.log(this.fileToUpload)
    }
  }

  ngOnInit() {
    //console.log(this.companyService.currentCompanyValue)
  if (this.companyService.currentCompanyValue) { 
    this.subscription = this.companyService.currentCompany.subscribe((data) => {
      this.company = data
      console.log(data)})
    }else{
      this.subscription = this.companyService.getCompanyById(this.form.value.company_id).subscribe((data) => {
       this.company = data
       console.log(data)
      })
    }
  }
  ngOnDestroy(){
    this.subscription.unsubscribe()
  }

  save() {
    console.log(this.form.value)  
    this.form.value.from_date =  new DatePipe('en-Us').transform(this.form.value.from_date, 'yyyy-MM-dd 00:00:00')
    this.form.value.to_date = new DatePipe('en-Us').transform(this.form.value.to_date, 'yyyy-MM-dd 00:00:00')
    this.event.emit({data: this.form.value});
    this.dialogRef.close();
    /*this.newsService.createNews(this.form.value).subscribe(
    (data) => this.dialogRef.close(this.form.value)
    )*/
    
  }

  changeStatus(){
    //if status is publish, then check for subscriber and if not subscribe remind them to subscribe or invoice.

    if(this.status == "publish" && this.company.b2b == "0"){
    
        let dialogRef = this.dialog.open(OfferPublishComponent, {
          width: '600px',
          disableClose: true
        });
      dialogRef.componentInstance.event.subscribe((result) => {
        console.log(result.data)
        this.form.value.status = "published"
        this.form.value.target_group = result.data.target_group
        this.form.value.selected_shops = result.data.selected_shops
        this.form.value.from_date =  new DatePipe('en-Us').transform(this.form.value.from_date, 'yyyy-MM-dd 00:00:00')
        this.form.value.to_date = new DatePipe('en-Us').transform(this.form.value.to_date, 'yyyy-MM-dd 00:00:00')
       
        console.log(this.form.value)
        this.event.emit({data: this.form.value});
        this.dialogRef.close();
      });
    }
    else{
      this.dialogService.openConfirmDialog(this.translate.instant("phrases.sure_change_status") + " " + this.translate.instant(this.status) + " " + this.translate.instant("dette")  + " " + this.translate.instant("offer"))
      .afterClosed().subscribe(res=>{
        if(res){
          this.form.value.from_date =  new DatePipe('en-Us').transform(this.form.value.from_date, 'yyyy-MM-dd 00:00:00')
          this.form.value.to_date = new DatePipe('en-Us').transform(this.form.value.to_date, 'yyyy-MM-dd 00:00:00')
         
          if(this.status == "publish" && this.company.b2b == "1"){
            this.form.value.status = "published"
            this.form.value.target_group = "all"
            this.event.emit({data: this.form.value});
            this.dialogRef.close();
          }else{
          this.form.value.status = this.status
          this.event.emit({data: this.form.value});
          this.dialogRef.close();
          }
        }
      })
    }
    /*this.dialogService.openConfirmDialog(this.translate.instant("phrases.sure_change_status") + " " + this.translate.instant(this.status) + " " + this.translate.instant("dette")  + " " + this.translate.instant("offer"))
    .afterClosed().subscribe(res=>{
      if(res){
        this.form.value.from_date =  new DatePipe('en-Us').transform(this.form.value.from_date, 'yyyy-MM-dd 00:00:00')
        this.form.value.to_date = new DatePipe('en-Us').transform(this.form.value.to_date, 'yyyy-MM-dd 00:00:00')
      
        if(this.status == "publish"){
          this.form.value.status = "published"
          this.event.emit({data: this.form.value});
          this.dialogRef.close();
        }else{
        this.form.value.status = this.status
        this.event.emit({data: this.form.value});
        this.dialogRef.close();
        }
        
        console.log(this.form.value) 
      
    })*/
  }

  deleteImage(){
    this.dialogService.openConfirmDialog(this.translate.instant("phrases.sure_delete_image"))
    .afterClosed().subscribe(res=>{
      if(res){
      this.imageClick = true;
      this.offerService.deleteImage(this.offerId).subscribe(
        data => {
          this.form.value.jpg_path = ""
          this.event.emit({data: this.form.value});
          this.imageUrl = "/assets/imgs/defaultImg.jpg"
        })

      }
    })
  }
  
  
  close() {
      this.dialogRef.close();
  }
}