import { NodeStringDecoder } from "string_decoder";

export class Message {
    id: number;
    company_id: number;
    shop_id: number;
    shop_name: string;
    app_user_name: string;
    body: string;
    sending: string;
    status: string;
    create_date: Date;
  
    /*constructor(content: string, avatar: string, timestamp?: Date){
      this.content = content;
      this.timestamp = timestamp;
      this.avatar = avatar;
    }*/
  }