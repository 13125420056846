import { Component, OnInit } from '@angular/core';
import {NewsService } from '../services/news.service'
import { News } from '../shared/news';
import {NewsAddComponent} from '../news-add/news-add.component';
import {MatDialog, MatDialogConfig} from '@angular/material'
import { NewsDetailComponent } from '../news-detail/news-detail.component';
import { DialogService } from '../services/dialog.service';
import{ SnackbarService } from '../services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource } from '@angular/material';
//import { visibility, flyInOut, expand } from '../animations/app.animation';



@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  isWait: Boolean = true
  news: News[];
  id: string;
  newsObject = {
    title : "Ny nyhed ... ",
    body: "",
    status : "draft",
    company_id : localStorage.getItem("profile_id")
  };

  dataSource:any
  displayedColumns: string[] = ['title', 'status', 'create_date', 'delete'];
 

  constructor(
    private newsService: NewsService,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private translate: TranslateService,
    private snackbarService: SnackbarService) { }

  ngOnInit() {
    this.id = localStorage.getItem("profile_id")
    this.newsService.getNewsByProfileId(this.id).subscribe(
      (data) => {
        this.news = data.news.filter((news: News) => news.status != "deleted")
        this.dataSource = new MatTableDataSource<News>(this.news)
        this.isWait = false
      }
    )
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  createNews(): void{
    this.newsService.createNews(this.newsObject).subscribe(
      (data) => {
        //if(data.OK == "YES"){
          this.newsService.getNewsByProfileId(this.id).subscribe(
            (data) => {
              this.news = data.news.filter((news: News) => news.status != "deleted")
              this.dataSource = new MatTableDataSource<News>(this.news)
              this.snackbarService.success(this.translate.instant("add_success"))
          
          })
        }
      //}
      )
    
  }

  /*openCreateDialog(): void{
    let dialogRef = this.dialog.open(NewsAddComponent, {
      width: '600px',
      data: 'Add News',
      disableClose: true 
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      console.log(result.data)
      this.newsService.createNews(result.data).subscribe(
      (data) => {
        if(data.OK == "YES"){
          this.newsService.getNewsByProfileId(this.id).subscribe(
          (data) => this.news = data.news)
        }
      })
    });
  }*/

  //function with param item object
  editNews(news:News){
    let dialogRef = this.dialog.open(NewsDetailComponent, {
      width: '800px',
      disableClose: true ,
      data: news
    });

    /*dialogRef.afterClosed().subscribe(
      val => console.log("Dialog output:", val)
  );*/
  dialogRef.componentInstance.event.subscribe((result) => {
    //console.log(result.data)
    if(result.data.status == "published"){
      this.newsService.publishNews(result.data).subscribe(
        data => {
        // if(data.OK == "YES"){
          this.newsService.getNewsByProfileId(this.id).subscribe(
            (data) => {
              this.news = data.news.filter((news: News) => news.status != "deleted")
              this.dataSource = new MatTableDataSource<News>(this.news)
              this.snackbarService.success(this.translate.instant("update_success"))
            })
          }
        //}
        )
    }else{
      this.newsService.updateNews(result.data).subscribe(
        (data) => {
          if(data.OK == "YES"){
            this.newsService.getNewsByProfileId(this.id).subscribe(
            (data) => {
              this.news = data.news.filter((news: News) => news.status != "deleted")
              this.dataSource = new MatTableDataSource<News>(this.news)
              this.snackbarService.success(this.translate.instant("update_success"))
            })
            }
        })
    }

    /*this.newsService.updateNews(result.data).subscribe(
    (data) => {
      if(data.OK == "YES"){
        this.newsService.getNewsByProfileId(this.id).subscribe(
        (data) => {
          this.news = data.news.filter((news: News) => news.status != "deleted")
          this.dataSource = new MatTableDataSource<News>(this.news)
          this.snackbarService.success(this.translate.instant("update_success"))
        })
        }
    })*/
  });
  }


  delete(news:News): void{
    event.stopPropagation()
     this.dialogService.openConfirmDialog(this.translate.instant("phrases.sure_delete_news"))
     .afterClosed().subscribe(res=>{
       if(res){
        this.newsService.updateStatusNews(news).subscribe(
          data => {
            this.news.splice(this.news.indexOf(news), 1);
            this.dataSource = new MatTableDataSource<News>(this.news)
            this.snackbarService.warn(this.translate.instant("delete_success"));
            }
          //}
          )
        
       }
     })
  
  /*delete(news){
    event.stopPropagation()
    this.newsService.deleteNews(news.id).subscribe(
      (data) => {
        if(data.OK == "YES"){
          this.news.splice(this.news.indexOf(news), 1);
        }
      }
    )
    
    console.log(news);
  }*/

}

}