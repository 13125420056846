import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CompanyService} from '../services/company.service';
import { BusinessCategoryService} from'../services/business-category.service';
import { Router } from '@angular/router';
import {Company} from '../shared/company';
import {BusinessCategory} from '../shared/businessCategory';
import { from } from 'rxjs';


@Component({
  selector: 'app-add-profile',
  templateUrl: './add-profile.component.html',
  styleUrls: ['./add-profile.component.scss']
})
export class AddProfileComponent implements OnInit {

  company: Company;
  businessCategory: BusinessCategory;
  businessCategories: BusinessCategory[];
  companyForm: FormGroup;
  private formSubmitAttempt: boolean;

  constructor(private formBuilder: FormBuilder,
    private router: Router, private companyService: CompanyService,
    private businessCategoryService: BusinessCategoryService) { 
      this.createForm();
    }

  ngOnInit() {
    this.businessCategoryService.getBusinessCategory().subscribe(
      data => this.businessCategories = data.categories
    )
  }

  createForm(){
    this.companyForm = this.formBuilder.group({
      id:'',
      businessCategory: 0,
      name: ['', Validators.required],
      cvr: ['', Validators.required],
      address: ['', Validators.required],
      zip: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      phone: ['', Validators.required],
      web:['', Validators.required],
      email: ['', Validators.required],
      contact_name: ['', Validators.required],
      contact_email: ['', Validators.required],
      contact_phone: ['', Validators.required],
      our_delivery_times: '',
      our_office_opening_hours: '',
      notes: '',
      logo_path: '',
      subscriber: true
    });
  }

  isFieldInvalid(field: string) {
    return (
      (!this.companyForm.get(field).valid && this.companyForm.get(field).touched) ||
      (this.companyForm.get(field).untouched && this.formSubmitAttempt)
    );
  }

  onSubmit(){
    this.company = this.companyForm.value;
    console.log(this.company);
    this.companyService.createCompany(this.companyForm.value)
    .subscribe(data => {
      this.router.navigate(['profile']);
    })
  }

 



}
