import { Component, Inject, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {Contact} from "../shared/contact";
import {FormBuilder, Validators, FormGroup} from "@angular/forms";
import { ContactService } from '../services/contact.service'

@Component({
  selector: 'app-contact-add',
  templateUrl: './contact-add.component.html',
  styleUrls: ['./contact-add.component.scss']
})
export class ContactAddComponent implements OnInit {

  
  form: FormGroup;
  public event: EventEmitter<any> = new EventEmitter();
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ContactAddComponent>,
    @Inject(MAT_DIALOG_DATA) contact:Contact ) {

    this.form = fb.group({
        company_id: localStorage.getItem('profile_id'),
        name: ['', Validators.required],
        phone: ['', Validators.required],
        email: [''],
        job_title: ['', Validators.required]
    });

}

ngOnInit() {

}

isFieldInvalid(field: string) {
  return (
    (!this.form.get(field).valid && this.form.get(field).touched)
  );
}



save() {
  //console.log(this.form.value)  
  this.event.emit({data: this.form.value});
  this.dialogRef.close();
  /*this.newsService.createNews(this.form.value).subscribe(
  (data) => this.dialogRef.close(this.form.value)
  )*/
  
}

close() {
    this.dialogRef.close();
}
}
