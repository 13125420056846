import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { Params, ActivatedRoute } from '@angular/router';
import { ShoppingList } from '../shared/shopping_list';
import {ShoppingListService} from '../services/shopping-list.service'
import { MatDialog, MatTableDataSource, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import {FormBuilder, Validators, FormGroup} from "@angular/forms";
import { DialogService } from '../services/dialog.service';


@Component({
  selector: 'app-shopping-list',
  templateUrl: './shopping-list.component.html',
  styleUrls: ['./shopping-list.component.scss']
})
export class ShoppingListComponent implements OnInit {

  company_id: string
  shop_id: string
  shop_name: string
  shopping_list: ShoppingList[]
  dataSource: any
  displayedColumns: string[] = ['engros_company_customer_id', 'shopping_list_name','edit']
  constructor(private location: Location, private route: ActivatedRoute,private dialog: MatDialog, private shoppingListService: ShoppingListService, private dialogService: DialogService) { }

  ngOnInit() {
    this.company_id = localStorage.getItem("profile_id")
    this.route.params
    .pipe(switchMap((params: Params) => {
      this.shop_id = params['id']
      this.shop_name = params['name']
      return this.shoppingListService.getShoppingListByShopId(this.company_id, this.shop_id);
    }))
    .subscribe(
      (data) => {
        this.shopping_list = data.list
        this.dataSource = new MatTableDataSource<ShoppingList>(this.shopping_list)
      })
  }

  createShoppingList(){
    let dialogRef = this.dialog.open(DialogCreateShoppingList, {
      width: '400px',
      disableClose: true 
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      result.data.shop_id = this.shop_id
      result.data.company_id = this.company_id
      console.log(result.data)
      this.shoppingListService.addShoppingList(result.data).subscribe(
        (data) => {
          if(data.OK == "YES"){
      this.shoppingListService.getShoppingListByShopId(this.company_id, this.shop_id).subscribe(
        (data) => {
          this.shopping_list = data.list
          this.dataSource = new MatTableDataSource<ShoppingList>(this.shopping_list)
        })
        }
      })
    });
  }

  updateShoppingList(shoppingList: ShoppingList){
    let dialogRef = this.dialog.open(DialogUpdateShoppingList, {
      width: '400px',
      disableClose: true ,
      data: shoppingList
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      console.log(result.data)
      this.shoppingListService.editShoppingList(result.data).subscribe(
        (data) => {
          if(data.OK == "YES"){
      this.shoppingListService.getShoppingListByShopId(this.company_id, this.shop_id).subscribe(
        (data) => {
          this.shopping_list = data.list
          this.dataSource = new MatTableDataSource<ShoppingList>(this.shopping_list)
        })
        }
      })
    });
  }

  delete(shoppingList: ShoppingList){
    this.dialogService.openConfirmDialog("Er du sikker på slette denne indkøbsliste?")
     .afterClosed().subscribe(res=>{
       if(res){
          this.shoppingListService.deleteShoppingList(shoppingList.id).subscribe((data)=>{
            if(data.OK == "YES"){
              this.shopping_list.forEach( (item, index) => {
                if(item.id === shoppingList.id) 
                  this.shopping_list.splice(index,1);
                this.dataSource = new MatTableDataSource<ShoppingList>(this.shopping_list);
              });
            }
          })
        }
      })
  }

  goBack(): void{
    this.location.back();
  }
}

@Component({
  selector: 'dialog-create-shopping-list',
  templateUrl: './dialog-create-shopping-list.html',
  styleUrls: ['./shopping-list.component.scss']
})
export class DialogCreateShoppingList {
  company_id: string
  shop_id: string
  shoppingList: ShoppingList[]
  form: FormGroup;
  public event: EventEmitter<any> = new EventEmitter();
constructor( 
  private fb: FormBuilder,
  private dialog: MatDialog, 
  private shoppingListService: ShoppingListService,  
  private translate: TranslateService, 
  public dialogRef: MatDialogRef<DialogCreateShoppingList>) {    
    this.form = fb.group({
      shopping_list_name: ['', Validators.required],
      engros_company_customer_id: ['', Validators.required],
  });
}

isFieldInvalid(field: string) {
  return (
    (!this.form.get(field).valid && this.form.get(field).touched)
  );
}



save() {
  this.event.emit({data: this.form.value});
  this.dialogRef.close();
}

close() {
    this.dialogRef.close();
}

}

@Component({
  selector: 'dialog-update-shopping-list',
  templateUrl: './dialog-update-shopping-list.html',
  styleUrls: ['./shopping-list.component.scss']
})
export class DialogUpdateShoppingList {
  company_id: string
  shop_id: string
  shoppingList: ShoppingList[]
  form: FormGroup;
  public event: EventEmitter<any> = new EventEmitter();
constructor( 
  private fb: FormBuilder,
  private dialog: MatDialog, 
  private shoppingListService: ShoppingListService,  
  private translate: TranslateService, 
  public dialogRef: MatDialogRef<DialogCreateShoppingList>,
  @Inject(MAT_DIALOG_DATA) shoppingList:ShoppingList ) {    
    this.form = fb.group({
      shopping_list_name: [shoppingList.shopping_list_name, Validators.required],
      engros_company_customer_id: [shoppingList.engros_company_customer_id, Validators.required],
      id: shoppingList.id
  });
}

isFieldInvalid(field: string) {
  return (
    (!this.form.get(field).valid && this.form.get(field).touched)
  );
}



save() {
  this.event.emit({data: this.form.value});
  this.dialogRef.close();
}

close() {
    this.dialogRef.close();
}

}
