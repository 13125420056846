import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { News } from '../shared/news';

interface responseData{
  OK: string,
  news: News[]
}

interface responseOK{
  OK: {}
}

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private http: HttpClient) { }

  getNewsByProfileId(id: string): Observable<responseData>{
    let httpParams = new HttpParams()
                  .set("company_id",id);
    return this.http.get<responseData>('server/engros_news.php?action=company_based_list', {params: httpParams});
  }

  createNews(news): Observable<News>{
    let formData: FormData = new FormData()
    formData.append("title",news.title)
    formData.append("body", news.body)
    formData.append("status", news.status)
    formData.append("company_id", news.company_id)
    formData.append("action", "create")
    return this.http.post<News>('server/engros_news.php', formData);
  }

  updateNews(news: News) : Observable<responseOK>{
    let formData: FormData = new FormData()
    formData.append("id", news.id.toString())
    formData.append("title",news.title)
    formData.append("body", news.body)
    formData.append("status", news.status)
    formData.append("company_id", news.company_id)
    formData.append("action", "update")
    return this.http.post<responseOK>('server/engros_news.php', formData);
  }

  publishNews(news: News) : Observable<News>{
    let formData: FormData = new FormData()
    formData.append("id", news.id.toString())
    formData.append("title",news.title)
    formData.append("body", news.body)
    formData.append("status", news.status)
    formData.append("company_id", news.company_id)
    formData.append("target_group", news.target_group)
    formData.append("selected_shops", news.selected_shops)
    formData.append("action", "publish")
    return this.http.post<News>('server/engros_news.php', formData);
  }

  updateStatusNews(news: News) : Observable<News>{
    let formData: FormData = new FormData()
    formData.append("id", news.id.toString())
    formData.append("title",news.title)
    formData.append("body", news.body)
    formData.append("status", "deleted")
    formData.append("company_id", news.company_id)
    formData.append("action", "update")
    return this.http.post<News>('server/engros_news.php', formData);
  }

  deleteNews(id:string): Observable<News>{
    let httpParams = new HttpParams().set("id", id);
    return this.http.get<News>('server/engros_news.php?action=delete', {params: httpParams});
  }

  updateNewsImage(id: string, fileToUpload: File): Observable<responseOK>{
    const formData: FormData = new FormData();
    formData.append('userfile', fileToUpload);
    formData.append('id', id);
    return this.http.post<responseOK>('server/engros_news.php?action=save_jpg', formData)
  }

  deleteImage(id:string){
    let httpParams = new HttpParams().set("id", id);
    return this.http.get<News>('server/engros_news.php?action=delete_jpg', {params: httpParams});
  }

  updateNewsPdf(id: string, fileToUpload: File): Observable<responseOK>{
    const formData: FormData = new FormData();
    formData.append('userfile', fileToUpload);
    formData.append('id', id);
    return this.http.post<responseOK>('server/engros_news.php?action=save_pdf', formData)
  }

  deletePdf(id:string){
    let httpParams = new HttpParams().set("id", id);
    return this.http.get<News>('server/engros_news.php?action=delete_pdf', {params: httpParams});
  }


}
