import { Component, OnInit, Inject } from '@angular/core';
import { Product } from '../shared/product';
import { ProductService } from '../services/product.service';
import {MatDialog, MAT_DIALOG_DATA, MatCheckboxClickAction, MatCheckboxChange} from '@angular/material';
import { MatTableDataSource } from '@angular/material';
import{ SnackbarService } from '../services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductAddComponent } from '../product-add/product-add.component';
import { DialogService } from '../services/dialog.service';
import { ProductDetailComponent } from '../product-detail/product-detail.component';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  isWait: Boolean = true
  products: Product[];
  dataSource;
  id: string;
  displayedColumns: string[] = ['product_name', 'unit', 'standard_product', 'delete'];

  constructor(
    private productService: ProductService,
    private translate: TranslateService,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.id = localStorage.getItem("profile_id")
    this.productService.getProducts(this.id).subscribe(
      data => {
        this.products = data.product_list
        this.dataSource = new MatTableDataSource<Product>(this.products)
        this.isWait = false
      }
    )
  }

  createProduct(): void{
    let dialogRef = this.dialog.open(ProductAddComponent, {
      width: '800px',
      data: 'Add News',
      disableClose: true 
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      console.log(result.data)
      this.productService.addProduct(result.data).subscribe(
      (data) => {
        if(data.OK == "YES"){
          this.productService.getProducts(this.id).subscribe(
            (data) => {
              this.products = data.product_list
              this.dataSource = new MatTableDataSource<Product>(this.products)
              this.snackbarService.success(this.translate.instant("add_success"))
              })
        }
      })
    });
  }

  editProduct(product: Product): void{
    let dialogRef = this.dialog.open(ProductDetailComponent, {
      width: '800px',
      data: product,
      disableClose: true 
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      console.log(result.data)
      this.productService.updateProduct(result.data).subscribe(
      (data) => {
        if(data.OK == "YES"){
          this.productService.getProducts(this.id).subscribe(
            (data) => {
              this.products = data.product_list
              this.dataSource = new MatTableDataSource<Product>(this.products)
              this.snackbarService.success(this.translate.instant("add_success"))
              })
        }
      })
    });
  }



  delete(product: Product){
    this.dialogService.openConfirmDialog("Er du sikker på slette denne produkt?")
     .afterClosed().subscribe(res=>{
       if(res){
          this.productService.deleteProduct(product.id).subscribe((data)=>{
            if(data.OK == "YES"){
              this.products.forEach( (item, index) => {
                if(item.id === product.id) 
                  this.products.splice(index,1);
                this.dataSource = new MatTableDataSource<Product>(this.products);
              });
            }
          })
        }
      })
  }

  toggle(event: MatCheckboxChange, product: Product) {
    console.log('toggle', event.checked);
    if(event.checked)
      product.standard_product = "1"
    else
      product.standard_product = "0"
    console.log(product)

    this.productService.updateStandardProduct(product).subscribe(
      data =>{
        if(data.OK == "YES")
        this.snackbarService.success(this.translate.instant("update_success"))
      }
    )
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
