import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from '../shared/user';

import { MatDialog, MatDialogRef } from '@angular/material';
import { ForgetPasswordComponent } from '../forget-password/forget-password.component';

import { DialogService } from '../services/dialog.service';
import{ SnackbarService } from '../services/snackbar.service';
import { AuthService } from '../services/auth.service';
import{CompanyService} from '../services/company.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  private formSubmitAttempt: boolean;
  user: User;
  today: number = Date.now();

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private companyService: CompanyService,
    private snackbarService: SnackbarService,
    public dialog: MatDialog 
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      handle: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  onSubmit() {
    if (this.form.valid) {
      this.authService.login(this.form.value).subscribe( 
        (user) => {
          console.log(user);
          if(user.OK == "YES"){
            this.authService.loggedIn.next(true)
            this.authService.role.next(user.role)
            localStorage.setItem('loggedIn', 'true')
            localStorage.setItem('profile_id', user.company_id.toString())
            if(user.role == "Employee"){
              localStorage.setItem('id', user.company_contact_id)
              this.router.navigate(['employee'])
              //this.router.navigate(['employee'], { queryParams: { id: user.company_contact_id} });
            }else{
              this.router.navigate(['profile']);
            }
            
          }else{
            this.snackbarService.warn("Error email or password")
          }
          
        }, err => {
	      console.log(err);
        }
      );
    }
    
    this.formSubmitAttempt = true;
  }

  openForgetPasswordForm() {
    this.dialog.open(ForgetPasswordComponent, {width: '400px'});
  }

}
