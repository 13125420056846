import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Order } from '../shared/order';

interface responseData{
  OK: string,
  list: Order[]
}

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient) { }

  getOrdersByProfileId(id: string): Observable<responseData>{
    let httpParams = new HttpParams()
                  .set("company_id",id);
    return this.http.get<responseData>('server/engros_company_order.php?action=order_list', {params: httpParams});
  }

  getOrderDetailById(id: string): Observable<Order>{
    let httpParams = new HttpParams()
                  .set("id",id);
    return this.http.get<Order>('server/engros_company_order.php?action=order_content', {params: httpParams});
  }

  updateOrderStatus(order: Order): Observable<Order>{
    let formData: FormData = new FormData()
    formData.append("id",order.id)
    formData.append("action", "update_status")
    return this.http.post<Order>('server/engros_company_order.php', formData);
  }

  updateOrderDone(order: Order): Observable<Order>{
    let formData: FormData = new FormData()
    formData.append("id",order.id)
    formData.append("done", order.done)
    formData.append("action", "order_done")
    return this.http.post<Order>('server/engros_company_order.php', formData);
  }
}
