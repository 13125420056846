import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {Product} from "../shared/product";
import {FormBuilder, Validators, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-shopping-list-item-edit',
  templateUrl: './shopping-list-item-edit.component.html',
  styleUrls: ['./shopping-list-item-edit.component.scss']
})
export class ShoppingListItemEditComponent implements OnInit {

  productUnits = [
    {value: 'Bakke(r)'},
    {value: 'Bundt(er)'},
    {value: 'Bøtte(r)'},
    {value: 'Dnk'},  
    {value: 'Dåse(r)'},
    {value: 'Filet(er)'},
    {value: 'Flaske(r)'},
    {value: 'Fustage(r)'},
    {value: 'Gram'},
    {value: 'Ks'},
    {value: 'Kasse(r)'},
    {value: 'Krt'},
    {value: 'Karton'},
    {value: 'Kg'},
    {value: 'Par'},
    {value: 'Pk'},
    {value: 'Pakke(r)'},
    {value: 'Ps'},
    {value: 'Pose(r)'},
    {value: 'Potte(r)'},
    {value: 'Rl'},  
    {value: 'Side(r)'},  
    {value: 'Spand(e)'},
    {value: 'Stk'},  
    {value: 'Sæk'}, 
    {value: 'Æsk'},   
  ]
  form: FormGroup;
  public event: EventEmitter<any> = new EventEmitter();
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ShoppingListItemEditComponent>,
    @Inject(MAT_DIALOG_DATA) product:Product ) {

    this.form = fb.group({
        name: [product.name, Validators.required],
        unit: [product.unit, Validators.required],
        product_number: [product.product_number],
        id: product.id
    });

}

ngOnInit() {

}

isFieldInvalid(field: string) {
  return (
    (!this.form.get(field).valid && this.form.get(field).touched)
  );
}



save() {
  this.event.emit({data: this.form.value});
  this.dialogRef.close();
}

close() {
    this.dialogRef.close();
}

}
