import { Component, EventEmitter, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material"
import { Contact } from "../shared/contact"
import { FormBuilder, Validators, FormGroup } from "@angular/forms"
import { ContactService } from '../services/contact.service'
import { DialogService } from '../services/dialog.service'
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.scss']
})
export class ContactDetailComponent implements OnInit {

  form: FormGroup;
  selectedFile: File
  imageUrl: string = "/assets/imgs/defaultImg.jpg"
  fileToUpload: File = null;
  contactId: string;
  company_id: string;
  imageClick : boolean = true;

  public event: EventEmitter<any> = new EventEmitter();

  constructor(
    private contactService: ContactService,
    private dialogService: DialogService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ContactDetailComponent>,
    @Inject(MAT_DIALOG_DATA) contact: Contact ) 
  {
    /*this.company_id = contact.company_id;
    this.contactId = contact.id
*/
    if(contact.photo_path !== ""){
     this.imageUrl = contact.photo_path
     this.imageClick = false;
    }

    this.form = fb.group({
        id: contact.id,
        company_id: contact.company_id,
        job_title: [contact.job_title, Validators.required],
        name: [contact.name, Validators.required],
        phone: [contact.phone, Validators.required],
        email: [contact.email]
    });

  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched)
    );
  }
  

  onFileChanged(file: FileList) {
    //this.selectedFile = event.target.files[0]
    if(file){
      this.fileToUpload = file.item(0)
      //console.log(this.selectedFile)
      //show image preview
      var reader = new FileReader();
      reader.onload = (event:any) =>{
        this.imageUrl = event.target.result
      }
      reader.readAsDataURL(this.fileToUpload)
      this.contactService.updateContactImage(this.form.value.id, this.fileToUpload).subscribe(
        (data) => {
          //console.log(data)
          if(data.OK == "YES"){
            this.imageClick = false;
            this.form.value.photo_path = "https://engros.likesharing.dk/database/engros/company_contact/photo/" + this.form.value.id + ".jpg"
            this.event.emit({data: this.form.value});
          }
        }
      )

      console.log(this.fileToUpload)
    }
  }

  ngOnInit() {  }

  save() {
    console.log(this.form.value)  
   // this.form.value.from_date =  new DatePipe('en-Us').transform(this.form.value.from_date, 'yyyy-MM-dd 00:00:00')
   // this.form.value.to_date = new DatePipe('en-Us').transform(this.form.value.to_date, 'yyyy-MM-dd 00:00:00')
    this.event.emit({data: this.form.value});
    this.dialogRef.close();
    /*this.newsService.createNews(this.form.value).subscribe(
    (data) => this.dialogRef.close(this.form.value)
    )*/
    
  }

  deleteImage(){
    this.dialogService.openConfirmDialog(this.translate.instant("phrases.sure_delete_image"))
    .afterClosed().subscribe(res=>{
      if(res){
      this.imageClick = true;
      this.contactService.deleteContactImage(this.form.value.id).subscribe(
        data => {
          this.form.value.jpg_path = ""
          this.event.emit({data: this.form.value});
          this.imageUrl = "/assets/imgs/defaultImg.jpg"
        })

      }
    })
  }
  
  
  close() {
      this.dialogRef.close();
  }
}