<div fxLayout="column wrap" fxFlex="80" fxFlexOffset="8" fxFlex.xs="auto" fxFlexOffset.xs="1">
 
  <h3>Produkter </h3>
  <div fxLayout="row" fxLayoutAlign="start" class="card-container">
    <button button ="submit" mat-mini-fab color="primary" (click) = "createProduct()">
      <mat-icon aria-label="Contact icon-button with a add icon">add</mat-icon>
    </button>
  </div>
  
  <div class="example-container">
      <mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
        </mat-form-field>
        <mat-progress-bar mode="indeterminate" *ngIf="isWait"></mat-progress-bar>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" >

          <ng-container matColumnDef="product_name">
            <th mat-header-cell *matHeaderCellDef> Navn </th>
            <td mat-cell *matCellDef="let element" [style.color]="'navy'" (click)="editProduct(element)" [style.cursor] ="'pointer'"> {{element.name}} </td>
          </ng-container>

          <ng-container matColumnDef="unit">
            <th mat-header-cell *matHeaderCellDef> Antal </th>
            <td mat-cell *matCellDef="let element"> {{element.unit}} </td>
          </ng-container>
        
          <ng-container matColumnDef="standard_product">
            <th mat-header-cell *matHeaderCellDef> Standardprodukt </th>
            <td mat-cell *matCellDef="let element"> <mat-checkbox  [checked]="element.standard_product == '1' ? true : false " (change)="toggle($event, element)"></mat-checkbox> </td>
          </ng-container>

          <ng-container matColumnDef="delete">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element"> 
                  <button mat-icon-button class="mat-warn" (click)="delete(element)">
                      <mat-icon>delete</mat-icon>
                    </button>
                <!--<mat-icon  class="mat-primary" (click)="delete(element)">delete</mat-icon>-->
              </td>
            </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
  </div>
</div>