import { Component, EventEmitter, OnInit, Inject, Input } from '@angular/core';
import { OfferResponse } from '../shared/offerResponse';
import {FormBuilder, Validators, FormGroup, FormControl} from "@angular/forms"
import {MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource} from '@angular/material'
import { ResponseOrderService } from '../services/response-order.service';
import{ SnackbarService } from '../services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-response-interested-list-detail',
  templateUrl: './response-interested-list-detail.component.html',
  styleUrls: ['./response-interested-list-detail.component.scss']
})
export class ResponseInterestedListDetailComponent implements OnInit {

  form: FormGroup;
  offerResponse : OfferResponse
  offerTitle: string
  offer_id: string

  public event: EventEmitter<any> = new EventEmitter();

  constructor(
    private offerResponseService: ResponseOrderService,
    private translate: TranslateService,
    private snackbarService: SnackbarService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ResponseInterestedListDetailComponent>,
    @Inject(MAT_DIALOG_DATA) data:any ) 
  {
    console.log(data)
    this.offerTitle = data.offerTitle
    this.offerResponse = data.offerResponse
    this.form = fb.group({
      id: this.offerResponse.id,
      engros_company_customer_id: this.offerResponse.engros_company_customer_id,
      shop_name: this.offerResponse.shop_name,
      shop_phone: this.offerResponse.shop_phone,
      done:this.offerResponse.done == "1"?true:false,
      note: this.offerResponse.note
    });

    
  }

  onPrint() : void {
      let popupWin;
      //printContents = document.getElementById('print-section').innerHTML;
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
        <html>
          <head>
            <title>${this.offerTitle}</title>
            <style>
            </style>
          </head>
      <body onload="window.print();window.close()">
      <p>${this.offerResponse.shop_name}</p>
      <p>Kontakt name: ${this.offerResponse.shop_contact_name }  tlf.:${this.offerResponse.shop_phone}</p>
      <p>Kunde nr.: ${this.form.value.engros_company_customer_id}</p>
      <p>Afsluttet: ${this.form.value.done}</p>
      <p>Noter: ${this.form.value.note}</p>
      </body>
        </html>`
      );
      popupWin.document.close();
  }

  ngOnInit() {
    
  }

  save(){
    this.event.emit({data: this.form.value});
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
}

}
