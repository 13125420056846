<div fxLayout="column" fxFlex="80" fxFlexOffset="8" fxFlex.xs="auto" fxFlexOffset.xs="1">
    <h3>{{'news' | translate | titlecase }}</h3>
    <div fxLayout="column" fxLayoutAlign="start start" class="card-container">
      <button button ="submit" mat-mini-fab color="primary" (click) = "createNews()">
        <mat-icon aria-label="News icon-button with a add icon">add</mat-icon>
      </button>
    </div>
    <div class="example-container">
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Søg efter listen">
      </mat-form-field>
      <mat-progress-bar mode="indeterminate" *ngIf="isWait"></mat-progress-bar>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" >
    
          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef> Title </th>
            <td mat-cell  *matCellDef="let element" [style.color]="'navy'" (click)="editNews(element)" [style.cursor] ="'pointer'"> {{element.title}} </td>
          </ng-container>
          <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
              <td mat-cell *matCellDef="let element" [style.color]="'navy'" > {{element.status}} </td>
            </ng-container>
  
          <ng-container matColumnDef="create_date">
            <th mat-header-cell *matHeaderCellDef> Dato </th>
            <td mat-cell *matCellDef="let element"> 
                <p *ngIf="element.to_date != '0000-00-00 00:00:00';else noDate" [style.color]="'green'" >
                    {{element.create_date | toDateObj | date}}
                 </p>
                 <ng-template #noDate><p [style.color]="'blue'"></p></ng-template>
            </td>
          </ng-container>
          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td  mat-cell *matCellDef="let element"> 
                <button mat-icon-button class="mat-primary" (click)="delete(element)">
                    <mat-icon>delete</mat-icon>
                  </button>
            </td>
          </ng-container>
          <!--<ng-container matColumnDef="delete">
              <th  mat-header-cell *matHeaderCellDef>Slet  </th>
              <td  mat-cell *matCellDef="let element"> 
                  
              </td>
            </ng-container>-->
        
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>