<mat-dialog-content>

        <label id="example-radio-group-label">Pick your employee for this shop</label>
        <mat-radio-group
          aria-labelledby="example-radio-group-label"
          class="example-radio-group"
          [(ngModel)]="favoriteContact">
          <mat-radio-button class="example-radio-button" *ngFor="let contact of contacts" [value]="contact.name">
            {{contact.name}}
          </mat-radio-button>
        </mat-radio-group>
        
</mat-dialog-content>
<mat-dialog-actions>
        <button color="primary" mat-button mat-dialog-close>LUK</button>
        <span style="flex: 1 1 auto;"></span>
        <button mat-button color="primary" (click)="onSave()">GEM</button>
    </mat-dialog-actions>
    