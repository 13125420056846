import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Rating } from '../shared/rating';

interface responseData{
  OK: string,
  ratings: Rating[]
}

interface responseOK{
  OK: {}
}

@Injectable({
  providedIn: 'root'
})
export class RatingService {

  constructor(private http: HttpClient) { }

  getRatingsByProfileId(id: string): Observable<responseData>{
    let httpParams = new HttpParams()
                  .set("company_id",id);
    return this.http.get<responseData>('server/engros_rating.php?action=company_based_list', {params: httpParams});
  }

  sendReply(rating: Rating): Observable<responseOK>{
    let httpParams = new HttpParams().set("id", rating.id).set("reply", rating.reply);
    return this.http.get<responseOK>('server/engros_rating.php?action=reply', {params: httpParams});
  }
}
