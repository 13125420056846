import { Component, Inject, EventEmitter, OnInit, } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {Notification} from "../shared/notification";
import {FormBuilder, Validators, FormGroup} from "@angular/forms";
import { NotificationService } from '../services/notification.service'


@Component({
  selector: 'app-notification-add',
  templateUrl: './notification-add.component.html',
  styleUrls: ['./notification-add.component.scss']
})
export class NotificationAddComponent implements OnInit {

  form: FormGroup;
  public event: EventEmitter<any> = new EventEmitter();
  constructor(
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<NotificationAddComponent>,
    @Inject(MAT_DIALOG_DATA) notification:Notification ) {

    this.form = fb.group({
       company_id: localStorage.getItem('profile_id'),
        title: ['', Validators.required],
        body: ['', Validators.required],
        status: ['', Validators.required],
        economy: ['', Validators.required]
    });

}

ngOnInit() {

}



save() {
  //console.log(this.form.value)  
  this.event.emit({data: this.form.value});
  this.dialogRef.close();
  /*this.newsService.createNews(this.form.value).subscribe(
  (data) => this.dialogRef.close(this.form.value)
  )*/
  
}

close() {
    this.dialogRef.close();
}

}
