import { Component, Inject, EventEmitter, OnInit, } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {Offer} from "../shared/offer";
import {FormBuilder, Validators, FormGroup} from "@angular/forms";
//import moment = require('moment');
import * as moment from 'moment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-offer-add',
  templateUrl: './offer-add.component.html',
  styleUrls: ['./offer-add.component.scss']
})
export class OfferAddComponent implements OnInit {

  form: FormGroup;
  public event: EventEmitter<any> = new EventEmitter();
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<OfferAddComponent>,
    @Inject(MAT_DIALOG_DATA) offer:Offer ) {

    this.form = fb.group({
       company_id: localStorage.getItem('profile_id'),
        title: ['', Validators.required],
        body: ['', Validators.required],
        status: ['', Validators.required],
        economy: ['', Validators.required],
        from_date: [moment(), Validators.required],
        to_date: [moment(), Validators.required]
        
    });

}

ngOnInit() {

}



save() {
  this.form.value.from_date =  new DatePipe('en-Us').transform(this.form.value.from_date, 'yyyy-MM-dd 00:00:00')
  this.form.value.to_date = new DatePipe('en-Us').transform(this.form.value.to_date, 'yyyy-MM-dd 00:00:00')
  //console.log(this.form.value)  
  this.event.emit({data: this.form.value});
  this.dialogRef.close();
  /*this.newsService.createNews(this.form.value).subscribe(
  (data) => this.dialogRef.close(this.form.value)
  )*/
  
}

close() {
    this.dialogRef.close();
}

}
