<div fxLayout="column" fxLayoutAlign="center center" class="content">
    <div class="login-image">
      <a href="https://shopengros.com"><img mat-card-image src="/assets/imgs/logo.png" alt="logo"></a>
    </div>
    
  <mat-card>
    <mat-card-title>
      <h5 style="text-align:center;">Velkommen kære Grossist</h5>
    </mat-card-title>
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-form-field class="full-width-input">
          <input matInput placeholder="{{'username' | translate | titlecase}}" formControlName="handle" required>
          <mat-error *ngIf="isFieldInvalid('handle')">
            {{'required_user_name' | translate | titlecase}} 
          </mat-error>
        </mat-form-field>
        <!-- <p>
        <mat-form-field appearance="outline">
            <mat-label>Enter your password</mat-label>
          <input matInput type="password" placeholder="Password" formControlName="password" required>
          <mat-error *ngIf="isFieldInvalid('password')">
            Please inform your password
          </mat-error>
        </mat-form-field>
      </p> -->
        <mat-form-field class="full-width-input">
          <input matInput type="password" placeholder="{{'password' | translate | titlecase}}" formControlName="password" required>
          <mat-error *ngIf="isFieldInvalid('password')">
            {{'required_password' | translate  | titlecase}}
          </mat-error>
        </mat-form-field>
        <div layout="row" layout-align="center center">
            <button mat-raised-button color="primary" type="submit">Login</button>
        </div>
      </form>
  
    </mat-card-content>

    <mat-card-footer>
      <a mat-button (click)="openForgetPasswordForm()" >Glemt adgangskode ?</a>
      <a mat-button routerLink="/signup">Opret en konto?</a>
      <br><br>
    </mat-card-footer>
  </mat-card>
  <div style="text-align:center; margin-top:2%; margin-bottom: 30%;">Copyright © {{today | date:'yyyy'}} ShopEngros ApS </div>
</div>


