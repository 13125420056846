<div class="example-container" [class.example-is-mobile]="mobileQuery.matches">
    <mat-toolbar color="primary" class="example-toolbar" *ngIf="isLoggedIn$ | async as isLoggedIn">
      <button mat-icon-button (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>
      <h1 class="example-app-name">ShopEngros</h1>
    </mat-toolbar>
  
    <mat-sidenav-container class="example-sidenav-container"
                           [style.marginTop.px]="mobileQuery.matches ? 75 : 0" >
      <mat-sidenav #snav 
        [attr.role]="mobileQuery.matches ? 'dialog' : 'navigation'" 
        [mode]="mobileQuery.matches ? 'over' : 'side'"
        [fixedInViewport]="mobileQuery.matches" fixedTopGap="75" >
        <mat-nav-list *ngIf="isLoggedIn$ | async as isLoggedIn">
          <!--<a mat-list-item routerLink="/home">Dashboard</a>
          <a mat-list-item [routerLink]="['profile', id]">Min Profil</a>-->
          <a mat-list-item routerLink="profile" *ngIf="hasRole == 'Company'"
          routerLinkActive
          #routerLinkActiveInstance="routerLinkActive"
          [class.list-item-active]="routerLinkActiveInstance.isActive">
          <mat-icon matListIcon class="app-nav-list-icon"> account_circle </mat-icon> {{'profile' | translate | titlecase}} </a>
         
          <a mat-list-item routerLink="employee" *ngIf="hasRole == 'Employee'"
          routerLinkActive
          #routerLinkActiveInstance="routerLinkActive"
          [class.list-item-active]="routerLinkActiveInstance.isActive">
          <mat-icon matListIcon class="app-nav-list-icon"> account_circle </mat-icon> {{'profile' | translate | titlecase}} </a>
         
          <a mat-list-item routerLink="news"
          routerLinkActive
          #routerLinkActiveInstance="routerLinkActive"
          [class.list-item-active]="routerLinkActiveInstance.isActive">
          <mat-icon matListIcon class="app-nav-list-icon"> announcement </mat-icon>{{'news'| translate | titlecase}}</a>

          <a mat-list-item routerLink="offers" routerLinkActive
          #routerLinkActiveInstance="routerLinkActive"
          [class.list-item-active]="routerLinkActiveInstance.isActive">
          <mat-icon matListIcon class="app-nav-list-icon"> local_offer </mat-icon>{{'offer' | translate | titlecase}}</a>

          <a mat-list-item routerLink="notifications" routerLinkActive
          #routerLinkActiveInstance="routerLinkActive"
          [class.list-item-active]="routerLinkActiveInstance.isActive">
          <mat-icon matListIcon class="app-nav-list-icon"> notifications </mat-icon>{{'notifications' | translate | titlecase}}</a>

          <a mat-list-item routerLink="messages" routerLinkActive
          #routerLinkActiveInstance="routerLinkActive"
          [class.list-item-active]="routerLinkActiveInstance.isActive">
          <mat-icon matListIcon class="app-nav-list-icon"> message </mat-icon>{{'messages' | translate | titlecase }}</a>

          <a mat-list-item routerLink="product" routerLinkActive
          #routerLinkActiveInstance="routerLinkActive"
          [class.list-item-active]="routerLinkActiveInstance.isActive">
          <mat-icon matListIcon class="app-nav-list-icon"> subject </mat-icon>{{'products' | translate | titlecase}}</a>

          <a mat-list-item routerLink="customers"  routerLinkActive
          #routerLinkActiveInstance="routerLinkActive"
          [class.list-item-active]="routerLinkActiveInstance.isActive">
          <mat-icon matListIcon class="app-nav-list-icon"> people </mat-icon>{{'customers'| translate | titlecase}}</a>

         

          <!--<a mat-list-item routerLink="ratings" routerLinkActive
          #routerLinkActiveInstance="routerLinkActive"
          [class.list-item-active]="routerLinkActiveInstance.isActive">
          <mat-icon matListIcon class="app-nav-list-icon"> star_rate </mat-icon>Ratings</a>

          <a mat-list-item routerLink="statistics" routerLinkActive
          #routerLinkActiveInstance="routerLinkActive"
          [class.list-item-active]="routerLinkActiveInstance.isActive">
          <mat-icon matListIcon class="app-nav-list-icon"> timeline </mat-icon>Statistik</a>

          <a mat-list-item routerLink="messages" routerLinkActive
          #routerLinkActiveInstance="routerLinkActive"
          [class.list-item-active]="routerLinkActiveInstance.isActive">
          <mat-icon matListIcon class="app-nav-list-icon"> message </mat-icon>Besked</a> -->

          <a mat-list-item routerLink="order" routerLinkActive
          #routerLinkActiveInstance="routerLinkActive"
          [class.list-item-active]="routerLinkActiveInstance.isActive">
          <mat-icon matListIcon class="app-nav-list-icon"> shopping_basket </mat-icon>{{'orders'| translate | titlecase}}</a>

         <a mat-list-item routerLink="contacts" *ngIf="hasRole == 'Company'" routerLinkActive
          #routerLinkActiveInstance="routerLinkActive"
          [class.list-item-active]="routerLinkActiveInstance.isActive">
          <mat-icon matListIcon class="app-nav-list-icon"> contacts </mat-icon>{{'employees' | translate | titlecase}}</a>
          <!--<a mat-list-item routerLink="economy">Økonomi</a>-->

          <a mat-list-item (click)="onLogout()"
          routerLinkActive
          #routerLinkActiveInstance="routerLinkActive"
          [class.list-item-active]="routerLinkActiveInstance.isActive">
          <mat-icon matListIcon class="app-nav-list-icon"> logout </mat-icon>{{'logout' | translate | titlecase}}</a>
        </mat-nav-list>
      </mat-sidenav>
  
      <mat-sidenav-content>
          <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
  