import { Component, Inject, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {Contact} from "../shared/contact";
import {FormBuilder, Validators, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-product-add',
  templateUrl: './product-add.component.html',
  styleUrls: ['./product-add.component.scss']
})
export class ProductAddComponent implements OnInit {

  productUnits = [
    {value: 'Bakke(r)'},
    {value: 'Bundt(er)'},
    {value: 'Bøtte(r)'},
    {value: 'Dnk'},  
    {value: 'Dåse(r)'},
    {value: 'Filet(er)'},
    {value: 'Flaske(r)'},
    {value: 'Fustage(r)'},
    {value: 'Gram'},
    {value: 'Ks'},
    {value: 'Kasse(r)'},
    {value: 'Krt'},
    {value: 'Karton'},
    {value: 'Kg'},
    {value: 'Par'},
    {value: 'Pk'},
    {value: 'Pakke(r)'},
    {value: 'Ps'},
    {value: 'Pose(r)'},
    {value: 'Potte(r)'},
    {value: 'Rl'},  
    {value: 'Side(r)'},  
    {value: 'Spand(e)'},
    {value: 'Stk'},  
    {value: 'Sæk'}, 
    {value: 'Æsk'},   
  ]
  form: FormGroup;
  public event: EventEmitter<any> = new EventEmitter();
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ProductAddComponent>,
    @Inject(MAT_DIALOG_DATA) contact:Contact ) {

    this.form = fb.group({
        company_id: localStorage.getItem('profile_id'),
        name: ['', Validators.required],
        unit: ['', Validators.required],
        standard_product: false
    });

}

ngOnInit() {

}

isFieldInvalid(field: string) {
  return (
    (!this.form.get(field).valid && this.form.get(field).touched)
  );
}



save() {
  this.event.emit({data: this.form.value});
  this.dialogRef.close();
}

close() {
    this.dialogRef.close();
}

}
