import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Company} from '../shared/company';
import { distinctUntilChanged, map } from 'rxjs/operators';

interface responseData{
  OK: string,
  companies: Company[]
}

interface responseOk{
  OK: string
}
@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  company: Company

  dataWasLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private currentCompanySubject: BehaviorSubject<Company>
  public currentCompany: Observable<Company>;
  company_id: string = localStorage.getItem("profile_id")
 
  constructor(private http: HttpClient) {
    this.currentCompanySubject = new BehaviorSubject<Company>(this.company);
    this.currentCompany = this.currentCompanySubject.asObservable();
  }

   public get currentCompanyValue(): Company {
    return this.currentCompanySubject.value;
  }

  removeCompanyValue(){
    this.currentCompanySubject.next(null)
  }

/*    async dataPromise(): Promise<any>{
    console.log(this.latestData)
    console.log(this.dataWasLoaded.value)
    if(this.dataWasLoaded.value){
        return this.latestData;
    }else{
      return this.getCompanyById(this.company_id).subscribe((data)=>{ 
        this.dataWasLoaded.next(true)
        this.changeData(data)

     });
    }
}*/

  getCompanies(): Observable<responseData>{
    return this.http.get<responseData>('server/engros_company.php?action=list')
  }

  getCompanyById(id: string): Observable<Company>{
    let httpParams = new HttpParams()
                  .set("id",id);
    return this.http.get<Company>('server/engros_company.php?action=read', {params: httpParams}).pipe(map(company => {
      // login successful if there's a jwt token in the response
      if (company) {
          this.currentCompanySubject.next(company);
      }

      return company;
  }));;
  }

  public getCompany(): Observable<Company> {
 
    return( this.currentCompanySubject.pipe( distinctUntilChanged() ) );

}

  createCompany(company: Company): Observable<Company>{
    let httpParams = new HttpParams()
                  .set("name",company.name)
                  .set("cvr", company.cvr)
                  .set("company_category_id", company.company_category_id)
                  .set("address",company.address)
                  .set("zip", company.zip)
                  .set("city", company.city)
                  .set("country",company.country)
                  .set("phone", company.phone)
                  .set("email", company.email)
                  .set("order_email", company.order_email)
                  .set("order_sms", company.order_sms)
                  .set("web", company.web)
                 // .set("contact_name",company.contact_name)
                //  .set("contact_email", company.contact_email)
                //  .set("contact_phone", company.contact_phone)
                  .set("our_delivery_times",company.our_delivery_times)
                  .set("our_office_opening_hours", company.our_office_opening_hours)
                  .set("subscriber",company.subscriber)
                  .set("notes", company.notes)
                  .set("action", "create");
    return this.http.get<Company>('server/engros_company.php', {params: httpParams});
  }

  updateCompany(company: Company): Observable<responseOk>{

    let formData: FormData = new FormData()
    formData.append("id",company.id.toString())
    formData.append("name",company.name)
    formData.append("company_category_id", company.company_category_id)
    formData.append("cvr", company.cvr)
    formData.append("address",company.address)
    formData.append("zip", company.zip)
    formData.append("city", company.city)
    formData.append("country",company.country)
    formData.append("phone", company.phone.replace(/\s/g, ""))
    formData.append("web", company.web)
    formData.append("order_email", company.order_email.toLowerCase())
    formData.append("order_sms", company.order_sms.replace(/\s/g, ""))
    formData.append("email", company.email.toLowerCase())
    formData.append("allow_ratings", company.allow_ratings.toString())
    formData.append("notes", company.notes)
    formData.append("our_delivery_times",company.our_delivery_times)
    formData.append("our_office_opening_hours", company.our_office_opening_hours)
    formData.append("subscriber",company.subscriber)
    formData.append("send_sms_only", company.send_sms_only)
    formData.append("action", "update");
    
    return this.http.post<responseOk>('server/engros_company.php', formData);
  }


  updateLogoImage(id: string, fileToUpload: File): Observable<responseOk>{
    const formData: FormData = new FormData();
    formData.append('userfile', fileToUpload);
    formData.append('id', id);
    return this.http.post<responseOk>('server/engros_company.php?action=add_logo', formData)
  }

}
