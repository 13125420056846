import { Component, OnInit, EventEmitter } from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {FormBuilder, FormGroup, Validators, FormArray, FormControl} from '@angular/forms';
import {MatDialogRef} from "@angular/material"
import { Shop } from '../shared/shop';
import { ShopService } from '../services/shop.service';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-notification-publish',
  templateUrl: './notification-publish.component.html',
  styleUrls: ['./notification-publish.component.scss']
})
export class NotificationPublishComponent implements OnInit {

  form: FormGroup;
  shops: Shop[];
  company_id: string;
  //checked = false
  all = false;
  selected = false;
  selected_shops: any
  dataSource : any
  displayedColumns: string[] = ['select','shop_name'];
  selection = new SelectionModel<Shop>(true, []);
  public event: EventEmitter<any> = new EventEmitter();
  constructor(private dialogRef: MatDialogRef<NotificationPublishComponent>,private _formBuilder: FormBuilder, private shopService: ShopService,) { }
 

 //@ViewChild('stepper') stepper: MatStepper;

  ngOnInit() {
    
    this.form = this._formBuilder.group({
      shops: this._formBuilder.array([])
    });
    this.company_id = localStorage.getItem("profile_id")
    this.shopService.getFavoriteShopList(this.company_id).subscribe(
      data => {
        //this.shops = data.shops
        this.dataSource = new MatTableDataSource<Shop>(data.shops);
      }
    )

    this.selected_shops = <FormArray>this.form.get('shops') as FormArray;
   
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched)
    );
  }

   /** Whether the number of selected elements matches the total number of rows. */
   isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
   /** Selects all rows if they are not all selected; otherwise clear selection. */
   masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Shop): string {
    if (!row) {
      return `all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id}`;
  }

  /*move(index: number) {
    
    if(index == 2){
      this.isLinear = true
      this.stepper.selectedIndex = 1;
    }else{
      this.isLinear = false
    }

  }*/

  /*onSelectAll(event){
   // console.log(event)
    
    if(event.checked) {
      while (this.selected_shops.length) {
        this.selected_shops.removeAt(0);
     }
      this.shops.forEach(element => {
        element.checked = true;
        this.selected_shops.push(new FormControl(element.id))
      });
      this.selected = true
    //  console.log(this.selected_shops)
    }else{
      this.shops.forEach(element => {
        element.checked = false; 
      });
      while (this.selected_shops.length) {
        this.selected_shops.removeAt(0);
     }
     this.selected = false
     // console.log(this.selected_shops)
    }
  }

  onChange(event) {
   // console.log(event.source.value)
   // console.log(this.selected_shops)
     if(event.checked) {
      this.selected_shops.push(new FormControl(event.source.value))
      this.selected = true
    } else {
      const i = this.selected_shops.controls.findIndex(x => x.value === event.source.value);
      this.selected_shops.removeAt(i);
    //  console.log(i)
      this.all = false
      if(this.selected_shops.length == 0){
        this.selected = false
      }
    }
    //console.log(this.selected_shops)
  }*/

  publish() {
    //const target_group = this.all ? "all" : "selected"
    this.selection.selected.forEach(row=>  this.selected_shops.push(new FormControl(row.id)))
    this.event.emit({data: {"target_group": "selected", "selected_shops": this.selected_shops.value}});
    this.dialogRef.close();
  }
  close() {
    this.dialogRef.close();
}
}
