import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    //debugger
    return this.authService.isUserLoggedIn().pipe(
      take(1),
      map((res)=> {
        console.log(res)
        this.authService.loggedIn.next(res.OK);
        if (!res.OK) {
          this.router.navigate(['/login']);
          return false;
        }
        this.authService.companyId.next(res.id)
        this.authService.role.next(res.role)
        return true;
      })
    );

    /*return this.authService.isLoggedIn.pipe(
      take(1),
      map((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.router.navigate(['/login']);
          return false;
        }
        return true;
      })
    );*/
  }
}