import { Component, OnInit, Inject, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Order } from '../shared/order';
import { OrderService } from '../services/order.service';
import {MatDialog, MAT_DIALOG_DATA,MatDialogRef, MatCheckboxClickAction, MatCheckboxChange, MatDatepickerInputEvent} from '@angular/material';
import { MatTableDataSource } from '@angular/material';
import{ SnackbarService } from '../services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { Message } from '../shared/message';
import { MessageService } from '../services/message.service';
import { interval } from 'rxjs';
import { flatMap } from 'rxjs/operators';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit, OnDestroy {

  isWait : Boolean = true
  orders: Order[];
  dataSource;
  id: string;
  timer: any

  displayedColumns: string[] = ['shop_name', 'delivery_date', 'create_date', 'done'];
  constructor(
    private orderService: OrderService,
    private translate: TranslateService,
    private snackbarService: SnackbarService,
    public dialog: MatDialog) { 
    }

  ngOnInit() {

    this.id = localStorage.getItem("profile_id")
    this.orderService.getOrdersByProfileId(this.id).subscribe(
      data => {
        this.orders = data.list
        this.dataSource = new MatTableDataSource<Order>(this.orders)
        this.isWait = false
      }
    )
    this.pullDataFromServer()
  }
  ngOnDestroy(){
    this.timer.unsubscribe()
  }


  pullDataFromServer(){
    this.timer = interval(2*60* 1000)
    .pipe(
        flatMap(() => this.orderService.getOrdersByProfileId(this.id))
    )
    .subscribe(data => {
      this.orders = data.list
        this.dataSource = new MatTableDataSource<Order>(this.orders)
    })
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  showDetail(order:Order){
    this.orderService.getOrderDetailById(order.id).subscribe(
      data => {
        console.log(data)
        this.dialog.open(DialogOrderContent, {
          width: '600px',
          disableClose: true ,
         // data: data.text_html
          data: {html_text: data.text_html, order: order, c_id: this.id}
        })
    
      }
    )
  }

  toggle(event: MatCheckboxChange, order: Order) {
    console.log('toggle', event.checked);
    if(event.checked)
      order.done = "1"
    else
      order.done = "0"
   // console.log(order)

    this.orderService.updateOrderDone(order).subscribe(
      data =>{
        if(data.OK == "YES")
        this.snackbarService.success(this.translate.instant("update_success"))
      }
    )
  }

}

@Component({
  selector: 'dialog-order-content',
  templateUrl: 'dialog-order-content.html',
})
export class DialogOrderContent {
  @Output() 
  dateChange:EventEmitter<MatDatepickerInputEvent<any>>;

  html_text: string
  order: Order
  c_id: number
  constructor(public dialog: MatDialog, private orderService: OrderService, @Inject(MAT_DIALOG_DATA) private data: any) {
   // console.log(data)
    this.html_text = data.html_text
    this.order = data.order
    this.c_id = data.c_id
    this.callToServer()
  }

  callToServer(): void{
    this.orderService.updateOrderStatus(this.order).subscribe()
    //console.log(this.order)
  }
  /*someMethodName(date: any) {  
    console.log(new DatePipe('en-Us').transform(this.data.delivery_date, 'yyyy-MM-dd 00:00:00'))
}*/

  onPrint():void{
    let popupWin;
      //printContents = document.getElementById('print-section').innerHTML;
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
        <html>
          <head>
            <title></title>
            <style>
            </style>
          </head>
      <body onload="window.print();window.close()">
      <div>${this.html_text}</div>
      </body>
        </html>`
      );
      popupWin.document.close();
  }

  onMessage(): void{
        this.dialog.open(DialogMessageContent, {
          width: '600px',
          disableClose: true ,
          data: {order: this.order, c_id: this.c_id}
        });
  }

}

@Component({
  selector: 'dialog-message-content',
  templateUrl: 'dialog-message-content.html',
  styleUrls: ['./order.component.scss']
})
export class DialogMessageContent {

  message : Message
  text: string
  order:Order
  c_id: number
  @Output() 
  dateChange:EventEmitter<MatDatepickerInputEvent<any>>;

  constructor(private messageService: MessageService,  private translate: TranslateService, private snackbarService: SnackbarService, @Inject(MAT_DIALOG_DATA) private data: any) {
    //console.log(data)
    this.order = data.order
    this.c_id = data.c_id
  }

  sendMessage(text: string) {
    //console.log(text)
    this.message = new Message()
    this.message.company_id = this.c_id
    this.message.shop_id = this.order.shop_id
    this.message.body = text;
   // console.log(this.message)
  this.messageService.createMessage(this.message).subscribe(data => {
      if(data.OK == "YES"){
        this.snackbarService.success(this.translate.instant("success"))
        this.text = "";
      }
    })
    
  }
}