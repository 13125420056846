<mat-dialog-content [formGroup]="form" class="example-form">
  <h3>Opdatere item</h3>

  <mat-form-field appearance="outline" class="full-width">
    <mat-label>Navn</mat-label>
    <input matInput formControlName="name">
    <mat-error *ngIf="isFieldInvalid('name')">
            Der skal indtastes navn
    </mat-error>

</mat-form-field>
<mat-form-field appearance="outline" class="full-width">
  <mat-label>Vælg enhed</mat-label>
  <mat-select formControlName="unit">
    <mat-option *ngFor="let u of productUnits" [value]="u.value">
      {{u.value | titlecase}}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="isFieldInvalid('unit')">
    Der skal vælge enhed
  </mat-error>
</mat-form-field>


<mat-slide-toggle formControlName="standard_product">Vælg for standard </mat-slide-toggle>
<p>
<mat-label>* Når du markerer produktet som standard,  tilføjes det automatisk på nye shopping lister ude hos dine kunder.</mat-label>
</p>
</mat-dialog-content>
  
  
  <mat-dialog-actions>
  
    
    <button class="mat-raised-button"
            (click)="close()">
        Fortryd
    </button>
    <span class="fill-space"></span>
    <button class="mat-raised-button mat-primary"
            (click)="save()" [disabled] ="form.invalid">
        Gem
    </button>
  </mat-dialog-actions>