import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { User } from '../shared/user';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { baseURL } from '../shared/baseurl';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Company } from '../shared/company';
import { CompanyService } from '../services/company.service';
import { ContactService } from './contact.service';



interface isLoggedIn{
  OK : boolean,
  id: string,
  role: string
}

@Injectable()
export class AuthService {
  loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  role = new BehaviorSubject<string>("")
  companyId = new BehaviorSubject<string>("")

  constructor(
    private router: Router,
    private http:HttpClient,
    private companyService: CompanyService,
    private contactService: ContactService
  ) {}

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  get userRole(){
    return this.role.asObservable();
  }

  /*set userRole(value: string){
    this.role.next(value);
  }*/



  login(user: User) : Observable<User>{
    /*let httpHeaders = new HttpHeaders()
    .set('Content-Type', 'application/json; charset=utf-8');*/
    let httpParams = new HttpParams()
                  .set("handle",user.handle)
                  .set("password", user.password)
                  .set("action", "search");
    //let options = new RequestOptions({ headers: httpHeaders,withCredentials:true});
    return this.http.get<User>('server/engros_authenticate.php', {params: httpParams});
  }

  signup(company: Company): Observable<Company>{
    let formData: FormData = new FormData()
    formData.append("name",company.name)
    formData.append("company_category_id", company.company_category_id)
    formData.append("cvr", company.cvr)
    formData.append("address",company.address)
    formData.append("zip", company.zip)
    formData.append("city", company.city)
    formData.append("country",company.country)
    formData.append("phone", company.phone.replace(/\s/g, ""))
    formData.append("web", company.web)
  //  formData.append("order_email", company.order_email.toLowerCase())
    //formData.append("order_sms", company.order_sms)
    formData.append("email", company.email.toLowerCase())
    formData.append("user_name",company.user_name)
    formData.append("notes", company.notes)
    formData.append("our_delivery_times",company.our_delivery_times)
    formData.append("our_office_opening_hours", company.our_office_opening_hours)
    formData.append("subscriber",company.subscriber)
    formData.append("action", "create_company");
    formData.append("locale", "da")
    return this.http.post<Company>('server/engros_authenticate.php', formData);
  }

 
  resetPassword(handle: string): Observable<User>{
    let httpParams = new HttpParams().set("handle",handle).set("action", "reset_password").set("locale", "da")
    return this.http.get<User>('server/engros_authenticate.php', {params: httpParams});

  }
  /*login(user: User) {
    console.log(user);
    if (user.email !== '' && user.password !== '' ) {
      this.loggedIn.next(true);
      this.router.navigate(['/']);
    }
  }*/

  isUserLoggedIn() : Observable<isLoggedIn>{
    return this.http.get<isLoggedIn>('server/engros_confirm_login.php?action=confirm_engros_login');
  }

  getPrivacy(locale: string) : Observable<User>{
    let httpParams = new HttpParams()
    .set("locale",locale)
    .set("action", "privacy");
    return this.http.get<User>('server/engros_authenticate.php', {params: httpParams});
  }

  logout() {
    return this.http.get('server/engros_authenticate.php?action=logout').subscribe(
      (data)=>{
        localStorage.removeItem('loggedIn');
        localStorage.removeItem('profile_id');
        localStorage.removeItem('id')
        this.loggedIn.next(false);
        this.router.navigate(['/login']);
        this.companyService.removeCompanyValue()
        this.contactService.removeContactValue()

      }
    );
   /* */
  }
}