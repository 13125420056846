import { Component, OnInit, Inject, OnDestroy } from '@angular/core'
import { Shop } from '../shared/shop'
import { ShopService } from '../services/shop.service'
import {MatDialog, MAT_DIALOG_DATA, MatCheckboxClickAction, MatCheckboxChange, MatDialogRef} from '@angular/material'
import { MatTableDataSource } from '@angular/material'
import{ SnackbarService } from '../services/snackbar.service'
import { TranslateService } from '@ngx-translate/core'
import { DialogService } from '../services/dialog.service'
import { Router } from '@angular/router'
import { CustomerAddComponent } from '../customer-add/customer-add.component'
import { Company } from '../shared/company'
import { CompanyService } from '../services/company.service'
import { ContactService} from '../services/contact.service'
import { Contact} from '../shared/contact'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit, OnDestroy {

  private subscription : Subscription
  isWait : Boolean = true
  allowCreateShop: Boolean = false
  shops: Shop[]
  dataSource: MatTableDataSource<any>
  company: Company
  company_id: string
  subscriber: boolean = false
  imageUrl: string = "/assets/imgs/phone.png"
  contacts: Contact[]
  selectedSaler : string
//'shop_shopping_list'
  displayedColumns: string[] = ['shop_name','shop_phone', 'saler','action'];
  
  constructor(
    private router: Router,
    private shopService: ShopService,
    private companyService: CompanyService,
    private contactService: ContactService,
    private translate: TranslateService,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.company_id = localStorage.getItem("profile_id")
    if (this.companyService.currentCompanyValue) { 
      this.subscription = this.companyService.currentCompany.subscribe((data) => {
        this.company = data
        if(this.company.allow_create_shop == "1")
          this.allowCreateShop = true
        })
      }else{
        this.subscription = this.companyService.getCompanyById(this.company_id).subscribe((data) => {
         this.company = data
         if(this.company.allow_create_shop == "1")
            this.allowCreateShop = true
        
        })
    }
    
    this.contactService.getContacts(this.company_id).subscribe(
      data => {
       this.contacts = data.contact_list
       this.shopService.getFavoriteShopList(this.company_id).subscribe(
        data => {
          this.shops = data.shops
          this.dataSource = new MatTableDataSource<Shop>(this.shops)
          this.isWait = false
        }
      )
      }
    )
    
  

      
    }
    ngOnDestroy(){
      this.subscription.unsubscribe()
    }



  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  showDetail(id:string){
    this.shopService.getShopDetailById(id).subscribe(
      data => {
        console.log(data)
        this.dialog.open(DialogShopContent, {
          width: '600px',
          disableClose: true ,
          data: data
        });
      }
    )
  }

  delete(shop: Shop){
    console.log(shop)
    this.dialogService.openConfirmDialog("Er du sikker på slette denne kunde?")
     .afterClosed().subscribe(res=>{
       if(res){
          this.shopService.deleteFavoriteShop(this.company_id, shop).subscribe((data)=>{
            if(data.OK == "YES"){
              this.shops.forEach( (item, index) => {
                if(item.id === shop.id) 
                  this.shops.splice(index,1);
                this.dataSource = new MatTableDataSource<Shop>(this.shops);
              });
            }
          })
        }
      })
  }

  changeSaler(shop : Shop){
    if(shop.saler_id != "0" && shop.contact_shop_id != "0"){
      console.log("update")
      //console.log(shop.saler_id)
      //console.log(contactId)
      // update the row
      //if(shop.saler_id != contactId){
        this.shopService.updateSalerShop(shop.contact_shop_id, shop.saler_id).subscribe((data) => {
          if(data.OK == "YES"){
            this.snackbarService.success(this.translate.instant("update_success"))
          }
        })
     // }
    }else{
      console.log("insert")
      //insert new row
      this.shopService.addSalerShop(shop.id, shop.saler_id).subscribe((data) => {
        if(data.OK == "YES"){
          shop.contact_shop_id = data.last_contact_shop_id
          this.snackbarService.success(this.translate.instant("add_success"))
        }
      })
    }
    
  }
  /*editSaler(shop:Shop){
    this.contactService.getContacts(this.company_id).subscribe(
      data => {
        console.log(data)
        this.dialog.open(DialogContactList, {
          width: '600px',
          disableClose: true ,
          data: {contacts: data.contact_list, shop: shop}
        });
      }
    )
  }*/

  createCustomer(){
   this.router.navigate(['customer'])
  }
 /* shoppingList(shop:Shop){
    this.router.navigate(['/shopping/'],{ queryParams: { name: shop.shop_name, id: shop.id} });
  }*/

}

@Component({
  selector: 'dialog-shop-content',
  templateUrl: 'dialog-shop-content.html',
})
export class DialogShopContent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: Shop) {}
  onPrint():void{
    let popupWin;
      //printContents = document.getElementById('print-section').innerHTML;
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
        <html>
          <head>
            <title></title>
            <style>
            </style>
          </head>
      <body onload="window.print();window.close()">
      <div>
          <b>${this.data.name}</b><br>
          CVR: ${this.data.cvr}<br>
          ${this.data.zip} ${this.data.city}<br>
          ${this.data.country}<br>
          Tlf. ${this.data.phone}<br>
          Email: ${this.data.email} <br>
          <br>

          <b>Kontakt person</b><br>
          Name: ${this.data.contact_name}<br>
          Mobile: ${this.data.contact_phone}<br>
          Email: ${this.data.contact_email}<br>

      </div>
      </body>
        </html>`
      );
      popupWin.document.close();
  }


}

@Component({
  selector: 'dialog-contact-list',
  templateUrl: 'dialog-contact-list.html',
  styleUrls: ['./customer.component.scss']
})
export class DialogContactList {
  
  favoriteContact: string
  contacts : Contact[]
 // displayedColumns: string[] = ['name','address','phone'];
 
 // public event: EventEmitter<any> = new EventEmitter();
  constructor(
    private dialog: MatDialog,
    private dialogService: DialogService,  
    private translate: TranslateService, 
    private dialogRef: MatDialogRef<DialogContactList>,
    @Inject(MAT_DIALOG_DATA) public data) {
      this.contacts = data.contacts
      this.favoriteContact = data.shop.saler
    }

    onSave(){
      console.log(this.favoriteContact)
      this.dialogRef.close();
    }

}
