<mat-dialog-content>
    <h4>Nogle butikker fundet ...!!</h4>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" >
  
                <ng-container matColumnDef="cvr">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> CVR </th>
                    <td mat-cell *matCellDef="let element" [style.color]="'navy'" (click)="showInfo(element)" [style.cursor] ="'pointer'"> {{element.cvr}} </td>
                </ng-container>
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Butiksnavn </th>
                  <td mat-cell  *matCellDef="let element" [style.color]="'navy'" (click)="showInfo(element)" [style.cursor] ="'pointer'"> {{element.name}} </td>
                </ng-container>
                
                <ng-container matColumnDef="address">
                        <th mat-header-cell *matHeaderCellDef> Adresse </th>
                        <td mat-cell  *matCellDef="let element" [style.color]="'navy'" (click)="showInfo(element)" [style.cursor] ="'pointer'"> 
                            {{element.address}}, <br/>
                            {{element.zip}} {{element.city}}
                        </td>
                      </ng-container>
                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef> Phone </th>
                    <td mat-cell  *matCellDef="let element"> {{element.phone}} </td>
                </ng-container>
               
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" mat-dialog-close>LUK</button>
</mat-dialog-actions>

