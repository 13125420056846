import { Injectable } from '@angular/core';
import {ShoppingList} from '../shared/shopping_list';
import { Observable, of} from 'rxjs';
import { delay } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Product } from '../shared/product';

interface responseData{
  OK: string,
  list: ShoppingList[],
  shopping_list_items: Product[],
  shop_name: string,
  shopping_list_name: string
}

@Injectable({
  providedIn: 'root'
})
export class ShoppingListService {

  constructor(private http: HttpClient) { }

  getShoppingListByShopId(company_id:string, shop_id:string): Observable<responseData>{
    return this.http.get<responseData>('server/engros_shopping_list.php?action=company_based_shopping_list&company_id=' + company_id + '&shop_id='+ shop_id);
  }

  addShoppingList(shoppingList:ShoppingList): Observable<ShoppingList>{
    let formData: FormData = new FormData()
    formData.append("name", shoppingList.shopping_list_name)
    formData.append("engros_company_customer_id", shoppingList.engros_company_customer_id)
    formData.append("shop_id", shoppingList.shop_id)
    formData.append("company_id", shoppingList.company_id)
    formData.append("action", "add_shopping_list")
    return this.http.post<ShoppingList>('server/engros_shopping_list.php', formData);
  }

  editShoppingList(shoppingList:ShoppingList): Observable<ShoppingList>{
    let formData: FormData = new FormData()
    formData.append("name", shoppingList.shopping_list_name)
    formData.append("engros_company_customer_id", shoppingList.engros_company_customer_id)
    formData.append("id", shoppingList.id)
    formData.append("action", "update_shopping_list")
    return this.http.post<ShoppingList>('server/engros_shopping_list.php', formData);
  }

  deleteShoppingList(id:string): Observable<ShoppingList>{
    let httpParams = new HttpParams().set("id", id);
    return this.http.get<ShoppingList>('server/engros_shopping_list.php?action=delete_shopping_list', {params: httpParams});
  }

  getShoppingListItem(shopping_list_id: string): Observable<responseData>{
    return this.http.get<responseData>('server/engros_shopping_list.php?action=list_shopping_list_items&shopping_list_id=' + shopping_list_id)
  }

  addShoppingListItem(product:Product): Observable<Product>{
    let formData: FormData = new FormData()
    formData.append("product_name", product.name)
    formData.append("unit", product.unit)
    formData.append("product_number", product.product_number)
    formData.append("shopping_list_id", product.shopping_list_id)
    formData.append("action", "add_shopping_list_item")
    return this.http.post<Product>('server/engros_shopping_list.php', formData);
  }

  editShoppingListItem(product:Product): Observable<Product>{
    let formData: FormData = new FormData()
    formData.append("product_name", product.name)
    formData.append("unit", product.unit)
    formData.append("product_number", product.product_number)
    formData.append("id", product.id)
    formData.append("action", "update_shopping_list_item")
    return this.http.post<Product>('server/engros_shopping_list.php', formData);
  }

  deleteShoppingListItem(id:string): Observable<Product>{
    let httpParams = new HttpParams().set("id", id);
    return this.http.get<Product>('server/engros_shopping_list.php?action=delete_shopping_list_item', {params: httpParams});
  }
}
