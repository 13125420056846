<div fxLayout="column wrap" fxFlex="80" fxFlexOffset="8" fxFlex.xs="auto" fxFlexOffset.xs="1">
 
  <h3>Kunder </h3>
  <div fxLayout="column wrap" fxLayoutAlign="start start" class="card-container" *ngIf="allowCreateShop">
    <button button ="submit" mat-mini-fab color="primary"  (click) = "createCustomer()">
      <mat-icon aria-label="Offer icon-button with a add icon">add</mat-icon>
    </button>
  </div>
  
  
  
  <div class="example-container">
      
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Søg efter listen">
      </mat-form-field>
      <mat-progress-bar mode="indeterminate" *ngIf="isWait"></mat-progress-bar>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" >

          <ng-container matColumnDef="shop_name">
            <th mat-header-cell *matHeaderCellDef> Navn </th>
            <td mat-cell *matCellDef="let element" [style.color]="'navy'" (click)="showDetail(element.id)" [style.cursor] ="'pointer'"> {{element.shop_name}} </td>
          </ng-container>
          <ng-container matColumnDef="shop_phone">
            <th mat-header-cell *matHeaderCellDef> Telefon </th>
            <td mat-cell *matCellDef="let element">
              <a [href]="'tel:+'+element.shop_phone"><img fxHide fxShow.xs="true" class="call-thumbnail" mat-card-avatar [src]="imageUrl"><div fxHide.xs="true">+{{element.shop_phone}}</div></a></td>
          </ng-container>
          <ng-container matColumnDef="saler">
              <th mat-header-cell *matHeaderCellDef> Sægler</th>
              <td mat-cell *matCellDef="let element"> 
                 <!--<mat-form-field class="no-line">-->
                      <mat-select [(ngModel)]="element.saler_id" (selectionChange)="changeSaler(element)">
                          <mat-option *ngFor="let contact of contacts" [value]="contact.id">
                              {{contact.name }}
                            </mat-option>
                      </mat-select>
                    <!--</mat-form-field>-->
              </td>
            </ng-container>
          <!--<ng-container matColumnDef="shop_shopping_list">
            <th mat-header-cell *matHeaderCellDef> Indkøbslist </th>
            <td mat-cell *matCellDef="let element" [routerLink]="['/shopping', element.shop_name, element.id]"> 
              <button mat-icon-button class="mat-primary" >
                  <mat-icon>shopping_cart</mat-icon>
                </button>
          </td>
          </ng-container>-->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button class="mat-primary" [routerLink]="['/shopping', element.shop_name, element.id]">
                  <mat-icon>shopping_cart</mat-icon>
                </button>
               <!-- <button mat-icon-button class="mat-primary" (click)="editSaler(element)">
                    <mat-icon>link</mat-icon>
                  </button> -->
              <button mat-icon-button class="mat-warn" (click)="delete(element)">
                  <mat-icon>delete</mat-icon>
                </button>
          </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
  </div>
</div>
