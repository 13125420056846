<div class="container" fxLayout="column wrap" fxLayoutGap="32px" fxFlex="60" fxFlexOffset="17" fxFlex.xs="auto" fxFlexOffset.xs="3" >
    <h3>Ratings</h3>
    <mat-card  *ngFor="let r of ratings">
        <div>
            <h4>{{ r.review }}</h4>
            <div>{{ r.stars }} Stars</div>
            <div>{{r.app_user_name}}  -  {{ r.create_date | date }} </div>
            
        </div>
        <br>
        <mat-divider></mat-divider>
        <br>
        <div>
        <mat-form-field appearance="outline" class="full-width">
                <mat-label>Reply to User</mat-label>
                <input matInput placeholder="Enter your reply here" [(ngModel)]="r.reply">
        </mat-form-field>
        <mat-card-actions>
                <button mat-raised-button color="primary" (click)="sendReply(r)" class="float-right">Reply</button>
        </mat-card-actions>
                    
            <!--<div *ngIf=r.reply><mat-card >
                <h4>{{r.reply}}</h4>
                </mat-card>
            </div>
        
            <div fxFlex [hidden]="r.reply" >
                <button mat-raised-button color="primary" (click)="sendReply(r)">Reply</button>
            </div> -->
                <!-- <mat-card> 
                    <mat-form-field class="full-width">
                        <input matInput placeholder="Reply to User">
                    </mat-form-field>
                    
                </mat-card>-->

            
        </div>
    </mat-card>
</div>