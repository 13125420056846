<mat-dialog-content [formGroup]="form" class="example-form">
        <div class="image-box">
            <input style="display: none" [disabled]="!imageClick" type="file" (change)="onFileChanged($event.target.files)"
                #fileInput accept="image/*">
                <button class="mat-raised-button mat-warn" style="display: flex;" *ngIf="!imageClick" (click) = "deleteImage()">Fjern billede</button>
                <!--<mat-icon style="margin-left:auto; display: flex;" *ngIf="!imageClick" (click) = "deleteImage()">close</mat-icon>-->
            <mat-card>
                <img  mat-card-image  [src]="imageUrl"  (click)="fileInput.click()">
            </mat-card>
        </div>
       

    <mat-form-field appearance="outline" class="full-width">
        <mat-label>Titel</mat-label>
        <input matInput formControlName="title" required>
        <mat-error *ngIf="isFieldInvalid('title')">
            Der skal indtastes titel
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="full-width" >
        <mat-label>Indhold</mat-label>
        <textarea matInput formControlName="body" matTextareaAutosize required></textarea>
        <mat-error *ngIf="isFieldInvalid('body')">
            Der skal indtastes indhold
        </mat-error>
    </mat-form-field>

   <!-- <mat-form-field>

        <mat-select placeholder="Select status"
            formControlName="status">

            <mat-option value="draft">
                Draft</mat-option>
            <mat-option value="published">
                Published</mat-option>
            <mat-option value="deleted">
                Deleted</mat-option>

        </mat-select>

    </mat-form-field>

    <mat-form-field>

            <mat-select placeholder="Select economy"
            formControlName="economy">

            <mat-option value="free">
                Free</mat-option>
            <mat-option value="invoice">
                Invoice</mat-option>
            <mat-option value="paid">
                Paid</mat-option>

        </mat-select>


    </mat-form-field>-->

</mat-dialog-content>

<mat-dialog-actions>

    <button class="mat-raised-button"
            (click)="close()">
        Fortryd
    </button>

   
    <span class="fill-space"></span>
    <button class="mat-raised-button mat-primary" *ngIf="status"
    (click)="changeStatus()" [disabled] ="form.invalid">
    {{status | titlecase}}
</button>
<button class="mat-raised-button mat-primary"
(click)="save()" [disabled]="form.invalid">
Gem
</button>

</mat-dialog-actions>