import { Component, OnInit, OnDestroy } from '@angular/core';
import {CompanyService } from '../services/company.service'
import { AuthService } from '../services/auth.service';
import { Company } from '../shared/company';
import { SnackbarService } from '../services/snackbar.service';
import { BusinessCategory } from '../shared/businessCategory';
import {BusinessCategoryService} from '../services/business-category.service'
import { MatSlideToggleChange } from '@angular/material';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-profiledetail',
  templateUrl: './profiledetail.component.html',
  styleUrls: ['./profiledetail.component.scss']
})
export class ProfiledetailComponent implements OnInit, OnDestroy {
  

  private subscription: Subscription
  company_id: string
  id = localStorage.getItem("profile_id")
  company : any
  category: BusinessCategory;
  imageUrl: string = "/assets/imgs/defaultImg.jpg"
  fileToUpload: File = null;
  imageClick : boolean = true;
  smsOnly : boolean = false;


  constructor(
    private companyService: CompanyService,
    private snackbarService: SnackbarService) { }

  ngOnInit() {
   // this.authService.companyId.subscribe((data) => this.company_id = data)

   // redirect to home if already logged in
   //console.log(this.companyService.currentCompanyValue)
    if (this.companyService.currentCompanyValue) { 
    this.subscription = this.companyService.currentCompany.subscribe((data) => {
      this.company = data
      if(this.company.send_sms_only == "1")
        this.smsOnly = true

    if(this.company.logo_path !== ""){
      this.imageUrl = this.company.logo_path
      this.imageClick = false;
    }
      console.log(data)})
    }else{
      this.subscription = this.companyService.getCompanyById(this.id).subscribe((data) => {
       this.company = data
       if(this.company.send_sms_only == "1")
        this.smsOnly = true

    if(this.company.logo_path !== ""){
      this.imageUrl = this.company.logo_path
      this.imageClick = false;
    }
       console.log(data)
      })
    }
    
    /*this.companyService.getCompanyById(this.id).subscribe((data) => {
      this.companyService.changeData(data)
     this.company = data
     console.log(data)
    })*/
   /* this.companyService.getCompanyById(this.company_id).subscribe(
      company => {
        this.companyService.changeData(company)
        this.companyService.dataWasLoaded.next(true)
        this.company = company;
        if(this.company.send_sms_only == "1")
          this.smsOnly = true;
        
        /*this.businessCategoryService.getBusinessCategoryById(company.company_category_id).subscribe(
          data =>{
            this.category = data;
          }
        );*/
       /* if(this.company.logo_path !== ""){
          this.imageUrl = this.company.logo_path
          this.imageClick = false;
         }
      }
    )*/
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


  updateCompany(){
    console.log(this.smsOnly)
    if(this.smsOnly == true)
      this.company.send_sms_only = "1"
    else
     this.company.send_sms_only = "0"
    this.companyService.updateCompany(this.company).subscribe(
      data => {
        if(data.OK == "YES"){
          this.snackbarService.success('Updated Successfully!')
        }
      }
    )
  }

  onFileChanged(file: FileList) {
    //this.selectedFile = event.target.files[0]
    if(file){
      this.fileToUpload = file.item(0)
      //console.log(this.selectedFile)
      //show image preview
      var reader = new FileReader();
      reader.onload = (event:any) =>{
        this.imageUrl = event.target.result
      }
      reader.readAsDataURL(this.fileToUpload)
      this.companyService.updateLogoImage(this.company.id.toString(), this.fileToUpload).subscribe(
        (data) => {
          //console.log(data)
          if(data.OK == "YES"){
            this.imageClick = false;
            //this.company.logo_path = "https://likesharing.dk/database/engros/company_logo/" + this.company.id + ".jpg"
            //this.event.emit({data: this.form.value});
          }
        }
      )

      console.log(this.fileToUpload)
    }
  }


}
