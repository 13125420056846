import { Component, OnInit } from '@angular/core';
import {NotificationService } from '../services/notification.service'
import { Notification } from '../shared/notification';
import {NotificationAddComponent} from '../notification-add/notification-add.component';
import {MatDialog} from '@angular/material'
import { NotificationDetailComponent } from '../notification-detail/notification-detail.component';
import { SnackbarService } from '../services/snackbar.service';
import { DialogService } from '../services/dialog.service'
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource } from '@angular/material';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  isWait : Boolean = true
  notifications: Notification[];
  id: string;
  notification = {
    title : "Ny notifikation ... ",
    body: "",
    status : "draft",
    company_id : localStorage.getItem("profile_id")
  };

  dataSource;
  displayedColumns: string[] = ['title', 'status', 'create_date', 'view_response'];
  
  constructor(
    private notificationService: NotificationService, 
    private snackbarService: SnackbarService,
    private dialogService: DialogService,
    private translate: TranslateService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.id = localStorage.getItem("profile_id")
    this.notificationService.getNotificationByProfileId(this.id).subscribe(
      (data) => {
        this.notifications = data.notifications.filter((notification: Notification) => notification.status != "deleted")
        this.dataSource = new MatTableDataSource<Notification>(this.notifications)
        this.isWait = false
    }
    )
  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
  createNotification(): void{
    this.notificationService.createNotification(this.notification).subscribe(
      (data) => {
        //if(data.OK == "YES"){
          this.notificationService.getNotificationByProfileId(this.id).subscribe(
            (data) => {
              this.notifications = data.notifications.filter((notification: Notification) => notification.status != "deleted")
              this.dataSource = new MatTableDataSource<Notification>(this.notifications)
              this.snackbarService.success(this.translate.instant("add_success"))
            })
        }
      //}
      )
    
  }

  /*openCreateDialog(): void{
    let dialogRef = this.dialog.open(NotificationAddComponent, {
      width: '600px',
      data: 'Add News',
      disableClose: true 
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      console.log(result.data)
      this.notificationService.createNotification(result.data).subscribe(
      (data) => {
        if(data.OK == "YES"){
          this.notificationService.getNotificationByProfileId(this.id).subscribe(
          (data) => this.notifications = data.notifications)
        }
      })
    });
  }*/

  /*
    //function with param item object
  editNotification(notification:Notification){
    let dialogRef = this.dialog.open(NotificationDetailComponent, {
      width: '800px',
      disableClose: true ,
      data: notification
    });
  dialogRef.componentInstance.event.subscribe((result) => {
    console.log(result.data)
    this.notificationService.updateNotification(result.data).subscribe(
    (data) => {
      
      if(data.OK == "YES"){
        if(result.data.status == "published"){
          console.log(result.data.status)
          this.notificationService.sendNotification(result.data).subscribe()
        }

        this.notificationService.getNotificationByProfileId(this.id).subscribe(
          (data) => {
            this.notifications = data.notifications.filter((notification: Notification) => notification.status != "deleted")
            this.dataSource = new MatTableDataSource<Notification>(this.notifications)
            this.snackbarService.success(this.translate.instant("update_success"));
          })
      }
    })
  });
  }
   */

  //function with param item object
  editNotification(notification:Notification){
    let dialogRef = this.dialog.open(NotificationDetailComponent, {
      width: '800px',
      disableClose: true ,
      data: notification
    });

    /*dialogRef.afterClosed().subscribe(
      val => console.log("Dialog output:", val)
  );*/
  dialogRef.componentInstance.event.subscribe((result) => {
    console.log(result.data)

    if(result.data.status == "published" && result.data.target_group == "selected"){
      this.notificationService.publishNotification(result.data).subscribe(
        data => {
        // if(data.OK == "YES"){
          this.notificationService.getNotificationByProfileId(this.id).subscribe(
            (data) => {
              this.notifications = data.notifications.filter((notification: Notification) => notification.status != "deleted")
              this.dataSource = new MatTableDataSource<Notification>(this.notifications)
              this.snackbarService.success(this.translate.instant("update_success"));
            })
          }
        //}
        )
    }else{
      this.notificationService.updateNotification(result.data).subscribe(
        (data) => {
          if(data.OK == "YES"){
            this.notificationService.getNotificationByProfileId(this.id).subscribe(
              (data) => {
                this.notifications = data.notifications.filter((notification: Notification) => notification.status != "deleted")
                this.dataSource = new MatTableDataSource<Notification>(this.notifications)
                this.snackbarService.success(this.translate.instant("update_success"));
              })
            }
        })
    }
  });
  }

  delete(notification:Notification): void{
    event.stopPropagation()
   
    this.dialogService.openConfirmDialog(this.translate.instant("phrases.sure_delete_notification"))
     .afterClosed().subscribe(res=>{
       if(res){
        this.notificationService.updateStatusNotification(notification).subscribe(
          data => {
            this.notifications.splice(this.notifications.indexOf(notification), 1);
            this.dataSource = new MatTableDataSource<Notification>(this.notifications)
            this.snackbarService.success(this.translate.instant("delete_success"));
            }
          )
        
       }
     })
  
  
  /*delete(notification:Notification){
    event.stopPropagation()
    this.notificationService.deleteNotification(notification.id.toString()).subscribe(
      (data) => {
        if(data.OK == "YES"){
          this.notifications.splice(this.notifications.indexOf(notification), 1);
        }
      }
    )
    
    //console.log(notification);
    }*/

  }
}
