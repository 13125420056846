import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../services/auth.service';
import { BusinessCategoryService} from'../services/business-category.service';
import { Router } from '@angular/router';
import {Company} from '../shared/company';
import {BusinessCategory} from '../shared/businessCategory';
import{ SnackbarService } from '../services/snackbar.service';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  browserLang: string
  company: Company;
  companyCategory: BusinessCategory;
  companyCategories: BusinessCategory[];
  companyForm: FormGroup;
  private formSubmitAttempt: boolean
  submittingForm  : boolean=false
  submitted: boolean=false
  initForm: boolean = true

  formErrors = {
    'name': '',
    'cvr' : '',
    'address' : '',
    'zip' : '',
    'city': '',
    'country': '',
    'phone': '',
    'email':'',
    'contact_name':''
  };

  validationMessages = {
    'name':{
      'required' : 'Der skal indtastes navn',
      'minlength' : 'Navnet skal være mindst 4 tegn'
    },
    'cvr':{
      'required' : 'Der skal indtastes cvr.'
    },
    'phone':{
      'required' : 'Der skal indtastes phonenummer',
      'pattern' : 'Telefonnummeret skal kun indeholde tal',
      'minlength' : 'Telefonnummeret skal være mindst 8 tegn'
    },
    'address':{
      'required' : 'Der skal indtastes adresse'
    },
    'city':{
      'required' : 'Der skal indtastes by'
    },
    'zip':{
      'required' : 'Der skal indtastes postalnummer',
      'pattern' : 'Postalnummer skal kun indeholde tal.'
    },
    'country':{
      'required' : 'Der skal indtastes land'
    },
    'user_name':{
      'required' : 'Der skal indtastes kontaktperson navn'
    },
    'email':{
      'required' : 'Der skal indtastes email',
      'email': 'Email skal være gyldigt emailformat'
    }
  };

  constructor(
    private formBuilder: FormBuilder,
    private router: Router, 
    private authService: AuthService,
    private businessCategoryService: BusinessCategoryService,
    private snackbarService: SnackbarService,
    public dialog: MatDialog,
    translate: TranslateService) { 
      //this.createForm();
    this.browserLang = translate.getBrowserLang();
    console.log(this.browserLang)
    }
    ngOnInit() {
      this.businessCategoryService.getBusinessCategory().subscribe(
        data => this.companyCategories = data.categories
      )
      this.createForm();
      
      
    }
  
    createForm(){
      this.companyForm = this.formBuilder.group({
        company_category_id: 0, 
        name: ['', [Validators.required, Validators.minLength(4)]],
        cvr: ['', Validators.required],
        address: ['', Validators.required],
        zip: ['', [Validators.required, Validators.pattern]],
        city: ['', Validators.required],
        country: ['Danmark', Validators.required],
        phone: ['', [Validators.required, Validators.pattern, Validators.minLength(8)]],
        web:['https://'],
        //order_email:[''],
        //order_sms:['0'],
        email: ['', [Validators.required, Validators.email]],
        user_name: ['', Validators.required],
        our_delivery_times: '',
        our_office_opening_hours: '',
        notes: [''],
        subscriber: false,
        agree: [false, Validators.required]
      });

      this.companyForm.valueChanges
      .subscribe(data => this.onValueChanged(data));
    
    this.onValueChanged(); //reset form validation messages
    }
  
    onValueChanged(data?: any){
      if(!this.companyForm){
        return;
      }
      const form = this.companyForm;
      for(const field in this.formErrors){
        if(this.formErrors.hasOwnProperty(field)){
          //clear previous eroor message(if any)
          this.formErrors[field] = '';
          const control = form.get(field);
          if(control && control.dirty && !control.valid){
            const messages = this.validationMessages[field];
            for(const key in control.errors){
              if(control.errors.hasOwnProperty(key)){
                this.formErrors[field] += messages[key] + ' ';
              }
            }
          }
        }
      }
  
    }
  
    showPrivacy(){
      this.authService.getPrivacy(this.browserLang).subscribe(
        data => {
          console.log(data)
          this.dialog.open(DialogSignupPrivacy, {
            width: '600px',
            disableClose: true ,
            data: data.privacy
          });
        }
      )
    }
  
    onSubmit(){
      this.formSubmitAttempt = true;
    this.initForm = false
    this.submittingForm = true
   // if (this.companyForm.valid) {
      console.log(this.company);
      this.authService.signup(this.companyForm.value)
      .subscribe(
        (data) => {
          console.log(data);
          if(data.OK == "YES"){
            this.submitted = true
            this.submittingForm = false
            //this.snackbarService.success("Success..!!")
            //this.router.navigate(['/login']);
          }else{
            this.submittingForm = false
            this.initForm = true
            this.snackbarService.warn(data.OK)
          }
        }, err => {
	      console.log(err);
        }
      )
     // }
      this.companyForm.reset 
    }

    close(){
      this.router.navigate(['/login']);
    }
  
}

@Component({
  selector: 'dialog-signup-privacy',
  templateUrl: 'dialog-signup-privacy.html',
})
export class DialogSignupPrivacy {
  constructor(@Inject(MAT_DIALOG_DATA) public data: string) {}
}
