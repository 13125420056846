import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from "@angular/material";
import {Notification} from "../shared/notification";
import {FormBuilder, Validators, FormGroup} from "@angular/forms";
import { NotificationService } from '../services/notification.service'
import { CompanyService } from '../services/company.service'
import {DialogService} from '../services/dialog.service'
import { TranslateService } from '@ngx-translate/core';
import { NotificationPublishComponent } from '../notification-publish/notification-publish.component';

@Component({
  selector: 'app-notification-detail',
  templateUrl: './notification-detail.component.html',
  styleUrls: ['./notification-detail.component.scss']
})
export class NotificationDetailComponent implements OnInit {

  form: FormGroup;
  selectedFile: File
  imageUrl: string = "/assets/imgs/defaultImg.jpg"
  fileToUpload: File = null;
  notificationId: string;
  status: string;
  company_id: number;
  subscriber: boolean = false;
  imageClick : boolean = true;

  public event: EventEmitter<any> = new EventEmitter();

  constructor(
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private companyService: CompanyService,
    private dialogService: DialogService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<NotificationDetailComponent>,
    @Inject(MAT_DIALOG_DATA) notification:Notification ) {

    this.company_id = notification.company_id;
    this.notificationId = notification.id.toString()

    if(notification.jpg_path !== ""){
     this.imageUrl = notification.jpg_path
     this.imageClick = false;
    }
    if(notification.status == "draft"){
      this.status = "publish"
    }else{
      this.status = "draft"
    }

    this.form = this.fb.group({
        id: notification.id,
        company_id: notification.company_id,
        title: [notification.title, Validators.required],
        body: [notification.body, Validators.required],
        status: notification.status
    });

}

isFieldInvalid(field: string) {
  return (
    (!this.form.get(field).valid && this.form.get(field).touched)
  );
}

onFileChanged(file: FileList) {
  //this.selectedFile = event.target.files[0]
  if(file){
    this.fileToUpload = file.item(0)
    //console.log(this.selectedFile)
    //show image preview
    var reader = new FileReader();
    reader.onload = (event:any) =>{
      this.imageUrl = event.target.result
    }
    reader.readAsDataURL(this.fileToUpload)
    this.notificationService.updateNotificationImage(this.notificationId, this.fileToUpload).subscribe(
      (data) => {
        //console.log(data)
        if(data.OK == "YES"){
          this.imageClick = false;
          this.form.value.jpg_path = "https://shopengros.com/database/engros/notification/jpg/" + this.notificationId + ".jpg"
          this.event.emit({data: this.form.value});
        }
      }
    )

    console.log(this.fileToUpload)
  }
}

ngOnInit() {
  this.companyService.getCompanyById(localStorage.getItem("profile_id")).subscribe(
   data => {
    if(data.subscriber == "1"){
      this.subscriber = true;
    }
   }
  )
}

  save() {
    //console.log(this.form.value)  
    this.event.emit({data: this.form.value});
    this.dialogRef.close();  
  }
  
  changeStatus(){
    if(this.status == "publish"){
    
      let dialogRef = this.dialog.open(NotificationPublishComponent, {
        width: '600px',
        disableClose: true
      });
    dialogRef.componentInstance.event.subscribe((result) => {
     // console.log(result.data)
      this.form.value.status = "published"
      this.form.value.target_group = result.data.target_group
      this.form.value.selected_shops = result.data.selected_shops
     
     // console.log(this.form.value)
      this.event.emit({data: this.form.value});
      this.dialogRef.close();
    });
  }else{
    this.dialogService.openConfirmDialog(this.translate.instant("phrases.sure_change_status") + " " + this.translate.instant(this.status) + " " + this.translate.instant("denne")  + " " + this.translate.instant("notification"))
    .afterClosed().subscribe(res=>{
      if(res){
          this.form.value.status = this.status
          this.event.emit({data: this.form.value});
          this.dialogRef.close();
              
        //console.log(this.form.value) 
      }
    })
  }
  }

  /*changeStatus(){
    //if status is publish, then check for subscriber and if not subscribe remind them to subscribe or invoice.

    if(this.status == "publish"){
          if(this.subscriber){
            this.form.value.status = "published"
            this.form.value.economy = "free"
            this.event.emit({data: this.form.value});
            this.dialogRef.close();
          }else{
            this.dialogService.openConfirmDialog("Bemæk at denne service er ikke gratis!")
            .afterClosed().subscribe(res=>{
              if(res){
                this.form.value.status = "published"
                this.form.value.economy = "invoice"
                this.event.emit({data: this.form.value});
                this.dialogRef.close();
              }
            })
          }
    }else{
      this.form.value.status = this.status
      this.event.emit({data: this.form.value});
      this.dialogRef.close();
    }
    //
    this.dialogService.openConfirmDialog(this.translate.instant("phrases.sure_change_status") + " " + this.translate.instant(this.status) + " " + this.translate.instant("denne")  + " " + this.translate.instant("notification"))
    .afterClosed().subscribe(res=>{
      if(res){
          if(this.status == "publish"){
            this.form.value.status = "published"
            this.event.emit({data: this.form.value});
            this.dialogRef.close();
          }else{
          this.form.value.status = this.status
          this.event.emit({data: this.form.value});
          this.dialogRef.close();
          }
          
          console.log(this.form.value) 
      }
    })
  }*/
  
  deleteImage(){
    this.dialogService.openConfirmDialog(this.translate.instant("phrases.sure_delete_image"))
    .afterClosed().subscribe(res=>{
      if(res){
        this.imageClick = true;
        this.notificationService.deleteImage(this.notificationId).subscribe(
          data => {
            this.form.value.jpg_path = ""
            this.event.emit({data: this.form.value});
            this.imageUrl = "/assets/imgs/defaultImg.jpg"
          })
      }
  })

  }

  close() {
      this.dialogRef.close();
  }

}
