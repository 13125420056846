import { Injectable } from '@angular/core';
import {Message} from '../shared/message'
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

interface responseData{
  OK: string,
  list: Message[]
}

interface responseOK{
  OK: string
}

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private http: HttpClient) { }

  getMessageByCompanyId(id: string): Observable<responseData>{
    let httpParams = new HttpParams()
                  .set("company_id",id);
    return this.http.get<responseData>('server/engros_message.php?action=company_based_list_inbox', {params: httpParams});
  }


  getMessageByUser(company_id: string, shop_id: number) : Observable<responseData>{
    let httpParams = new HttpParams()
                  .set("company_id", company_id)
                  .set("shop_id", shop_id.toString());
    return this.http.get<responseData>('server/engros_message.php?action=company_based_list_inbox_details', {params: httpParams});
  }

  createMessage(message: Message): Observable<responseOK>{
    let formData: FormData = new FormData()
                  formData.append("company_id", message.company_id.toString())
                  formData.append("shop_id", message.shop_id.toString())
                  formData.append("body", message.body)
                  formData.append("action", "create");
    return this.http.post<responseOK>('server/engros_message.php', formData);
  }

  updateStatus(message: Message) : Observable<responseOK>{
    let formData: FormData = new FormData()
    formData.append("status", "Read")
    formData.append("id", message.id.toString())
    formData.append("action", "message_status")
    return this.http.post<responseOK>('server/engros_message.php', formData);
  }

}
