import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Notification } from '../shared/notification';
import { map, catchError } from 'rxjs/operators';

interface responseData{
  OK: string,
  notifications: Notification[]
}

interface responseOK{
  OK: {}
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http : HttpClient) { }


  getNotificationByProfileId(id: string): Observable<responseData>{
    let httpParams = new HttpParams()
                  .set("company_id",id);
    return this.http.get<responseData>('server/engros_notification.php?action=company_based_list', {params: httpParams});
  }

  getNotifications(): Observable<responseData>{
    return this.http.get<responseData>('server/engros_notification.php?action=list');
  }
  getNotification(id:any): Observable<any>{
    //return of(DISHES.map(dish => dish.id));
    return this.getNotifications().pipe(map(data => data.notifications.filter((notification: Notification) => notification.id == id)[0]))
      .pipe(catchError(error => error))
  }

  readNotification(id:string): Observable<Notification>{
    //return of(DISHES.map(dish => dish.id));
    let formData: FormData = new FormData()
    formData.append("id",id)
    formData.append("action", "read")
    return this.http.post<Notification>('server/engros_notification.php', formData);
  }

  createNotification(notification): Observable<Notification>{
    let formData: FormData = new FormData()
    formData.append("title",notification.title)
    formData.append("body", notification.body)
    formData.append("status", notification.status)
    formData.append("company_id", notification.company_id.toString())
    formData.append("action", "create")
    return this.http.post<Notification>('server/engros_notification.php', formData);
  }

  updateNotification(notification: Notification) : Observable<responseOK>{
    let formData: FormData = new FormData()
    formData.append("id", notification.id.toString())
    formData.append("title",notification.title)
    formData.append("body", notification.body)
    formData.append("status", notification.status)
    formData.append("company_id", notification.company_id.toString())
    formData.append("action", "update")
    return this.http.post<responseOK>('server/engros_notification.php', formData);
  }

  publishNotification(notification: Notification) : Observable<responseOK>{
    let formData: FormData = new FormData()
    formData.append("id", notification.id.toString())
    formData.append("title",notification.title)
    formData.append("body", notification.body)
    formData.append("status", notification.status)
    formData.append("company_id", notification.company_id.toString())
    formData.append("target_group", notification.target_group)
    formData.append("selected_shops", notification.selected_shops)
    formData.append("action", "publish")
    return this.http.post<responseOK>('server/engros_notification.php', formData);
  }

  updateStatusNotification(notification: Notification) : Observable<Notification>{
    let formData: FormData = new FormData()
    formData.append("id", notification.id.toString())
    formData.append("title",notification.title)
    formData.append("body", notification.body)
    formData.append("status", "deleted")
    formData.append("company_id", notification.company_id.toString())
    formData.append("action", "update")
    return this.http.post<Notification>('server/engros_notification.php', formData);
  }

  sendNotification(notification: Notification) : Observable<Notification>{
    let formData: FormData = new FormData()
    formData.append("id", notification.id.toString())
    formData.append("action", "send")
    return this.http.post<Notification>('server/engros_notification.php', formData)
  }

  deleteNotification(id:string): Observable<Notification>{
    let httpParams = new HttpParams().set("id", id);
    return this.http.get<Notification>('server/engros_notification.php?action=delete', {params: httpParams});
  }

  updateNotificationImage(id: string, fileToUpload: File):  Observable<responseOK>{
    const formData: FormData = new FormData();
    formData.append('userfile', fileToUpload);
    formData.append('id', id);
    return this.http.post<responseOK>('server/engros_notification.php?action=save_jpg', formData)
  }

  deleteImage(id:string){
    let httpParams = new HttpParams().set("id", id);
    return this.http.get('server/engros_notification.php?action=delete_jpg', {params: httpParams});
  }

}
