<div fxFlex fxFlexOffset="20px" class="form-size">
    <h4>Create Company</h4>
    <form novalidate [formGroup]="companyForm" (ngSubmit)="onSubmit()">
        <mat-card>
            <mat-card-content class="container">
                <!-- fxLayout="row" fxLayoutGap="20px" -->
                <div>
                  <div fxLayout="column">
                    <div fxLayout="column" fxLayoutAlign="center" class="row">
                      <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center" class="row-example">
                        <mat-form-field>
                          <input matInput placeholder="Company Name">
                          <!--<mat-icon matSuffix>face</mat-icon>-->
                        </mat-form-field>
                        <mat-form-field>
                          <input matInput placeholder="CVR">
                          <!--<mat-icon matSuffix>face</mat-icon>-->
                        </mat-form-field>
                      </div>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center stretch" class="row">
                      <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start center" class="row-example">
                        <mat-form-field>
                          <input matInput placeholder="Address">
                          <!--<<mat-icon matSuffix>email</mat-icon>-->
                        </mat-form-field>
                        <mat-form-field>
                          <input matInput placeholder="Postal">
                          <!--<<mat-icon matSuffix>work</mat-icon>-->
                        </mat-form-field>
                      </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="40px" fxLayoutAlign="center stretch" class="row">
                      <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start" class="row-example">
                        <mat-form-field>
                          <input matInput placeholder="City">
                          <!--<<mat-icon matSuffix>history</mat-icon>-->
                        </mat-form-field>
                        <mat-form-field>
                          <input matInput placeholder="Country">
                          <!--<<mat-icon matSuffix>web</mat-icon>-->
                        </mat-form-field>
                      </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="40px" fxLayoutAlign="center stretch" class="row">
                        <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="start" class="row-example">
                          <mat-form-field>
                            <input matInput placeholder="Email">
                            <!--<<mat-icon matSuffix>history</mat-icon>-->
                          </mat-form-field>
                          <mat-form-field>
                            <input matInput placeholder="Telephone">
                            <!--<<mat-icon matSuffix>web</mat-icon>-->
                          </mat-form-field>
                        </div>
                    </div>
                   
                  
                    <div fxLayout="column" fxLayoutAlign="center stretch" class="row">
                        <div fxLayout="row" fxLayoutGap="40px">
                            <h3 class="subheading-2" >Contact Person Info</h3>
                        </div>
                        <div fxLayout="column" fxLayoutAlign="start" class="row">
                            <div fxLayout="row" fxLayoutGap="20px">
                                <div fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="start">
                                    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
                                      <mat-form-field style="max-width: 250px;">
                                          <input matInput placeholder="Name">
                                      </mat-form-field>                    
                                    </div>
                                  </div>
                              <div fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="start">
                                <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
                                  <mat-form-field style="max-width: 250px;">
                                      <input matInput placeholder="Email">
                                  </mat-form-field>                    
                                </div>
                              </div>
                              <div fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="start">
                                  <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
                                    <mat-form-field style="max-width: 250px;">
                                        <input matInput placeholder="Telephone">
                                    </mat-form-field>                    
                                  </div>
                              </div>
                            </div>
                          </div>            
                      </div>
        
                    <div fxLayout="column" fxLayoutAlign="start" class="row">
                      <h3 class="subheading-2">Opening Hours</h3>
                          <div fxLayout="row" fxLayoutGap="50px">
                              <div fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="start">
                                  <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
                                    <mat-form-field style="max-width: 250px;">
                                        <input matInput placeholder="Opening Hours">
                                    </mat-form-field>                    
                                  </div>
                                </div>
                            <div fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="start">
                              <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
                                <mat-form-field style="max-width: 250px;">
                                    <input matInput placeholder="Delivery Time">
                                </mat-form-field>                    
                              </div>
                            </div>
                          </div> 
                    </div>
                    <div fxLayout="column" fxLayoutAlign="start" class="row">
                        <mat-slide-toggle><h3 class="subheading-2">Subscribed</h3></mat-slide-toggle>
                    </div>
                           
                   
                    <div class="row">
                      <button mat-raised-button color="primary">Create</button>
                      <button type="button" mat-button>Cancel</button>
                    </div>
                  </div>
                  <!-- <div fxLayoutAlign="stretch">
                    <pre>Status : {{register.status | json }}
        Data :               
        {{register.value | json }}
        Errors :
        {{ register.get('email').errors | json }}
                    </pre>
                  </div> -->
                </div>
            </mat-card-content>
          </mat-card>
    </form>
  </div>


