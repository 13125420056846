import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Company } from '../shared/company';

@Component({
  selector: 'app-profile-create-dialog',
  templateUrl: './profile-create-dialog.component.html',
  styleUrls: ['./profile-create-dialog.component.scss']
})
export class ProfileCreateDialogComponent implements OnInit {

  companyForm: FormGroup;
  company: Company;

  constructor(private fb: FormBuilder) { 
    this.createForm();
  }


  ngOnInit() {
  }

  createForm() {
    this.companyForm = this.fb.group({
      name: '',
      cvr: '',
      address: '',
      zip: '',
      city: '',
      country: '',
      phone: '',
      email: '',
      delivery_time: '',
      opening_hour: '',
      contact_name: '',
      contact_phone: '',
      contact_email: '',
      notes: '',
      logo_path: '',
      subscriber: '',
    });
  }


  onSubmit() {
    this.company = this.companyForm.value;
    console.log(this.company);
    this.companyForm.reset();
  }

}
