import { Injectable } from '@angular/core';
import {BusinessCategory} from '../shared/businessCategory';
import { Observable, of} from 'rxjs';
import { delay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

interface responseData{
  OK: string,
  categories: BusinessCategory[]
}

@Injectable({
  providedIn: 'root'
})
export class BusinessCategoryService {

  constructor(private http: HttpClient) { }

  getBusinessCategory(): Observable<responseData>{
    return this.http.get<responseData>('server/engros_company_category.php?action=list');
  }

  getBusinessCategoryById(id:string): Observable<BusinessCategory>{
    return this.http.get<BusinessCategory>('server/engros_company_category.php?action=read&id=' + id);

  }
}
