<mat-dialog-content [formGroup]="form" class="example-form">

    <h3>Oprette butik</h3>

    <mat-form-field appearance="outline" class="full-width">
        <mat-label>CVR</mat-label>
        <input matInput formControlName="cvr" required>
        <mat-error *ngIf="isFieldInvalid('cvr')">
            Der skal indtastes navn
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="full-width">
        <mat-label>Navn</mat-label>
        <input matInput formControlName="name" required>
        <mat-error *ngIf="isFieldInvalid('name')">
            Der skal indtastes navn
        </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="full-width">
        <mat-label>Adresse</mat-label>
        <input matInput formControlName="address" required>
        <mat-error *ngIf="isFieldInvalid('address')">
            Der skal indtastes adresse
        </mat-error>
    </mat-form-field>
    <div fxLayout="row" fxLayout.xs="column" class="mat-form-field--inline">
    <mat-form-field appearance="outline" class="full-width">
        <mat-label>Postnr.</mat-label>
        <input matInput  type="tel" pattern="[0-9]*" formControlName="zip" required>
        <mat-error *ngIf="isFieldInvalid('zip')">
            Der skal indtastes postnr
        </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="full-width">
        <mat-label>By</mat-label>
        <input matInput formControlName="city" required>
        <mat-error *ngIf="isFieldInvalid('city')">
            Der skal indtastes byen
        </mat-error>
    </mat-form-field>
</div>
<div fxLayout="row" fxLayout.xs="column" class="mat-form-field--inline">
    <mat-form-field appearance="outline" class="full-width">
        <mat-label>Phone</mat-label>
        <input matInput  type="tel" pattern="[0-9]*" formControlName="phone" required>
        <mat-error *ngIf="isFieldInvalid('phone')">
            Der skal indtastes phone
        </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="full-width">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" required>
        <mat-error *ngIf="isFieldInvalid('email')">
            Der skal indtastes Email
        </mat-error>
    </mat-form-field>
    </div>
<mat-form-field appearance="outline" class="full-width">
    <mat-label>Kontaktpersons navn</mat-label>
    <input matInput formControlName="contact_name" required>
    <mat-error *ngIf="isFieldInvalid('contact_name')">
        Der skal indtastes kontaktpersons navn
    </mat-error>
</mat-form-field>
<mat-form-field appearance="outline" class="full-width">
    <mat-label>Kontaktpersons mobil</mat-label>
    <input matInput  type="tel" pattern="[0-9]*" formControlName="contact_phone" required>
    <mat-error *ngIf="isFieldInvalid('contact_phone')">
        Der skal indtastes kontaktpersons mobile
    </mat-error>
</mat-form-field>


</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button mat-primary" mat-dialog-close>Luk</button>
    <span style="flex: 1 1 auto;"></span>
    <button class="mat-raised-button mat-primary" (click)="save()" [disabled] ="form.invalid">Send</button>
</mat-dialog-actions>

