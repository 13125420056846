<div fxLayout="column" fxFlex="70" fxFlexOffset="8" fxFlex.xs="auto" fxFlexOffset.xs="1">
 
  
  <h3>Søg efter kunde </h3>
    <div fxLayout="row" fxLayoutAlign="start center" style="margin-bottom:5%">
      <button mat-button mat-mini-fab color="primary" (click)="goBack()">
        <mat-icon aria-label="Cusotmer icon-button with a back icon">arrow_back</mat-icon>
      </button>
    </div>

    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Søg efter kunde</mat-label>
      <input (keydown.enter)="searchShops(text)" matInput placeholder="Søg ..." name="text" [(ngModel)]="text" cdkFocusInitial>
      <mat-icon matSuffix (click)="searchShops(text)" [style.cursor] ="'pointer'">send</mat-icon>
    </mat-form-field>
    <mat-progress-bar mode="indeterminate" *ngIf="isWait"></mat-progress-bar>

    <!-- <div fxLayout="row" fxLayoutAlign="center center">
      <mat-spinner  mode="indeterminate" *ngIf="isWait"></mat-spinner>
    </div>
  -->
    

  <ng-container *ngIf="(shops$ | async) as shops">
      <div *ngIf="shops.shops.length > 0">
        <div *ngIf="shops.shops.length <= 3; else moreInfo">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" >
  
          <ng-container matColumnDef="cvr">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> CVR </th>
              <td mat-cell *matCellDef="let element" [style.color]="'navy'" (click)="showInfo(element)" [style.cursor] ="'pointer'"> {{element.cvr}} </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Butiksnavn </th>
            <td mat-cell  *matCellDef="let element" [style.color]="'navy'" (click)="showInfo(element)" [style.cursor] ="'pointer'"> {{element.name}} </td>
          </ng-container>
          
          <ng-container matColumnDef="address">
                  <th mat-header-cell *matHeaderCellDef> Adresse </th>
                  <td mat-cell  *matCellDef="let element" [style.color]="'navy'" (click)="showInfo(element)" [style.cursor] ="'pointer'"> 
                      {{element.address}}, <br/>
                      {{element.zip}} {{element.city}}
                  </td>
                </ng-container>
          <ng-container matColumnDef="phone">
              <th mat-header-cell *matHeaderCellDef> Phone </th>
              <td mat-cell  *matCellDef="let element"> {{element.phone}} </td>
          </ng-container>
         
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        </div>
     <!-- <mat-list-item *ngFor="let shop of shops.shops">
          <button mat-button (click)="showInfo(shop)">
            {{shop.name}}
           </button>
      </mat-list-item>
      </mat-list>
      <mat-list>
      <mat-list-item *ngIf="shops.shops.length < 1">
          <button mat-button  color="warn"(click)="createNewShop()">
              Ikke fundet? Opret ny butik..!!
             </button>
        </mat-list-item> -->
      </div>
      <mat-list>
      <mat-list-item *ngIf="shops.shops.length < 1">
          <button mat-button  color="primary"(click)="createNewShop()">
              Ikke fundet. Vil du oprette en ny kunde?
             </button>
        </mat-list-item> 
      </mat-list>
    
  </ng-container>

  <ng-template #moreInfo>
      For mange resultater. Være mere specifik i din søgning..!!
    </ng-template>

  
</div>
