<div fxLayout="column" fxFlex="80" fxFlexOffset="8" fxFlex.xs="auto" fxFlexOffset.xs="1">
      <h3>{{ 'employees' | translate | titlecase}}</h3>
      <div fxLayout="column wrap" fxLayoutAlign="start start" class="card-container">
        <button button ="submit" mat-mini-fab color="primary" (click) = "createContact()">
          <mat-icon aria-label="Contact icon-button with a add icon">add</mat-icon>
        </button>
      </div>
      <div class=".example-container">
        <mat-form-field>
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Søg efter listen">
        </mat-form-field>
        <mat-progress-bar mode="indeterminate" *ngIf="isWait"></mat-progress-bar>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" >
          
          <ng-container matColumnDef="photo">
            <th mat-header-cell *matHeaderCellDef> Billede </th>
            <td mat-cell  *matCellDef="let element"> 
                <img class="offer-thumbnail" mat-card-avatar *ngIf="element.photo_path != '';else noImage" [src]="element.photo_path">
                <ng-template #noImage><img class="offer-thumbnail" mat-card-avatar [src]="imageUrl"></ng-template>
            </td>
          </ng-container>
          <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'name' | translate | titlecase}} </th>
              <td mat-cell *matCellDef="let element" (click)="editContact(element)" [style.color]="'navy'" [style.cursor] ="'pointer'" > {{element.name}} </td>
            </ng-container>
  
          <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef> Mobilnummer </th>
            <td mat-cell *matCellDef="let element"> 
                {{element.phone}}
            </td>
          </ng-container>
          <ng-container matColumnDef="job_title">
              <th mat-header-cell *matHeaderCellDef> Rolle </th>
              <td mat-cell *matCellDef="let element"> 
                  {{element.job_title}}
              </td>
            </ng-container>
          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef></th>
            <td  mat-cell *matCellDef="let element"> 
                <button mat-icon-button class="mat-primary" (click)="visibilityContact(element, 0)" *ngIf="element.is_visible == 1">
                    <mat-icon>visibility</mat-icon>
                </button>
                <button mat-icon-button class="mat-primary" (click)="visibilityContact(element, 1)" *ngIf="element.is_visible == 0">
                    <mat-icon>visibility_off</mat-icon>
                </button>
                <button mat-icon-button class="mat-warn" (click)="deleteContact(element)">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
          </ng-container>
        
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
  </div>