<mat-dialog-content>
    <h4>Butik information</h4>
    <mat-list-item>
        <p matLine>{{data.name}} </p>
        <p matLine>Cvr: {{data.cvr}}</p>
        <p matLine>{{data.zip}} {{data.city}}</p>
        <p matLine>{{data.country}}</p>
        <p matLine>Tel.: {{data.phone}}</p>
       <!-- <p matLine>Email. : {{data.email}}</p> -->
    </mat-list-item>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" mat-dialog-close>LUK</button>
    <span style="flex: 1 1 auto;"></span>
    <ng-container *ngIf="!data.favorite">
            <button class="mat-raised-button mat-primary" (click)="requestToConnect()">Anmod forbindelse</button>
    </ng-container>
    <ng-container *ngIf="data.favorite">
            <button class="mat-raised-button mat-warn">Allerede kunde hos dig</button>
    </ng-container>
    
</mat-dialog-actions>

