import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { SnackbarService } from '../services/snackbar.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  form: FormGroup;
  private formSubmitAttempt: boolean;

  constructor( private authService: AuthService,  private snackbarService: SnackbarService,
    public dialogRef: MatDialogRef<ForgetPasswordComponent>, private fb: FormBuilder) { }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  ngOnInit() {
    this.form = this.fb.group({
      handle: ['', Validators.required],
    });
  }

  send() {
    console.log('User: ', this.form.value);
    this.authService.resetPassword(this.form.value.handle).subscribe((data) => {
      if(data.OK == "YES"){
        this.snackbarService.success("Email is to "  + data.email)
        this.dialogRef.close();
      }
      else{
        this.snackbarService.success("Bruger ikke fundet..!!")
      }
    })
  }
}
