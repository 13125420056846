    
<mat-dialog-content [formGroup]="form" class="example-form">
    <div [dir]="'rtl'">
        <button mat-icon-button (click)="onPrint()" ><mat-icon>print</mat-icon></button>
        <span [dir]="'ltr'" style="float:left; color:navy;text-align:left;padding-bottom: 16px;"><b>{{offerTitle}}</b></span>
    </div>
    
    <div [dir]="'ltr'">
        <b>{{offerResponse.shop_name}}</b> <br/>
        <span><a [href]="'tel:+'+offerResponse.shop_phone"><mat-icon>phone</mat-icon></a> {{offerResponse.shop_phone}}</span>
        <p>Kontakt person: {{offerResponse.shop_contact_name}} </p>
    </div>   
    <mat-checkbox formControlName="done" style="text-align:right;">Afsluttet</mat-checkbox>
    <mat-form-field appearance="outline">
        <mat-label>Kundenummer</mat-label>
        <input matInput formControlName="engros_company_customer_id">
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Noter</mat-label>
        <textarea matInput formControlName="note" matTextareaAutosize matAutosizeMinRows=5></textarea>
    </mat-form-field>
   
</mat-dialog-content>
<mat-dialog-actions>

    <button class="mat-raised-button"
            (click)="close()">
        Fortryd
    </button>

    <span class="fill-space"></span>
    
    <button class="mat-raised-button mat-primary"
            (click)="save()" [disabled] ="form.invalid">
        Gem
    </button>
  

</mat-dialog-actions>