import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
import {Message} from '../shared/message';
import { MessageService } from '../services/message.service';
import { Params, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { switchMap, flatMap } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { interval } from 'rxjs';

@Component({
  selector: 'app-message-detail',
  templateUrl: './message-detail.component.html',
  styleUrls: ['./message-detail.component.scss']
})
export class MessageDetailComponent implements OnInit {

  showProgressIcon = true
  messages: Message[]
  message:Message
  errMsg: string
  c_id: string
  text: string
  shop_id: number
  shop_name : string
  timer: any
  public event: EventEmitter<any> = new EventEmitter();
  constructor(
    private messageService: MessageService,
    private route: ActivatedRoute,
    private location: Location, 
    private dialogRef: MatDialogRef<MessageDetailComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data:any
  ) { 
    this.shop_name = data.shop_name
    this.c_id = localStorage.getItem("profile_id")
    this.shop_id = data.shop_id

    
    this.messageService.getMessageByUser(this.c_id, this.shop_id)
    .subscribe(data => {
      if(data.OK == "YES"){
        this.messages = data.list; 
        this.message = this.messages[this.messages.length - 1]
        if(this.message.sending == "Shop" && this.message.status == "Unread"){
          this.messageService.updateStatus(this.message).subscribe(data=>{
            if(data.OK == "YES"){
              this.message.status = "Read";
            }
          })
        }
        this.showProgressIcon = false
      }
    },errmsg => this.errMsg = <any>errmsg) 
  }

  ngOnInit() {
    this.pullDataFromServer()
  }

  ngOnDestroy(){
    this.timer.unsubscribe()
  }


  pullDataFromServer(){
    this.timer = interval(2*60* 1000)
    .pipe(
        flatMap(() => this.messageService.getMessageByUser(this.c_id, this.shop_id))
    )
    .subscribe(data => {
      if(data.OK == "YES"){
        this.messages = data.list; 
        this.message = this.messages[this.messages.length - 1]
        if(this.message.sending == "Shop" && this.message.status == "Unread"){
          this.messageService.updateStatus(this.message).subscribe(data=>{
            if(data.OK == "YES"){
              this.message.status = "Read";
            }
          })
        }
      }
    },errmsg => this.errMsg = <any>errmsg)
  }

  sendText(text: string) {
    console.log(text)
   // this.showProgressIcon = true
    this.message = new Message()
    this.message.sending = "Company"
    this.message.company_id = Number.parseInt(this.c_id)
    this.message.shop_id = this.shop_id
    this.message.body = text;
    this.message.create_date = new Date()
    this.messages.unshift(this.message);
        this.text = "";
    //console.log(this.messages)
  this.messageService.createMessage(this.message).subscribe(data => {
      if(data.OK == "YES"){
        //this.messages.unshift(this.message);
        //this.text = "";
        //this.showProgressIcon = false
      }
    })
    
  }

  close() {
    this.event.emit();
    this.dialogRef.close();
}
}
