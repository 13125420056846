import { Component, EventEmitter, Inject, OnInit  } from '@angular/core';
import { OfferResponse } from '../shared/offerResponse';
import { Params, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { switchMap } from 'rxjs/operators';
import {MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource} from '@angular/material'
import { ResponseOrderService } from '../services/response-order.service';
import { ResponseInterestedListDetailComponent } from '../response-interested-list-detail/response-interested-list-detail.component';
import{ SnackbarService } from '../services/snackbar.service';
import {OfferService} from '../services/offer.service'
import { TranslateService } from '@ngx-translate/core';
import {Offer} from '../shared/offer'

@Component({
  selector: 'app-response-interested-list',
  templateUrl: './response-interested-list.component.html',
  styleUrls: ['./response-interested-list.component.scss']
})
export class ResponseInterestedListComponent implements OnInit {


  errMsg: string;
  id: number;
  offer:Offer;
  dataSource;
  displayedColumns: string[] = ['shop_name','shop_phone','shop_contact_name','create_date','done'];
 
  constructor(
    private dialog: MatDialog, 
    private route: ActivatedRoute,
    private location: Location,
    private offerService: OfferService,
    private offerResponseService: ResponseOrderService,
    private translate: TranslateService,
    private snackbarService: SnackbarService) { 

  }

  ngOnInit() {

    const id = this.route.params
    .pipe(switchMap((params: Params) => {
      this.id = +params['id']
      return this.offerResponseService.getOfferResponse(params['id']);
    }))
    .subscribe(data => {
      if(data.OK == "YES")
      this.dataSource = new MatTableDataSource<OfferResponse>(data.responses)
    },
    errmsg => this.errMsg = <any>errmsg);

    this.offerService.readOffer(this.id.toString()).subscribe(data => this.offer = data)

  }

  editOffer(offerResponse:OfferResponse){
    let dialogRef = this.dialog.open(ResponseInterestedListDetailComponent, {
      width: '600px',
      disableClose: true ,
      data: {offerTitle: this.offer.title, offerResponse}
    });
  dialogRef.componentInstance.event.subscribe((result) => {
    console.log(result.data)
    this.offerResponseService.updateOfferResponse(result.data).subscribe(
    data => {
     // if(data.OK == "YES"){
      this.offerResponseService.getOfferResponse(this.id.toString()).subscribe(
          (data) => {
            if(data.OK == "YES")
              this.dataSource = new MatTableDataSource<OfferResponse>(data.responses)
            this.snackbarService.success(this.translate.instant("update_success"))
          })
      }
    //}
    )
  });
  }

  goBack(): void{
    this.location.back();
  }

}
