import { Injectable } from '@angular/core';
import {User} from '../shared/user';
import { Observable, of} from 'rxjs';
import { delay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { baseURL } from '../shared/baseurl';

interface responseData{
  OK: string,
  users: User[]
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  /*ELEMENT_DATA: User[] = [
    {OK: "", handle: "", password: 'Manjil Thapa'},
    {OK: "", handle: "", password: 'Dennis'}
  ];*/


  constructor(private http: HttpClient) { }

  /*getUser(): Observable<User[]>{
    //return of<User[]>(this.ELEMENT_DATA).pipe(delay(2000));
    //return this.http.get<User[]>(baseURL + 'users');
  }*/

  getUsers(): Observable<responseData>{
    return this.http.get<responseData>('server/engros_company_user.php?action=list');
  }

  addUser(data){
    //this.ELEMENT_DATA.push(data);
  }

  dataLength(){
    //return this.ELEMENT_DATA.length;
  }
}
