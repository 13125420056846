<div fxLayout="column" fxFlex="70" fxFlexOffset="8" fxFlex.xs="auto" fxFlexOffset.xs="1">
 
 <h3>{{'shopping_list' | translate | titlecase}} </h3>
    <div  fxLayout="row" fxLayoutAlign="space-between center">
      <button mat-button mat-mini-fab color="primary" (click)="goBack()">
        <mat-icon aria-label="Cusotmer icon-button with a back icon">arrow_back</mat-icon>
      </button>
    
        <button mat-button mat-mini-fab color="primary">
          <mat-icon aria-label="Cusotmer icon-button with a back icon" (click)="createShoppingList()">add</mat-icon>
        </button>
      </div>
   
    <h4>{{shop_name}}</h4>

    <div>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" >
      
          <ng-container matColumnDef="engros_company_customer_id">
              <th mat-header-cell *matHeaderCellDef> Kundenummer </th>
              <td mat-cell *matCellDef="let element" [style.color]="'navy'" [routerLink]="['/shopping-detail', element.id]" [style.cursor] ="'pointer'" > 
                     {{element.engros_company_customer_id}}
              </td>
              
            </ng-container>
            <ng-container matColumnDef="shopping_list_name" >
                <th mat-header-cell *matHeaderCellDef> {{'name' | translate | titlecase}} </th>
                <td mat-cell *matCellDef="let element" [style.color]="'navy'" [routerLink]="['/shopping-detail', element.id]" [style.cursor] ="'pointer'" > 
                     {{element.shopping_list_name}}
                </td>
              </ng-container>
             
                <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element" > 
                        <button mat-flat-button color="primary" [routerLink]="['/shopping-detail', element.id]">View</button> &nbsp;
                        <button mat-flat-button color="accent" (click)="updateShoppingList(element)">Edit</button> &nbsp;
                        <button mat-flat-button color="warn" (click)="delete(element)">Delete</button>
                      <!--<mat-icon  class="mat-primary" (click)="delete(element)">delete</mat-icon>-->
                    </td>
                  </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
</div>
