import { Component, OnInit, ViewChild } from '@angular/core';
import{CompanyService} from '../services/company.service'
import {OfferService } from '../services/offer.service'
import {ResponseOrderService} from '../services/response-order.service'
import { Offer } from '../shared/offer';
import {OfferAddComponent} from '../offer-add/offer-add.component';
import {MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material'
import { OfferDetailComponent } from '../offer-detail/offer-detail.component';
import { MatConfirmDialogComponent } from '../mat-confirm-dialog/mat-confirm-dialog.component';
import { DialogService } from '../services/dialog.service';
import{ SnackbarService } from '../services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource } from '@angular/material';
import { ResponseInterestedListComponent } from '../response-interested-list/response-interested-list.component';
import { take } from 'rxjs/operators';
import { Company } from '../shared/company';


@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss']
})
export class OfferComponent implements OnInit {

  isWait: Boolean = true
  offers: Offer[];
  company: Company
  id: string;
  offer = {
    title : "Ny tilbud ... ",
    body: "",
    status : "draft",
    price: 0,
    currency:"",
    from_date: "",
    to_date : "",
    company_id : localStorage.getItem("profile_id")
  };
  dataSource;
  displayedColumns: string[] = ['title', 'status', 'from_date', 'to_date', 'view_response' ];
  
  constructor(
    private companyService: CompanyService,
    private offerService: OfferService, 
    private offerResponseService: ResponseOrderService,
    private dialog: MatDialog, 
    private dialogService: DialogService,
    private translate: TranslateService,
    private snackbarService: SnackbarService) { }

  ngOnInit() {
    this.id = localStorage.getItem("profile_id")
    this.offerService.getOffersByProfileId(this.id).subscribe(
      data => {
        this.offers = data.offers.filter((offer: Offer) => offer.status != "deleted")
        this.dataSource = new MatTableDataSource<Offer>(this.offers)
        this.isWait = false
      }
    )
    /*this.companyService.companyPromise().pipe(take(1)).subscribe((data) => {
      this.companyService.changeData(data)
      this.companyService.dataWasLoaded.next(true)
      this.company = data
      console.log(data)})*/
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  createOffer(): void{
    this.offerService.createOffer(this.offer).subscribe(
      (data) => {
        //if(data.OK == "YES"){
          this.offerService.getOffersByProfileId(this.id).subscribe(
            (data) => {this.offers = data.offers.filter((offer: Offer) => offer.status != "deleted")
            this.dataSource = new MatTableDataSource<Offer>(this.offers)
            this.snackbarService.success(this.translate.instant("add_success"))
            })
        }
      //}
      )
  }

  /*viewOfferResponse(id:string){
    this.offerResponseService.getOfferResponse(id).subscribe(
      (data) => {
        console.log(data.responses)
        if(data.OK == "YES")
          this.openOfferResponseList(data.responses)
      }
    )
  }*/

  /*openOfferResponseList(data:any){
    let dialogRef = this.dialog.open(ResponseInterestedListComponent, {
      width: '800px',
      disableClose: true ,
      data: data
    });
  dialogRef.componentInstance.event.subscribe((result) => {
    console.log(result.data)
  });
  }*/

  /*openCreateDialog(): void{
    let dialogRef = this.dialog.open(OfferAddComponent, {
      width: '600px',
      data: 'Add News',
      disableClose: true 
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      console.log(result.data)
      this.offerService.createOffer(result.data).subscribe(
      (data) => {
        if(data.OK == "YES"){
          this.offerService.getOffersByProfileId(this.id).subscribe(
            (data) => this.offers = data.offers
          )
        }
      })
    });
  }*/

  //function with param item object
  editOffer(offer:Offer){
    let dialogRef = this.dialog.open(OfferDetailComponent, {
      width: '800px',
      disableClose: true ,
      data: offer
    });
  dialogRef.componentInstance.event.subscribe((result) => {
    console.log(result.data)
    if(result.data.status == "published"){
      this.offerService.publishOffer(result.data).subscribe(
        data => {
        // if(data.OK == "YES"){
            this.offerService.getOffersByProfileId(this.id).subscribe(
              (data) => {
                this.offers = data.offers.filter((offer: Offer) => offer.status != "deleted")
                this.dataSource = new MatTableDataSource<Offer>(this.offers)
                this.snackbarService.success(this.translate.instant("update_success"))
              })
          }
        //}
        )
    }else{
      this.offerService.updateOffer(result.data).subscribe(
      data => {
      // if(data.OK == "YES"){
          this.offerService.getOffersByProfileId(this.id).subscribe(
            (data) => {
              this.offers = data.offers.filter((offer: Offer) => offer.status != "deleted")
              this.dataSource = new MatTableDataSource<Offer>(this.offers)
              this.snackbarService.success(this.translate.instant("update_success"))
            })
        }
      //}
      )
    }
  });
  }
  
  delete(offer:Offer): void{
    event.stopPropagation()
     this.dialogService.openConfirmDialog(this.translate.instant("phrases.sure_delete_offer"))
     .afterClosed().subscribe(res=>{
       if(res){
        this.offerService.updateStatusOffer(offer).subscribe(
          data => {
            this.offers.splice(this.offers.indexOf(offer), 1);
            this.dataSource = new MatTableDataSource<Offer>(this.offers)
            this.snackbarService.warn(this.translate.instant("delete_success"));
            }
          //}
          )
        
       }
     })
  
    /*this.offerService.deleteOffer(offer.id.toString()).subscribe(
      (data) => {
        if(data.OK == "YES"){
          this.offers.splice(this.offers.indexOf(offer), 1);
        }
      }
    )*/
    
    //console.log(notification);
    }

}

