<mat-dialog-content>
 <div [innerHTML]="html_text"></div>
</mat-dialog-content>

<mat-dialog-actions>
    <button color="primary" mat-button mat-dialog-close>LUK</button>
    <span style="flex: 1 1 auto;"></span>
  <!--  <mat-form-field appearance="outline">
        <mat-label>Forventet leveringsdato</mat-label>
        <input matInput [matDatepicker]="deliveryDate" [(ngModel)] = "data.delivery_date"  (dateChange)="someMethodName($event)"required>
        <mat-datepicker-toggle matSuffix [for]="deliveryDate"> </mat-datepicker-toggle>
        <mat-datepicker #deliveryDate></mat-datepicker>
    </mat-form-field> -->
    <button mat-icon-button (click)="onMessage()" ><mat-icon>message</mat-icon></button>
    <button mat-icon-button (click)="onPrint()" ><mat-icon>print</mat-icon></button>
</mat-dialog-actions>

