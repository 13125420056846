import { Component, OnInit } from '@angular/core';
import { ContactService } from '../services/contact.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '../services/snackbar.service';
import { Contact } from '../shared/contact';
import { ContactDetailComponent } from '../contact-detail/contact-detail.component';
import { ContactAddComponent } from '../contact-add/contact-add.component';
import {MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material'
import { DialogService } from '../services/dialog.service';
import { MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  isWait : Boolean = true
  contacts : Contact[]
  id: string
  visible : Boolean = false
  imageUrl: string = "/assets/imgs/defaultImg.jpg"


  contact = {
    name: "Ny kontakter ...",
    phone : "",
    email: "",
    job_title:"",
    company_id : localStorage.getItem("profile_id")
  };

  dataSource;
  displayedColumns: string[] = ['photo', 'name', 'phone', 'job_title', 'edit'];
 

  constructor(
    private contactService: ContactService,
    private dialog: MatDialog, 
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.id = localStorage.getItem("profile_id")
    this.contactService.getContacts(this.id).subscribe(
      (data) =>{ 
        this.contacts = data.contact_list
        this.dataSource = new MatTableDataSource<Contact>(this.contacts)
        this.isWait = false
      }
    )
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /*createContact(): void{
    this.contactService.addContact(this.contact).subscribe(
      (data) => {
        //if(data.OK == "YES"){
          this.contactService.getContacts(this.id).subscribe(
            (data) => {this.contacts = data.contact_list
            this.snackbarService.success(this.translate.instant("add_success"))
            })
        }
      //}
      )
  }*/
  createContact(): void{
    let dialogRef = this.dialog.open(ContactAddComponent, {
      width: '600px',
      data: 'Add News',
      disableClose: true 
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      console.log(result.data)
      this.contactService.addContact(result.data).subscribe(
      (data) => {
        if(data.OK == "YES"){
          this.contactService.getContacts(this.id).subscribe(
            (data) => {
              this.contacts = data.contact_list
              this.dataSource = new MatTableDataSource<Contact>(this.contacts)
              this.snackbarService.success(this.translate.instant("add_success"))
              })
        }
      })
    });
  }

  editContact(contact: Contact){
    let dialogRef = this.dialog.open(ContactDetailComponent, {
      width: '600px',
      disableClose: true ,
      data: contact
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      console.log(result.data)
      this.contactService.updateContact(result.data).subscribe(
      data => {
      // if(data.OK == "YES"){
          this.contactService.getContacts(this.id).subscribe(
            (data) => {
              this.contacts = data.contact_list
              this.dataSource = new MatTableDataSource<Contact>(this.contacts)
              this.snackbarService.success(this.translate.instant("update_success"))
            })
        }
      //}
      )
    });
  }

  deleteContact(contact:Contact): void{
    event.stopPropagation()
     this.dialogService.openConfirmDialog(this.translate.instant("Er du sikker på slette denne kontakt?"))
     .afterClosed().subscribe(res=>{
       if(res){
        this.contactService.deleteContact(contact.id).subscribe(
          data => {
            this.contacts.splice(this.contacts.indexOf(contact), 1);
            this.dataSource = new MatTableDataSource<Contact>(this.contacts)
            this.snackbarService.warn(this.translate.instant("delete_success"));
            }
          //}
          )
        
       }
     })
  }

  visibilityContact(contact: Contact, value: string){
    event.stopPropagation()
    let info: string;
    if(value == "0"){
       info = "Vil du skjule " + contact.name + " i app?"
    }else 
      info = "Vil du gøre " + contact.name + " synlig i app?"

     this.dialogService.openConfirmDialog(this.translate.instant(info))
     .afterClosed().subscribe(res=>{
       if(res){
        this.contactService.updateVisibilityContact(contact.id, value).subscribe(
          data => {
            this.contacts.find(item => item.id == contact.id).is_visible = value;
            this.dataSource = new MatTableDataSource<Contact>(this.contacts)
            this.snackbarService.warn(this.translate.instant("update_success"));
            }
          //}
          )
        
       }
     })
  }

}
