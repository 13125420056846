<div fxLayout="column" fxFlex="70" fxFlexOffset="8" fxFlex.xs="auto" fxFlexOffset.xs="1">
 
  <div>
    <h3>{{'messages' | translate | titlecase }}</h3>
    <div fxLayout="column" fxLayoutAlign="start start" class="card-container">
      <button button ="submit" mat-mini-fab color="primary" (click) = "createMessage()">
        <mat-icon aria-label="Message icon-button with a add icon">add</mat-icon>
      </button>
    </div>
    <div>
        <mat-progress-bar mode="indeterminate" *ngIf="isWait"></mat-progress-bar>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" >
    
          <ng-container matColumnDef="shop_name">
           
           <!-- <td mat-cell  *matCellDef="let element" [style.color]="'navy'" [routerLink]="['/message', element.shop_name, element.shop_id]" [style.cursor] ="'pointer'"> {{element.shop_name}} </td> -->
           <td mat-cell  *matCellDef="let element" [style.color]="'navy'" (click) = "showDetails(element)" [style.cursor] ="'pointer'"> 
             
             <div *ngIf="element.sending === 'Shop'"> 

                <p *ngIf="element.status === 'Unread'">
                    <b> 
                      {{element.shop_name}} <br/>
                      <span class="message-body">{{element.app_user_name}} - {{element.body}}</span>
                    </b>
                </p>
                <p *ngIf="element.status === 'Read'"> 
                    {{element.shop_name}} <br/>
                    <span class="message-body">{{element.app_user_name}} - {{element.body}}</span>
                </p>
              </div>
              <div *ngIf="element.sending === 'Company'">
                  <p> 
                    {{element.shop_name}}<br/>
                    <span class="message-body">{{'you' | translate | titlecase }} - {{element.body}}</span>
                  </p>
              </div>
           </td>
          </ng-container>
          <ng-container matColumnDef="create_date">
              
              <td mat-cell *matCellDef="let element" [style.color]="'green'" > 
                   {{element.create_date | toDateObj | date}}
              </td>
              
            </ng-container>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
  </div>
</div><!--<div class ="container" fxFlex="40" fxLayout="column" fxFlexOffset="20" fxFlex.xs="auto" fxFlexOffset.xs="auto">
  <h3>Besked</h3>
      <mat-card  *ngFor="let msg of messages" [routerLink]="['/message', msg.app_user_name, msg.app_user_id]" class="card-container" >
        <div class="message">
          <h5>{{msg.app_user_name}}</h5>
          <p *ngIf="msg.status === 'Unread'" class="message-status"> 
            <b>{{msg.body}}</b>
          </p>
          <p *ngIf="msg.status === 'Read'" class="message-status"> 
              {{msg.body}}
            </p>
        </div>
    </mat-card>
</div>-->
