import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import {Message} from '../shared/message';
import {MessageService} from '../services/message.service'
import { MatDialog, MatTableDataSource, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DialogService } from '../services/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '../services/snackbar.service';
import { MessageDetailComponent } from '../message-detail/message-detail.component';
import { interval } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { ShopService } from '../services/shop.service';
import { Shop } from '../shared/shop';


@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit, OnDestroy {

  isWait : Boolean = true
  messages: Message[];
  id: string;
  dataSource: any;
  displayedColumns: string[] = ['shop_name', 'create_date'];
  timer : any
 

  constructor(
    private messageService: MessageService,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private translate: TranslateService,
    private snackbarService: SnackbarService) { 
      
    }

  ngOnInit() {
    this.id = localStorage.getItem("profile_id")
    this.messageService.getMessageByCompanyId(this.id).subscribe(
      (data) => {
        this.messages = data.list
        this.dataSource = new MatTableDataSource<Message>(this.messages)
        this.isWait = false
      })
      this.pullDataFromServer()
  }

  ngOnDestroy(){
    this.timer.unsubscribe()
  }


  pullDataFromServer(){
    this.timer = interval(2*60* 1000)
  
    .pipe(
        flatMap(() => this.messageService.getMessageByCompanyId(this.id))
    )
    .subscribe(data => {
      this.messages = data.list
      this.dataSource = new MatTableDataSource<Message>(this.messages)
    })
  }

  showMessage(message: Message){
    
  }
  createMessage(): void{
   this.dialog.open(DialogCreateMessage, {
      width: '300px',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: this.id
    })
    this.dialog.afterAllClosed.subscribe(result => {
      this.messageService.getMessageByCompanyId(this.id).subscribe(
        (data) => {
          this.messages = data.list
          this.dataSource = new MatTableDataSource<Message>(this.messages)
        })
    });
  }
  //function with param item object
  showDetails(message:Message){
    let dialogRef = this.dialog.open(MessageDetailComponent, {
      width: '700px',
      disableClose: true ,
      data: {shop_id: message.shop_id, shop_name: message.shop_name}
    });
  dialogRef.componentInstance.event.subscribe(() => {
    this.messageService.getMessageByCompanyId(this.id).subscribe(
      (data) => {
        this.messages = data.list
        this.dataSource = new MatTableDataSource<Message>(this.messages)
      })
  });
  }
}

@Component({
  selector: 'dialog-create-message',
  templateUrl: 'dialog-create-message.html',
  styleUrls: ['./message.component.scss']
})
export class DialogCreateMessage {
  company_id: string
  shops: Shop[]
constructor( 
  private dialog: MatDialog, 
  private shopService: ShopService,  
  private translate: TranslateService, 
  public dialogRef: MatDialogRef<DialogCreateMessage>,
  @Inject(MAT_DIALOG_DATA) private data: any) {    
  this.company_id = data
}

ngOnInit() {
    this.shopService.getFavoriteShopList(this.company_id).subscribe(
      data => {
        this.shops = data.shops
      }
    )
}
showDetails(shop:any){
  console.log(shop)
  let dialogRef =  this.dialog.open(MessageDetailComponent, {
    width: '700px',
    disableClose: true ,
    data: {shop_id: shop.id , shop_name: shop.shop_name}
  });
  this.dialogRef.close()
  dialogRef.afterClosed().subscribe(() => {
    //this.dialogRef.close()
  })
}

closeDialog(){
  this.dialogRef.close()
}

}
