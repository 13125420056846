<mat-dialog-content>
    <mat-form-field appearance="outline" class="full-width">
        <mat-label>{{'message' | translate | titlecase }}</mat-label>
        <textarea matInput name="text" [(ngModel)]="text" cdkFocusInitial matTextareaAutosize></textarea>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button color="primary" mat-button mat-dialog-close>Cancel</button>
    <span style="flex: 1 1 auto;"></span>
  <!--  <mat-form-field appearance="outline">
        <mat-label>Forventet leveringsdato</mat-label>
        <input matInput [matDatepicker]="deliveryDate" [(ngModel)] = "data.delivery_date"  (dateChange)="someMethodName($event)"required>
        <mat-datepicker-toggle matSuffix [for]="deliveryDate"> </mat-datepicker-toggle>
        <mat-datepicker #deliveryDate></mat-datepicker>
    </mat-form-field> -->
    <button mat-icon-button (click)="sendMessage(text)" ><mat-icon>send</mat-icon></button>
</mat-dialog-actions>

