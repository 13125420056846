import {MediaMatcher} from '@angular/cdk/layout';
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy, OnInit {

  isLoggedIn$: Observable<boolean>
  hasRole: string
 
  mobileQuery: MediaQueryList;
  //id: string 
  private _mobileQueryListener: () => void;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private authService: AuthService) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener
      );
  }

  ngOnInit(){
    this.isLoggedIn$ = this.authService.isLoggedIn;
    this.authService.role.subscribe((data) => {
      this.hasRole = data
      console.log(this.hasRole)
     })
    console.log(this.hasRole)
    console.log(localStorage.getItem("profile_id"))
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  onLogout() {
    this.authService.logout();
  }

}
