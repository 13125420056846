<div fxLayout="column" fxFlex="60" fxFlexOffset="8" fxFlex.xs="auto" fxFlexOffset.xs="1" *ngIf='employee'>
    <mat-card class="example-card mat-elevation-z1" >
        <mat-card-header>
            
              <input style="display: none" [disabled]="!imageClick" type="file" (change)="onFileChanged($event.target.files)"
                #fileInput accept="image/*">
              <!--<mat-icon style="margin-left:auto; display: flex;" *ngIf="!imageClick" (click) = "deleteImage()">close</mat-icon>-->
              <img  mat-card-avatar [src]="imageUrl"  (click)="fileInput.click()">
        </mat-card-header>
        <mat-card-content>
          <p>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Navn</mat-label>
              <input matInput value="{{employee.name}}" [(ngModel)]="employee.name">
            </mat-form-field>
          </p>

          <p>
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Email</mat-label>
                <input matInput value="{{employee.email}}" [(ngModel)]="employee.email">
              </mat-form-field>
          </p>

          <p>
                <mat-form-field appearance="outline" class="full-width">
                <mat-label>Mobilenummre.</mat-label>
                <input matInput value="{{employee.phone}}" [(ngModel)]="employee.phone">
              </mat-form-field>    
          </p>

          <p>
              <mat-form-field appearance="outline" class="full-width">
              <mat-label>Position</mat-label>
              <input matInput value="{{employee.job_title}}" [(ngModel)]="employee.job_title">
            </mat-form-field>    
        </p>
          </mat-card-content>
          <mat-card-actions>
            <button mat-raised-button color="primary" class="float-right" (click)="updateEmployee()">GEM</button>
          </mat-card-actions>
        </mat-card>
</div>
