import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {MatMomentDateModule} from "@angular/material-moment-adapter";
import { AppMaterialModule } from './app-material/app-material.module';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import 'hammerjs';

import { AppRoutingModule } from './app-routing/app-routing.module';

import { baseURL } from './shared/baseurl';

import { AuthGuard } from './shared/auth.guard';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { MainNavComponent } from './main-nav/main-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ProfileComponent } from './profile/profile.component';
import { OfferComponent } from './offer/offer.component';
import { UserComponent } from './user/user.component';
import { NewsComponent } from './news/news.component';
import { NotificationComponent } from './notification/notification.component';
import { StatisticComponent } from './statistic/statistic.component';
import { EconomyComponent } from './economy/economy.component';
import { UserCreateDialogComponent } from './user-create-dialog/user-create-dialog.component';
import { AdminComponent } from './admin/admin.component';
import { ProfileCreateDialogComponent } from './profile-create-dialog/profile-create-dialog.component';
import { AddProfileComponent } from './add-profile/add-profile.component';
import { ProfiledetailComponent } from './profiledetail/profiledetail.component';
import { CompanyComponent } from './company/company.component';
import { NewsDetailComponent } from './news-detail/news-detail.component';
import { NewsAddComponent } from './news-add/news-add.component';
import { NotificationAddComponent } from './notification-add/notification-add.component';
import { NotificationDetailComponent } from './notification-detail/notification-detail.component';
import { OfferDetailComponent } from './offer-detail/offer-detail.component';
import { OfferAddComponent } from './offer-add/offer-add.component';
import { OfferDeleteComponent } from './offer-delete/offer-delete.component';
import { MatConfirmDialogComponent } from './mat-confirm-dialog/mat-confirm-dialog.component';
import { ReviewComponent } from './review/review.component';
import { RatingComponent } from './rating/rating.component';
import { MessageComponent, DialogCreateMessage } from './message/message.component';
import { RatingReplyComponent } from './rating-reply/rating-reply.component';
import { MessageDetailComponent } from './message-detail/message-detail.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ContactComponent } from './contact/contact.component';
import { ContactDetailComponent } from './contact-detail/contact-detail.component';
import { ContactAddComponent } from './contact-add/contact-add.component';
import { OrderComponent, DialogOrderContent, DialogMessageContent } from './order/order.component';
import { SignupComponent, DialogSignupPrivacy } from './signup/signup.component';
import { ResponseInterestedListComponent } from './response-interested-list/response-interested-list.component';
import { ResponseInterestedListDetailComponent } from './response-interested-list-detail/response-interested-list-detail.component';
import { NotificationInterestedListComponent } from './notification-interested-list/notification-interested-list.component';
import { NotificationInterestedListDetailComponent } from './notification-interested-list-detail/notification-interested-list-detail.component';
import { CustomerComponent, DialogShopContent, DialogContactList } from './customer/customer.component';
import { ProductComponent } from './product/product.component';
import { ProductAddComponent } from './product-add/product-add.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ShoppingListComponent, DialogCreateShoppingList, DialogUpdateShoppingList } from './shopping-list/shopping-list.component';
import { ShoppingListDetailComponent } from './shopping-list-detail/shopping-list-detail.component';
import { ShoppingListItemAddComponent } from './shopping-list-item-add/shopping-list-item-add.component';
import { ShoppingListItemEditComponent } from './shopping-list-item-edit/shopping-list-item-edit.component';
import { OfferPublishComponent } from './offer-publish/offer-publish.component';
import { NewsPublishComponent } from './news-publish/news-publish.component';
import { NotificationPublishComponent } from './notification-publish/notification-publish.component';
import { ToDateObjPipe } from './to-date-obj.pipe';
import { CustomerAddComponent, DialogCreateShop, DialogShopInfo, DialogShopList } from './customer-add/customer-add.component';
import { EmployeeComponent } from './employee/employee.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    HomeComponent,
    MainNavComponent,
    ForgetPasswordComponent,
    ProfileComponent,
    OfferComponent,
    UserComponent,
    NewsComponent,
    NotificationComponent,
    StatisticComponent,
    EconomyComponent,
    UserCreateDialogComponent,
    AdminComponent,
    ProfileCreateDialogComponent,
    AddProfileComponent,
    ProfiledetailComponent,
    CompanyComponent,
    NewsDetailComponent,
    NewsAddComponent,
    NotificationAddComponent,
    NotificationDetailComponent,
    OfferDetailComponent,
    OfferAddComponent,
    OfferDeleteComponent,
    MatConfirmDialogComponent,
    ReviewComponent,
    RatingComponent,
    MessageComponent,
    DialogCreateMessage,
    RatingReplyComponent,
    MessageDetailComponent,
    PageNotFoundComponent,
    ContactComponent,
    ContactDetailComponent,
    ContactAddComponent,
    OrderComponent,
    DialogOrderContent,
    DialogMessageContent,
    DialogShopContent,
    DialogSignupPrivacy,
    SignupComponent,
    ResponseInterestedListComponent,
    ResponseInterestedListDetailComponent,
    NotificationInterestedListComponent,
    NotificationInterestedListDetailComponent,
    CustomerComponent,
    DialogContactList,
    ProductComponent,
    ProductAddComponent,
    ProductDetailComponent,
    ShoppingListComponent,
    DialogCreateShoppingList,
    DialogUpdateShoppingList,
    ShoppingListDetailComponent,
    ShoppingListItemAddComponent,
    ShoppingListItemEditComponent,
    OfferPublishComponent,
    NewsPublishComponent,
    NotificationPublishComponent,
    ToDateObjPipe,
    CustomerAddComponent,
    DialogCreateShop,
    DialogShopInfo,
    DialogShopList,
    EmployeeComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    LayoutModule,
    HttpClientModule,
    MatMomentDateModule,TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [ HttpClient ]
      }
    }),
  ],
  entryComponents: [
    ForgetPasswordComponent,
    UserCreateDialogComponent,
    ProfileCreateDialogComponent,
    DialogCreateMessage,
    MessageDetailComponent,
    NewsAddComponent,
    NewsDetailComponent,
    NewsPublishComponent,
    NotificationAddComponent,
    NotificationDetailComponent,
    NotificationPublishComponent,
    OfferDetailComponent,
    OfferAddComponent,
    OfferPublishComponent,
    MatConfirmDialogComponent,
    RatingReplyComponent,
    ContactDetailComponent,
    ContactAddComponent,
    DialogOrderContent,
    DialogMessageContent,
    DialogShopContent,
    DialogSignupPrivacy,
    ResponseInterestedListDetailComponent,
    ProductAddComponent,
    ProductDetailComponent,
    DialogCreateShoppingList,
    DialogUpdateShoppingList,
    ShoppingListItemAddComponent,
    ShoppingListItemEditComponent,
    DialogCreateShop,
    DialogShopInfo,
    DialogShopList,
    DialogContactList
  ],
  providers: [
    AuthService, 
    AuthGuard, 
    UserService, 
    {provide: 'BaseURL', useValue: baseURL},
  ],
  exports:[ToDateObjPipe],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor(translate: TranslateService) {
    // 'en-GB' -> 'en'
    const browserLang = translate.getBrowserLang();
    console.log(browserLang)
    translate.use(browserLang.match(/en|da/) ? browserLang : 'da');
  }
}
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
