<mat-dialog-content [formGroup]="form" class="example-form">

        <mat-form-field>
    
            <input matInput
                    placeholder="Titel"
                   formControlName="title">
    
        </mat-form-field>
        <mat-form-field>
    
                <textarea matInput placeholder="Indhold"
                    formControlName="body">
        
                </textarea>
        
            </mat-form-field>
    
        <mat-form-field>
    
            <mat-select placeholder="Select status"
                formControlName="status">
    
                <mat-option value="draft">
                    Draft</mat-option>
                <mat-option value="published">
                    Published</mat-option>
                <mat-option value="deleted">
                    Deleted</mat-option>
    
            </mat-select>
    
        </mat-form-field>
    
        <!--<mat-form-field>
    
            <input matInput [matDatepicker]="myDatepicker"
                formControlName="releasedAt">
    
            <mat-datepicker-toggle matSuffix
                                   [for]="myDatepicker">
    
            </mat-datepicker-toggle>
    
            <mat-datepicker #myDatepicker></mat-datepicker>
    
        </mat-form-field>-->
    
        
    
    
    </mat-dialog-content>
    
    <mat-dialog-actions>
    
        <button class="mat-raised-button"
                (click)="close()">
            Close
        </button>
    
        <button class="mat-raised-button mat-primary"
                (click)="save()">
            Save
        </button>
    
    </mat-dialog-actions>

<!--
<div mat-dialog-content>
  <form class="example-form" (ngSubmit)="onSubmit()">
    <mat-form-field>
      <input matInput placeholder="Title" type="text" required formControlName="title">
    </mat-form-field>
    <mat-form-field>
      <textarea matInput placeholder="Body" required formControlName="body"></textarea>
    </mat-form-field>
    <mat-form-field>
      <mat-select matInput placeholder="Category" required formControlName="status">
            <mat-option value="draft">
                    Draft</mat-option>
                <mat-option value="publish">
                    Publish</mat-option>
                <mat-option value="delete">
                    Delete</mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-raised-button type="submit" color="primary">Save</button>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-raised-button class="close" (click)="onNoClick()" color="warn">Cancel</button>
</div>
-->