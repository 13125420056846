import { Component, OnInit } from '@angular/core';
import { OfferResponse } from '../shared/offerResponse';
import { Params, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { switchMap } from 'rxjs/operators';
import {MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource} from '@angular/material'
import { ResponseOrderService } from '../services/response-order.service';
import { ResponseInterestedListDetailComponent } from '../response-interested-list-detail/response-interested-list-detail.component';
import{ SnackbarService } from '../services/snackbar.service';
import {NotificationService} from '../services/notification.service'
import { TranslateService } from '@ngx-translate/core';
import {Notification} from '../shared/notification'

@Component({
  selector: 'app-notification-interested-list',
  templateUrl: './notification-interested-list.component.html',
  styleUrls: ['./notification-interested-list.component.scss']
})
export class NotificationInterestedListComponent implements OnInit {

  errMsg: string;
  id: number;
  notification:Notification;
  dataSource;
  displayedColumns: string[] = ['shop_name','shop_phone','shop_contact_name','create_date','done'];
 
  constructor(
    private dialog: MatDialog, 
    private route: ActivatedRoute,
    private location: Location,
    private notificationService: NotificationService,
    private notificationResponseService: ResponseOrderService,
    private translate: TranslateService,
    private snackbarService: SnackbarService) { 

  }

  ngOnInit() {

    const id = this.route.params
    .pipe(switchMap((params: Params) => {
      this.id = +params['id']
      return this.notificationResponseService.getNotificationResponse(params['id']);
    }))
    .subscribe(data => {
      if(data.OK == "YES")
      this.dataSource = new MatTableDataSource<OfferResponse>(data.responses)
    },
    errmsg => this.errMsg = <any>errmsg);

    this.notificationService.readNotification(this.id.toString()).subscribe(data => this.notification = data)

  }

  editNotificationResponse(offerResponse:OfferResponse){
    let dialogRef = this.dialog.open(ResponseInterestedListDetailComponent, {
      width: '600px',
      disableClose: true ,
      data: {offerTitle: this.notification.title, offerResponse}
    });
  dialogRef.componentInstance.event.subscribe((result) => {
    console.log(result.data)
    this.notificationResponseService.updateNotificationResponse(result.data).subscribe(
    data => {
     // if(data.OK == "YES"){
      this.notificationResponseService.getNotificationResponse(this.id.toString()).subscribe(
          (data) => {
            if(data.OK == "YES")
              this.dataSource = new MatTableDataSource<OfferResponse>(data.responses)
            this.snackbarService.success(this.translate.instant("update_success"))
          })
      }
    //}
    )
  });
  }

  goBack(): void{
    this.location.back();
  }


}
