<mat-dialog-content [formGroup]="form" class="example-form"> 
    <h1 mat-dialog-title>Forget password</h1>
    <mat-form-field>
      <input matInput placeholder="Brugernavn eller Email" type="email" formControlName="handle" required>
      <mat-error *ngIf="isFieldInvalid('handle')">
          Der skal indtastes brugernavn eller email 
        </mat-error>
    </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
        <span class="fill-space"></span>
        <button class="mat-raised-button mat-primary"
                (click)="send()" [disabled] ="form.invalid">
                Send
        </button>
     
    </mat-dialog-actions>
    
 