<div fxLayout="column" fxFlex="80" fxFlexOffset="8" fxFlex.xs="auto" fxFlexOffset.xs="1">
 
    
    <div fxLayout="column wrap" fxLayoutAlign="start start" class="card-container">
      <button mat-button (click)="goBack()">
          <mat-icon matListIcon class="app-nav-list-icon"> arrow_back </mat-icon>
          Tilbage
      </button>
    </div>
    <div fxLayout="column wrap" fxLayoutAlign="start center" *ngIf="offer">
      <h4>{{offer.title}}</h4>

    </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" >
  
        <ng-container matColumnDef="shop_name">
          <th mat-header-cell *matHeaderCellDef> Butiksnavn </th>
          <td mat-cell  *matCellDef="let element" [style.color]="'navy'" (click)="editOffer(element)" [style.cursor] ="'pointer'"> {{element.shop_name}} </td>
        </ng-container>
        <ng-container matColumnDef="shop_phone">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Butik telefon </th>
            <td mat-cell *matCellDef="let element"> {{element.shop_phone}} </td>
          </ng-container>
  
        <ng-container matColumnDef="shop_contact_name">
          <th mat-header-cell *matHeaderCellDef> Kontaktperson </th>
          <td mat-cell *matCellDef="let element"> 
                  {{element.shop_contact_name}}
          </td>
        </ng-container>
        <ng-container matColumnDef="create_date">
          <th mat-header-cell *matHeaderCellDef>Dato</th>
          <td mat-cell *matCellDef="let element"> {{element.create_date | toDateObj | date}}</td>
        </ng-container>
        <ng-container matColumnDef="done">
          <th mat-header-cell *matHeaderCellDef>Afsluttet</th>
          <td  mat-cell *matCellDef="let element"> 
             {{element.done}}
          </td>
        </ng-container>
        <!--<ng-container matColumnDef="delete">
            <th  mat-header-cell *matHeaderCellDef>Slet  </th>
            <td  mat-cell *matCellDef="let element"> 
                
            </td>
          </ng-container>-->
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
</div>