import { Component, OnInit, OnDestroy } from '@angular/core';
import {ContactService } from '../services/contact.service'
import {Contact} from '../shared/contact'
import { SnackbarService } from '../services/snackbar.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent implements OnInit, OnDestroy {

  private subscription: Subscription
  id = localStorage.getItem("id")
  employee : Contact
  imageUrl: string = "/assets/imgs/defaultImg.jpg"
  fileToUpload: File = null;
  imageClick : boolean = true;
  constructor(private route: ActivatedRoute, private contactService: ContactService,
    private snackbarService: SnackbarService) {
     
  }

  ngOnInit() {
    if (this.contactService.currentContactValue) { 
      this.subscription = this.contactService.currentContact.subscribe((data) =>{
        this.employee = data
        if(this.employee.photo_path !== ""){
          this.imageUrl = this.employee.photo_path
          this.imageClick = false;
        }
      })
       
      }else{
        this.subscription = this.contactService.getContactById(this.id).subscribe((data) => {
         this.employee = data
         if(this.employee.photo_path !== ""){
          this.imageUrl = this.employee.photo_path
          this.imageClick = false;
        }
      })
      }
      
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


  onFileChanged(file: FileList) {
    //this.selectedFile = event.target.files[0]
    if(file){
      this.fileToUpload = file.item(0)
      //console.log(this.selectedFile)
      //show image preview
      var reader = new FileReader();
      reader.onload = (event:any) =>{
        this.imageUrl = event.target.result
      }
      reader.readAsDataURL(this.fileToUpload)
      this.contactService.updateContactImage(this.employee.id.toString(), this.fileToUpload).subscribe(
        (data) => {
          //console.log(data)
          if(data.OK == "YES"){
            this.imageClick = false;
            //this.company.logo_path = "https://likesharing.dk/database/engros/company_logo/" + this.company.id + ".jpg"
            //this.event.emit({data: this.form.value});
          }
        }
      )

      console.log(this.fileToUpload)
    }
  }

}
